import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { fadeIn } from 'react-animations';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';

import { fetchTenantActivity } from 'actions/Activity';

import { ActivityIcon } from 'utilities/icons';

import Spinner from 'components/Spinner';
import Header from 'components/Header/dashboard';
class DashboardActivityOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'overview'
        };
    }
    componentDidMount() {
        const { fetchTenantActivity, authUser, site, userTimezone, editWidgets, visible } = this.props;
        let timeZone = authUser && authUser.useSiteTime && site ? site.timeZone : userTimezone;
        (editWidgets || visible) && fetchTenantActivity({ interval: 'hour', period: 'week', timeZone });
    }
    updateView = (view) => {
        this.setState({ view });
    };
    render() {
        const { intl, theme, width, activityDates, tenantActivity, editWidgets, handleDateSelection, active } =
            this.props;
        const { view } = this.state;
        this.styles = styles(theme, width);
        const CustomTooltip = ({ active, payload, label }) => {
            if (active) {
                return (
                    <div className={css(this.styles.tooltipWrapper)}>
                        <p className={css(this.styles.tooltipLabel)}>{`${label}:`}</p>
                        {payload && payload[0] && payload[0].value ? (
                            <p className={css(this.styles.tooltipText)}>{`${payload[0].value} ${
                            payload[0].value === 1
                                ? intl.formatMessage({ id: 'form.event' })
                                : intl.formatMessage({ id: 'form.events' })
                            }`}</p>
                        ) : (
                            <p className={css(this.styles.tooltipText)}>{`${'0'} ${
                                intl.formatMessage({ id: 'form.events' })
                                }`}</p>
                        )
                        }

                    </div>
                );
            }
            return null;
        };
        const CustomTooltipTenants = ({ active, payload, label }) => {
            if (active) {
                return (
                    <div className={css(this.styles.tooltipWrapper)}>
                        <p className={css(this.styles.tooltipLabel)}>{`${label}:`}</p>
                        <p className={css(this.styles.tooltipText)}>{`${payload[0].value} ${
                            payload[0].value === 1
                                ? intl.formatMessage({ id: 'role.client' })
                                : intl.formatMessage({ id: 'filters.users.client' })
                        }`}</p>
                    </div>
                );
            }
            return null;
        };
        return (
            <>
                <Header
                    handleDateSelection={handleDateSelection}
                    widgetId={1}
                    active={active}
                    activityOverview={view}
                    editWidgets={editWidgets}
                    updateActivityOverview={this.updateView}
                    Icon={ActivityIcon}
                    iconSize={34}
                    title={view === 'overview' ? 'pages.dashboard.activityOverview' : 'pages.dashboard.activeTenants'}
                />
                {activityDates && view === 'overview' ? (
                    activityDates.length > 0 && view === 'overview' ? (
                        <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                            <Col xs={12} lg={12} style={{ padding: 0 }}>
                                <div style={{ marginLeft: -15 }}>
                                    <BarChart
                                        width={editWidgets ? width / 6 : width > 990 ? width / 4.2 : width - 100}
                                        fontSize={12}
                                        height={170}
                                        data={activityDates}>
                                        <XAxis stroke={theme.color.fontColor} dataKey="Name" />
                                        <YAxis stroke={theme.color.fontColor} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey="Events" fill={theme.color.themeColor} />
                                    </BarChart>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        view === 'overview' && (
                            <div
                                style={{
                                    fontSize: 12,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    animationName: fadeIn,
                                    animationDuration: '1.2s'
                                }}>
                                <p style={{ marginTop: 50 }}>{intl.formatMessage({ id: 'comp.table.cell.empty' })}</p>
                            </div>
                        )
                    )
                ) : (
                    view === 'overview' && (
                        <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                            <Col xs={12} lg={12} style={{ padding: 0 }}>
                                <div style={{ marginLeft: -15 }}>
                                    <BarChart
                                        width={editWidgets ? width / 6 : width > 990 ? width / 4.2 : width - 100}
                                        fontSize={12}
                                        height={170}
                                        data={[
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 },
                                            { name: '00:00', Events: 0 }
                                        ]}>
                                        <XAxis stroke={theme.color.fontColor} dataKey="Name" />
                                        <YAxis stroke={theme.color.fontColor} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey="Events" fill={theme.color.themeColor} />
                                    </BarChart>
                                </div>
                            </Col>
                        </Row>
                    )
                )}
                {tenantActivity && view === 'tenant' ? (
                    tenantActivity.length > 0 && view === 'tenant' ? (
                        <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                            <Col xs={12} lg={12} style={{ padding: 0 }}>
                                <div style={{ marginLeft: -15 }}>
                                    <BarChart
                                        width={editWidgets ? width / 6 : width > 990 ? width / 4.2 : width - 100}
                                        fontSize={12}
                                        height={170}
                                        data={tenantActivity}>
                                        <XAxis stroke={theme.color.fontColor} dataKey="Name" />
                                        <YAxis stroke={theme.color.fontColor} />
                                        <Tooltip content={<CustomTooltipTenants />} />
                                        <Bar dataKey="Tenants" fill={theme.color.themeColor} />
                                    </BarChart>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        view === 'tenant' && (
                            <div
                                style={{
                                    fontSize: 12,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    animationName: fadeIn,
                                    animationDuration: '1.2s'
                                }}>
                                <p style={{ marginTop: 50 }}>{intl.formatMessage({ id: 'comp.table.cell.empty' })}</p>
                            </div>
                        )
                    )
                ) : (
                    view === 'tenant' && <Spinner />
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, activity }) => {
    const { theme, width, userTimezone } = app;
    const { authUser, site } = auth;
    const { activityDates, tenantActivity } = activity;
    return { theme, width, activityDates, tenantActivity, authUser, site, userTimezone };
};

export default withRouter(injectIntl(connect(mapStateToProps, { fetchTenantActivity })(DashboardActivityOverview)));

const styles = (theme) =>
    StyleSheet.create({
        footerRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 5,
            fontSize: 12,
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        footerRowEdit: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            fontSize: 10,
            '@media(max-width:1384px)': {
                fontSize: 9
            }
        },
        footerRowShort: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 5,
            fontSize: 12,
            '@media(max-width:1384px)': {
                fontSize: 11
            }
        },
        footerItemActive: {
            color: 'white',
            padding: '5px 7px',
            margin: '0 6px',
            borderRadius: 4,
            background: theme.color.themeColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            userSelect: 'none',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8',
                boxShadow: theme.color.boxShadowCornerHover
            }
        },
        footerItem: {
            color: theme.color.fontColor,
            padding: '5px 7px',
            margin: '0 6px',
            borderRadius: 4,
            background: theme.color.pageColor,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            boxShadow: theme.color.boxShadowCorner,
            userSelect: 'none',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8',
                boxShadow: theme.color.boxShadowCornerHover
            }
        },
        tooltipWrapper: {
            background: theme.color.compColor,
            borderRadius: 4,
            color: theme.color.compColor,
            boxShadow: theme.color.boxShadow,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 80,
            textAlign: 'center'
        },
        tooltipLabel: {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            padding: '8px 20px 8px 20px',
            color:
                theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                    ? theme.color.fontColor
                    : theme.color.compColor,
            background: theme.color.themeColor,
            width: '100%'
        },
        tooltipText: {
            margin: '-6px 0 0 0',
            padding: '0 0 8px 0',
            color: theme.color.fontColor
        }
    });
