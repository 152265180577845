import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from '../store/history';
import moment from 'moment-timezone';
import * as API from 'api';
import * as CONSTANTS from 'constants';
import * as ACTIONS_AUTH from 'actions/Auth';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_USERS from 'actions/Users';
import * as ACTIONS_SETTINGS from 'actions/Settings';
import * as ACTIONS_COMPANY from 'actions/Company';
import * as ACTIONS_ACCESS_CODES from 'actions/AccessCodes';
import * as UTILS from 'utilities';
import * as DATABASE from 'utilities/database';

const siteState = (state) => state.auth.site;
const getUsersFilters = (state) => state.users.usersFilters;
const assignmentList = (state) => state.settings.featureFlagsAssignmentsList;
const getTrainingHeaders = (state) => state.settings.trainingHeader;
const getFeatureFlagHeaders = (state) => state.settings.featureFlagHeader;

function* requestSettings() {
    try {
        const settings = JSON.parse(sessionStorage.getItem('user_data')).Settings;
        if (!settings) {
            yield put(ACTIONS_SETTINGS.setSettings(null));
        } else {
            yield put(ACTIONS_SETTINGS.setSettings(settings));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestPmsDetails({ payload }) {
    try {
        const pms = yield call(API.POST, payload.url, payload.data);
        if (!pms) {
            yield put(ACTIONS_SETTINGS.setPmsDetails(null));
        } else {
            if (pms.data.pms === 'webhooks') {
                // yield put(ACTIONS_APP.fetchWebhooksSiteDetails({}));
                // yield put(ACTIONS.APP.fetchWebhooksClientDetails({}));
            }
            yield put(ACTIONS_SETTINGS.setPmsDetails(pms.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSettings({ payload }) {
    try {
        const settings = yield call(API.POST, payload.url, payload.data);
        if (settings.message) {
            if (settings.message === 'Token is expired') {
                return;
            } else {
                yield put(ACTIONS_APP.showMessage(settings.message, 'message'));
            }
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            let user = JSON.parse(sessionStorage.getItem('user_data'));
            user.Settings = payload.data;
            sessionStorage.setItem('user_data', JSON.stringify(user));
            yield put(ACTIONS_AUTH.userSignInSuccess(user));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSaveSiteHours({ payload }) {
    try {
        let required = payload.data;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield put(ACTIONS_AUTH.setSite(payload.site));
            yield put(ACTIONS_APP.setSwitcherLoader({loading: true, text: ''}));
            yield put(ACTIONS_AUTH.switchSite(payload.site.uuid));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSaveSiteDailyHours({ payload }) {
    try {
        let required = payload.data;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else if (payload.wizard) {
            let userData = JSON.parse(sessionStorage.getItem('user_data'))
                ? JSON.parse(sessionStorage.getItem('user_data'))
                : null;
            if (userData) {
                // need to update the site hours in user_data instead of doing a whole site switch and all that craziness
                userData.sites.map((s) => {
                    if (s.uuid == payload.data.siteUUID) s.hours = update.data;
                });
                sessionStorage.setItem('user_data', JSON.stringify(userData));
            }
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            //no longer showing the schedules in the wizard so will default to the next step which is the image create
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield put(ACTIONS_AUTH.setSite(payload.site));
            yield put(ACTIONS_APP.setSwitcherLoader({loading: true, text: ''}));
            yield put(ACTIONS_AUTH.switchSite(payload.site.uuid));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUploadImage({ payload }) {
    const formData = new FormData();
    payload.data.site && formData.append('site', payload.data.site);
    payload.data.logo && formData.append('logo', payload.data.logo);
    payload.data.siteUUID && formData.append('SiteUUID', payload.data.siteUUID);
    payload.data.companyUUID && formData.append('CompanyUUID', payload.data.companyUUID);
    try {
        const image = yield call(API.POST, payload.url, formData);
        if (!image) {
            return;
        } else if (payload.wizard) {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            let authUser = JSON.parse(sessionStorage.getItem('user_data'));
            let site = yield select(siteState);
            yield put(ACTIONS_APP.fetchStartup(authUser, site));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateSite({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const site = yield call(API.POST, payload.url, payload.data);
        if (!site) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            // pushing new site to user_data temporarily until it is next grabbed by either login/site switch
            let userData = JSON.parse(sessionStorage.getItem('user_data'))
                ? JSON.parse(sessionStorage.getItem('user_data'))
                : null;
            if (userData) {
                userData.sites.push(site.data.sites[0]);
                sessionStorage.setItem('user_data', JSON.stringify(userData));
            }
            yield put(ACTIONS_AUTH.setCreateSite(site.data.sites[0]));
            yield put(ACTIONS_SETTINGS.setCreateSiteHours(site.data.sites[0].hours));
            yield put(ACTIONS_SETTINGS.setSiteCreateData(site.data.sites[0]));
            yield put(ACTIONS_COMPANY.fetchCompanyList());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateBulkSite({ payload }) {
    try {
        const site = yield call(API.POST, payload.url, payload.data);
        if (!site) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            // pushing new site to user_data temporarily until it is next grabbed by either login/site switch
            let userData = JSON.parse(sessionStorage.getItem('user_data'))
                ? JSON.parse(sessionStorage.getItem('user_data'))
                : null;
            if (userData) {
                userData.sites = userData.sites.concat(site.data.sites);
                sessionStorage.setItem('user_data', JSON.stringify(userData));
            }
            yield put(ACTIONS_COMPANY.fetchCompanyList());
            yield history.push(`/app/settings/theme/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSite({ payload }) {
    try {
        const site = yield call(API.POST, payload.url, payload.data);
        if (!site) {
            return;
        } else if (payload.wizard) {
            if (!payload.silent) yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        } else {
            let prevSite = yield select(siteState);
            // if allowDailyAccessCodes value was changed lets re-fetch daily codes here
            if (site.data.sites &&
                site.data.sites.length &&
                site.data.sites.length === 1 &&
                prevSite.allowDailyAccessCodes !== site.data.sites[0].allowDailyAccessCodes) {
                    yield put(ACTIONS_ACCESS_CODES.fetchDailyCodes());
                }
            yield put(ACTIONS_APP.setSaveLoader(false));
            if (!payload.silent) yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            let authUser = JSON.parse(sessionStorage.getItem('user_data'));
            let siteIndex = authUser.sites.findIndex((s) => s.id === site.data.sites[0].id);
            authUser.sites[siteIndex] = site.data.sites[0];
            sessionStorage.setItem('user_data', JSON.stringify(authUser));
            yield put(ACTIONS_APP.fetchStartup(authUser, site.data.sites[0]));
        }
    } catch (error) {
        console.warn(error);
    }
}

// NOTE: same as requestEditSite, just only notifies if there is an error
function* requestEditSiteSilently({ payload }) {
    try {
        const site = yield call(API.POST, payload.url, payload.data);
        if (!site) {
            yield put(ACTIONS_APP.showMessage('db.error', 'error'));
            yield put(ACTIONS_APP.setSaveLoader(false));
        } else if (payload.wizard) {
            yield put(ACTIONS_APP.setSaveLoader(false));
            return;
        } else {
            yield put(ACTIONS_APP.setSaveLoader(false));
            let authUser = JSON.parse(sessionStorage.getItem('user_data'));
            let siteIndex = authUser.sites.findIndex((s) => s.id === site.data.sites[0].id);
            authUser.sites[siteIndex] = site.data.sites[0];
            sessionStorage.setItem('user_data', JSON.stringify(authUser));
            yield put(ACTIONS_APP.fetchStartup(authUser, site.data.sites[0]));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSiteLocation({ payload }) {
    try {
        const site = yield call(API.POST, payload.url, payload.data);
        if (!site) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            let authUser = JSON.parse(sessionStorage.getItem('user_data'));
            let siteIndex = authUser.sites.findIndex((s) => s.id === site.data.sites[0].id);
            authUser.sites[siteIndex] = site.data.sites[0];
            sessionStorage.setItem('user_data', JSON.stringify(authUser));
            yield put(ACTIONS_APP.fetchStartup(authUser, site.data.sites[0]));
            window.location.reload();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFeatureFlags({ payload }) {
    try {
        const flags = yield call(API.POST, payload.url, payload.data);
        if (!flags) {
            yield put(ACTIONS_SETTINGS.setFeatureFlags(null));
        } else {
            let flag = payload.data ? flags.data.filter((flag) => flag.uuid == payload.data) : null;
            let site = yield select(siteState);
            let assignmentsList = yield select(assignmentList);
            let flagsList = flags.data;
            assignmentsList &&
                flagsList.map((flag) => {
                    assignmentsList.map((assignment) => {
                        if (flag.uuid == assignment.featureflagUUID) {
                            flag.type = assignment.type;
                            flag.featureFlagUUID = assignment.featureflagUUID;
                        } else null;
                    });
                    flag.siteId = site.id;
                    flag.siteUUID = site.uuid;
                });
            flagsList.map((flag) => {
                flag.searchName = flag.name.split('_').join(' ');
                flag.featureFlagUUID = flag.featureFlagUUID ? flag.featureFlagUUID : flag.uuid;
            });
            let flagHeaders = yield select(getFeatureFlagHeaders);
            let activeHeader = flagHeaders.find((item) => item.active);
            flagsList = UTILS.sortList(!activeHeader.order, flagsList, activeHeader.sortTitle);
            yield put(ACTIONS_SETTINGS.setFeatureFlags(flagsList));
            yield flag && flag[0] && put(ACTIONS_SETTINGS.setFeatureFlag(flag[0]));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFeatureFlagAssignments({ payload }) {
    try {
        const flags = yield call(API.POST, payload.url, payload.data);
        if (!flags) {
            yield put(ACTIONS_SETTINGS.setFeatureFlagAssignments(null));
        } else {
            yield put(ACTIONS_SETTINGS.setFeatureFlagAssignments(flags.data));
            yield put(ACTIONS_SETTINGS.fetchFeatureFlags());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditFeatureFlags({ payload }) {
    payload &&
        payload.data &&
        payload.data.map((flag) => {
            !flag.type ? (flag.type = 'none') : '';
        });
    try {
        const flags = yield call(API.POST, payload.url, payload.data);
        if (!flags) {
            return;
        } else {
            yield put(ACTIONS_APP.setSwitcherLoader({loading: true, text: ''}));
            yield put(ACTIONS_AUTH.switchSite(JSON.parse(localStorage.getItem('nse_login_data')).siteUUID));
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFeatureFlagCreate({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const flag = yield call(API.POST, payload.url, payload.data);
        if (!flag) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.add`, 'success'));
            yield history.push(`/app/settings/features/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditFeatureFlag({ payload }) {
    try {
        const flag = yield call(API.POST, payload.url, payload.data);
        if (!flag) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield history.push(`/app/settings/features/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteFeatureFlag({ payload }) {
    try {
        const flag = yield call(API.POST, payload.url, payload.data);
        if (!flag) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield history.push(`/app/settings/features/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteHours({ payload }) {
    try {
        const hours = yield call(API.POST, payload.url, payload.data);
        if (!hours) {
            yield put(ACTIONS_SETTINGS.setSiteHours([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteHours(hours.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateSchedule({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const schedule = yield call(API.POST, payload.url, payload.data);
        if (!schedule) {
            return;
        } else if (payload.wizard) {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            if (!payload.another) {
                yield history.push(`/app/settings/info/create/image/${schedule.data.siteUUID}`);
            }
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSchedule({ payload }) {
    try {
        const schedule = yield call(API.POST, payload.url, payload.data);
        if (!schedule) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield put(ACTIONS_SETTINGS.fetchAllSchedules());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSchedule({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setSchedule(null));
        const schedule = yield call(API.POST, payload.url, payload.data);
        if (!schedule) {
            yield put(ACTIONS_SETTINGS.setSchedule(null));
        } else {
            yield put(ACTIONS_SETTINGS.setSchedule(schedule.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchScheduleUsers({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setScheduleUsers(null));
        const schedule = yield call(API.POST, payload.url, payload.data);
        if (!schedule) {
            yield put(ACTIONS_SETTINGS.setScheduleUsers([]));
        } else if (!schedule.data) {
            yield put(ACTIONS_SETTINGS.setScheduleUsers([]));
        } else {
            schedule.data.map(
                (user) => (user.name = `${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''}`)
            );
            schedule.data = UTILS.sortList(false, schedule.data, 'name');
            yield put(ACTIONS_SETTINGS.setScheduleUsers(schedule.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchAllSchedules({ payload }) {
    try {
        const schedules = yield call(API.POST, payload.url, payload.data);
        if (!schedules) {
            yield put(ACTIONS_SETTINGS.setAllSchedules([]));
        } else if (!schedules.data) {
            yield put(ACTIONS_SETTINGS.setAllSchedules([]));
        } else {
            // 24/7 one shows up first and they want it last
            let orderedSchedules = [];
            if (schedules.data && schedules.data.length && schedules.data.length > 1) {
                orderedSchedules = [...schedules.data.slice(1), schedules.data[0]];
            } else {
                orderedSchedules = schedules.data;
            }
            yield put(ACTIONS_SETTINGS.setAllSchedules(orderedSchedules));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchHolidayHours({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setHolidayHours(null));
        const hours = yield call(API.POST, payload.url, payload.data);
        if (!hours) {
            yield put(ACTIONS_SETTINGS.setHolidayHours([]));
        } else if (!hours.data) {
            yield put(ACTIONS_SETTINGS.setHolidayHours([]));
        } else {
            yield put(ACTIONS_SETTINGS.setHolidayHours(hours.data.holidays));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestAddHoliday({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setHolidayHours(null));
        const holiday = yield call(API.POST, payload.url, payload.data);
        if (!holiday) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield put(ACTIONS_SETTINGS.setHolidayHours(holiday.data.holidays));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteHoliday({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setHolidayHours(null));
        const holiday = yield call(API.POST, payload.url, payload.data);
        if (!holiday) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield put(ACTIONS_SETTINGS.setHolidayHours(holiday.data.holidays));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteSchedule({ payload }) {
    try {
        const schedule = yield call(API.POST, payload.url, payload.data);
        if (!schedule) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield history.push('/app/hours/display');
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateUserSchedule({ payload }) {
    try {
        const schedule = yield call(API.POST, payload.url, payload.data);
        if (!schedule) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            if (payload.stayPage) {
                yield put(ACTIONS_USERS.setUserEditedSchedule(payload.data.userUUID));
                let usersFilters = yield select(getUsersFilters);
                let active = usersFilters && usersFilters.find((filt) => filt.active);
                if (payload.fetchAll) {
                    if (active) {
                        yield put(ACTIONS_USERS.fetchSiteUserList(active.value, false, false, true, false, true));
                    } else {
                        yield put(ACTIONS_USERS.fetchSiteUserList(undefined, false, false, true, false, true));
                    }
                } else {
                    if (active) {
                        yield put(ACTIONS_USERS.fetchSiteUserList(active.value, false, true));
                    } else {
                        yield put(ACTIONS_USERS.fetchSiteUserList(undefined, false, true));
                    }
                }
            } else {
                yield history.push(`/app/users/display/${payload.data.userUUID}`);
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteUserSchedule({ payload }) {
    try {
        const schedule = yield call(API.POST, payload.url, payload.data);
        if (!schedule) {
            return;
        } else {
            yield put(ACTIONS_USERS.setUserDetails(null));
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield history.push(`/app/users/display/${payload.data.userUUID}`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSendSiteHours({ payload }) {
    try {
        const schedule = yield call(API.POST, payload.url, payload.data);
        if (!schedule) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sendSiteHours', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchMessages({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setMessages(null));
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setMessages(messages.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteMessages({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setSiteMessages(null));
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setSiteMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setSiteMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteMessages(messages.data.siteMessages));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteSMSMessages({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setSiteSpecificSMSMessages(null));
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificSMSMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificSMSMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteSpecificSMSMessages(messages.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteEmailMessages({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setSiteSpecificEmailMessages(null));
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificEmailMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificEmailMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteSpecificEmailMessages(messages.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSitePushMessages({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setSiteSpecificPushMessages(null));
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificPushMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificPushMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteSpecificPushMessages(messages.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteNotificationMessages({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setSiteSpecificNotificationMessages(null));
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificNotificationMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificNotificationMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteSpecificNotificationMessages(messages.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchNotificationMessages({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setNotificationMessages(null));
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setNotificationMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setNotificationMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setNotificationMessages(messages.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchLanguages({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setLanguages(null));
        const languages = yield call(API.POST, payload.url, payload.data);
        if (!languages) {
            yield put(ACTIONS_SETTINGS.setLanguages([]));
        } else if (!languages.data) {
            yield put(ACTIONS_SETTINGS.setLanguages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setLanguages(languages.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditMessages({ payload }) {
    try {
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            return;
        } else {
            yield put(ACTIONS_SETTINGS.setMessages(messages.data));
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSiteMessages({ payload }) {
    try {
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSiteSMSMessages({ payload }) {
    try {
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificSMSMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificSMSMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteSpecificSMSMessages(messages.data));
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSiteEmailMessages({ payload }) {
    try {
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificEmailMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificEmailMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteSpecificEmailMessages(messages.data));
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditSitePushMessages({ payload }) {
    try {
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificPushMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificPushMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteSpecificPushMessages(messages.data));
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}
function* requestEditSiteNotificationMessages({ payload }) {
    try {
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificNotificationMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setSiteSpecificNotificationMessages([]));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteSpecificNotificationMessages(messages.data));
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestGetSiteMessageVariables({ payload }) {
    try {
        const variables = yield call(API.POST, payload.url, payload.data);
        if (!variables && !variables.data) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail.update', 'error'));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteVariables(variables.data.notificationsData));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditNotificationMessages({ payload }) {
    try {
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            return;
        } else {
            yield put(ACTIONS_SETTINGS.setNotificationMessages(messages.data));
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSearchFob({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setFobDetails(null));
        const languages = yield call(API.POST, payload.url, payload.data);
        if (!languages) {
            yield put(ACTIONS_SETTINGS.setFobDetails({}));
        } else if (!languages.data) {
            yield put(ACTIONS_SETTINGS.setFobDetails({}));
        } else {
            yield put(ACTIONS_SETTINGS.setFobDetails(languages.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchTrainingList({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setTrainingList(null));
        const trainings = yield call(API.POST, payload.url, payload.data);
        if (!trainings) {
            yield put(ACTIONS_SETTINGS.setTrainingList([]));
        } else if (!trainings.data || !trainings.data.data) {
            yield put(ACTIONS_SETTINGS.setTrainingList([]));
        } else {
            let trainingHeaders = yield select(getTrainingHeaders);
            let activeHeader = trainingHeaders.find((item) => item.active);
            trainings.data.data = UTILS.sortList(!activeHeader.order, trainings.data.data, activeHeader.sortTitle);
            yield put(ACTIONS_SETTINGS.setTrainingList(trainings.data.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEmailDailyActivity({ payload }) {
    try {
        const activity = yield call(API.POST, payload.url, payload.data);
        if (!activity) {
            return
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.dailyActivity', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchLoginTypes({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setLoginTypes(null));
        const loginTypes = yield call(API.POST, payload.url, payload.data);
        if (!loginTypes || !loginTypes.data || !loginTypes.data.loginTypes) {
            yield put(ACTIONS_SETTINGS.setLoginTypes([]));
        } else {
            yield put(ACTIONS_SETTINGS.setLoginTypes(loginTypes.data.loginTypes));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchEntryMessages({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setEntryMessages(null));
        const messages = yield call(API.POST, payload.url, payload.data);
        if (!messages) {
            yield put(ACTIONS_SETTINGS.setEntryMessages([]));
        } else if (!messages.data) {
            yield put(ACTIONS_SETTINGS.setEntryMessages([]));
        } else {
            messages.data.map((message) => {
                message.startTime = moment(message.startTime);
                message.endTime = moment(message.endTime);
                message.recipientIDs = message.recipientIDs ? message.recipientIDs : [];
            });
            messages.data = UTILS.sortList(false, messages.data, 'messageTitle');
            yield put(ACTIONS_SETTINGS.setEntryMessages(messages.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestCreateEntryMessage({ payload }) {
    try {
        const message = yield call(API.POST, payload.url, payload.data);
        if (!message) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail.noAdd', 'error'));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.add', 'success'));
            yield history.goBack();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteEntryMessage({ payload }) {
    try {
        const message = yield call(API.POST, payload.url, payload.data);
        if (!message) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.delete', 'success'));
            yield history.push(`/app/settings/messages/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateEntryMessage({ payload }) {
    try {
        const message = yield call(API.POST, payload.url, payload.data);
        if (!message) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail.noEdit', 'error'));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield history.push(`/app/settings/messages/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchAudit({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setAudit(null));
        const audit = yield call(API.POST, payload.url, payload.data);
        if (!audit) {
            yield put(ACTIONS_SETTINGS.setAudit([]));
        } else if (!audit.data) {
            yield put(ACTIONS_SETTINGS.setAudit([]));
        } else {
            yield put(ACTIONS_SETTINGS.setAudit(audit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchHistoricalAudits({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setHistoricalAudits(null));
        const audit = yield call(API.POST, payload.url, payload.data);
        if (!audit || !audit.data) {
            yield put(ACTIONS_SETTINGS.setHistoricalAudits([]));
        } else {
            audit.data = UTILS.sortList(true, audit.data, 'requestedDate');
            yield put(ACTIONS_SETTINGS.setHistoricalAudits(audit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchIndividualAudit({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setIndividualAudit(null));
        const audit = yield call(API.POST, payload.url, payload.data);
        if (!audit || !audit.data) {
            yield put(ACTIONS_SETTINGS.setIndividualAudit([]));
        } else {
            yield put(ACTIONS_SETTINGS.setIndividualAudit(audit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchDigitalAuditsList({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setDigitalAuditsList(null));
        const audit = yield call(API.POST, payload.url, payload.data);
        if (!audit || !audit.data) {
            yield put(ACTIONS_SETTINGS.setDigitalAuditsList([]));
        } else {
            audit.data = UTILS.sortList(true, audit.data, 'workflowTime');
            audit.data.forEach((a, i) => a.hash = audit.data.length - i);
            if (audit.data && audit.data.length) audit.data[0].active = true;
            yield put(ACTIONS_SETTINGS.setDigitalAuditsList(audit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchDigitalAuditsFacilities({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setDigitalAuditsFacilities(null));
        const audit = yield call(API.POST, payload.url, payload.data);
        if (!audit || !audit.data) {
            let stats = {
                fail: {
                    finalApproval: {
                        approved: 0,
                        approvedPercent: 0.0,
                        denied: 0,
                        deniedPercent: 0.0,
                        pending: 0,
                        pendingPercent: 0.0
                    },
                    initialApproval: {
                        approved: 0,
                        approvedPercent: 0.0,
                        denied: 0,
                        deniedPercent: 0.0,
                        pending: 0,
                        pendingPercent: 0.0
                    },
                    pendingApprovalSubmision: 0,
                    pendingApprovalSubmisionPercent: 0.0,
                    total: 0,
                    totalPercent: 0.0
                },
                notConducted: 0,
                notConductedPercent: 0.0,
                pass: {
                    finalApproval: {
                        approved: 0,
                        approvedPercent: 0.0,
                        denied: 0,
                        deniedPercent: 0.0,
                        pending: 0,
                        pendingPercent: 0.0
                    },
                    initialApproval: {
                        approved: 0,
                        approvedPercent: 0.0,
                        denied: 0,
                        deniedPercent: 0.0,
                        pending: 0,
                        pendingPercent: 0.0
                    },
                    pendingApprovalSubmision: 0,
                    pendingApprovalSubmisionPercent: 0.0,
                    total: 0,
                    totalPercent: 0.0
                },
                review: {
                    finalApproval: {
                        approved: 0,
                        approvedPercent: 0.0,
                        denied: 0,
                        deniedPercent: 0.0,
                        pending: 0,
                        pendingPercent: 0.0
                    },
                    initialApproval: {
                        approved: 0,
                        approvedPercent: 0.0,
                        denied: 0,
                        deniedPercent: 0.0,
                        pending: 0,
                        pendingPercent: 0.0
                    },
                    pendingApprovalSubmision: 0,
                    pendingApprovalSubmisionPercent: 0.0,
                    total: 0,
                    totalPercent: 0.0
                },
                Sumations: {
                    IAPending: 0,
                    IAPendingPercent: 0.0,
                    IAApproved: 0,
                    IAApprovedPercent: 0.0,
                    IADenied: 0,
                    IADeniedPercent: 0.0,
                    FAPending: 0,
                    FAPendingPercent: 0.0,
                    FAApproved: 0,
                    FAApprovedPercent: 0.0,
                    FADenied: 0,
                    FADeniedPercent: 0.0,
                    PASubmission: 0,
                    PASubmissionPercent: 0.0
                },
                total: 0
            }
            yield put(ACTIONS_SETTINGS.setDigitalAuditsFacilities([]));
            yield put(ACTIONS_SETTINGS.setDigitalAuditsFacilitiesCompanies([]));
            yield put(ACTIONS_SETTINGS.setDigitalAuditsFacilitiesStats(stats));
        } else {
            audit.data.facilityList = UTILS.sortList(true, audit.data.facilityList, 'workflowTime');
            const companies = audit.data.facilityList.reduce((acc, facility) => {
                const exists = acc.some(company => company.companyID === facility.companyID);
                if (!exists && facility.companyID && facility.companyName) {
                    acc.push({ companyID: facility.companyID ? facility.companyID : '', companyName: facility.companyName ? facility.companyName : '' });
                }
                return acc;
            }, []).sort((a, b) => {
                if (a.companyName < b.companyName) return -1;
                if (a.companyName > b.companyName) return 1;
                return 0;
            });
            companies.unshift({companyID: 'All', companyName: 'All'});
            yield put(ACTIONS_SETTINGS.setDigitalAuditsFacilities(audit.data.facilityList));
            yield put(ACTIONS_SETTINGS.setDigitalAuditsFacilitiesStats(audit.data.header));
            yield put(ACTIONS_SETTINGS.setDigitalAuditsFacilitiesCompanies(companies));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchDigitalAuditSummary({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setDigitalAuditSummary(null));
        const audit = yield call(API.POST, payload.url, payload.data);
        if (!audit || !audit.data) {
            yield put(ACTIONS_SETTINGS.setDigitalAuditSummary([]));
        } else {
            // format how we want it (an array of groups that is sorted by groupSeq and each of those groups having parameters sorted by paramterSeq)
            audit.data.groups = audit.data.lineItems.reduce((a, c) => {
                let group = a.find(group => group.groupName === c.groupName);
                if (group) {
                    group.params.push(c)
                } else {
                    a.push({
                        groupSequence: c.groupSeq,
                        groupName: c.groupName,
                        params: [c]
                    })
                }
                return a;
            }, [])
            audit.data.groups.sort((a,b) => a.params[0].groupSeq - b.params[0].groupSeq);
            audit.data.groups.forEach(g => g.params.sort((a, b) => a.parameterSeq - b.parameterSeq));
            yield put(ACTIONS_SETTINGS.setDigitalAuditSummary(audit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchDigitalAuditHistory({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setDigitalAuditHistory(null));
        const audit = yield call(API.POST, payload.url, payload.data);
        if (!audit || !audit.data) {
            yield put(ACTIONS_SETTINGS.setDigitalAuditHistory([]));
        } else {
            audit.data = UTILS.sortList(true, audit.data, 'workflowLifeCycleId');
            audit.data.forEach((a, i) => a.hash = audit.data.length - i);
            yield put(ACTIONS_SETTINGS.setDigitalAuditHistory(audit.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchDigitalAuditDetail({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setDigitalAuditDetail(null));
        const audit = yield call(API.POST, payload.url, payload.data);
        if (!audit || !audit.data) {
            yield put(ACTIONS_SETTINGS.setDigitalAuditDetail([]));
        } else {
            let result = audit.data.map(p => {
                if (p.parameterId == '33') {
                    return {
                        ...p,
                        actualValueDetails: "Need at least 2 gateways (each mesh area should have 2)."
                    }
                }
                return {
                    ...p,
                    actualValueDetails: p.actualValueDetails && p.actualValueDetails !== '' ? JSON.parse(p.actualValueDetails) : []
                }
            })
            // format how we want it (an array of groups that is sorted by groupSeq and each of those groups having parameters sorted by paramterSeq)
            let formattedResult = result.reduce((a, c) => {
                let group = a.find(group => group.groupName === c.groupName);
                if (group) {
                    group.params.push(c)
                } else {
                    a.push({
                        groupSequence: c.groupSeq,
                        groupName: c.groupName,
                        params: [c]
                    })
                }
                return a;
            }, [])
            formattedResult.sort((a,b) => a.params[0].groupSeq - b.params[0].groupSeq);
            formattedResult.forEach(g => g.params.sort((a, b) => a.parameterSeq - b.parameterSeq));
            yield put(ACTIONS_SETTINGS.setDigitalAuditDetail(formattedResult));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* selectRequestNewDigitalAudit({ payload }) {
    try {
        const audit = yield call(API.POST, payload.url, payload.data);
        if (!audit || !audit.data) {
            
        } else {
            history.push(`/app/fleetmgmt/audits/summary/${audit.data.id}`)
        }
    } catch (error) {
        console.warn(error);
    }
}

function* selectRequestBulkDigitalAudit({ payload }) {
    try {
        yield call(API.POST, payload.url, payload.data);
        yield put(ACTIONS_APP.showMessage('notifications.info.bulkAuditRequest', 'success'));
    } catch (error) {
        console.warn(error);
    }
}

function* selectRequestDigitalAuditSummaryExport({ payload }) {
    try {
        yield call(API.POST, payload.url, payload.data);
        yield put(ACTIONS_APP.showMessage('notifications.success.dailyActivity', 'success'));
    } catch (error) {
        console.warn(error);
    }
}

function* selectRequestDigitalAuditParameterExport({ payload }) {
    try {
        yield call(API.POST, payload.url, payload.data);
        yield put(ACTIONS_APP.showMessage('notifications.success.dailyActivity', 'success'));
    } catch (error) {
        console.warn(error);
    }
}

function* selectRequestFacilityPerformanceExport({ payload }) {
    try {
        yield call(API.POST, payload.url, payload.data);
        yield put(ACTIONS_APP.showMessage('notifications.success.dailyActivity', 'success'));
    } catch (error) {
        console.warn(error);
    }
}



function* selectUpdateAuditLifecycle({ payload }) {
    try {
        yield call(API.POST, payload.url, payload.data);
        if (payload.requestNew) {
            // canceling in order to fetch new audit
            yield put(ACTIONS_SETTINGS.requestNewDigitalAudit());
        } else {
            // only updating lifecycle so re-fetch that audit
            yield put(ACTIONS_SETTINGS.fetchDigitalAuditSummary(payload.data.auditId));
            yield put(ACTIONS_SETTINGS.fetchDigitalAuditHistory(payload.data.auditId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchHubspotTickets({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setHubspotTickets(null));
        const tickets = yield call(API.POST, payload.url, payload.data);
        if (!tickets || !tickets.data || !tickets.data.Tickets) {
            yield put(ACTIONS_SETTINGS.setHubspotTickets([]));
        } else if (!tickets.data) {
            yield put(ACTIONS_SETTINGS.setHubspotTickets([]));
        } else {
            yield put(ACTIONS_SETTINGS.setHubspotTickets(tickets.data.Tickets));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchHubspotTicket({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setHubspotTicket(null));
        const ticket = yield call(API.POST, payload.url, payload.data);
        if (!ticket) {
            yield put(ACTIONS_SETTINGS.setHubspotTicket([]));
        } else if (!ticket.data) {
            yield put(ACTIONS_SETTINGS.setHubspotTicket([]));
        } else {
            yield put(ACTIONS_SETTINGS.setHubspotTicket(ticket.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchHubspotCompanies({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setHubspotTickets(null));
        const companies = yield call(API.POST, payload.url, payload.data);
        if (!companies || !companies.data || !companies.data.companies) {

        } else {

        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSubmitHubspotFeedback({ payload }) {
    try {
        const data = yield call(API.POST, payload.url, payload.data);
        if (!data) {
            yield put(ACTIONS_APP.setLoader(false));
        } else {
            yield put(ACTIONS_APP.setLoader(false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEcobeePin({ payload }) {
    try {
        if (payload.fakeData) {
            yield put(ACTIONS_SETTINGS.setEcobeePin(DATABASE.ECOBEE_REQUEST_PIN));
            yield put(ACTIONS_APP.setLoader(false));
        } else {
            yield put(ACTIONS_SETTINGS.setEcobeePin(null));
            const ecobee = yield call(API.POST, payload.url, payload.data);
            if (!ecobee || !ecobee.data) {
                yield put(ACTIONS_SETTINGS.setEcobeePin([]));
            } else {
                yield put(ACTIONS_SETTINGS.setEcobeePin(ecobee.data));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchEcobeePin({ payload }) {
    try {
        if (payload.fakeData) {
            yield put(ACTIONS_SETTINGS.setEcobeePin(DATABASE.ECOBEE_REQUEST_PIN));
            yield put(ACTIONS_APP.setLoader(false));
        } else {
            const ecobee = yield call(API.POST, payload.url, payload.data);
            if (!ecobee || !ecobee.data || !ecobee.data.pin || ecobee.data.pin == '') {
                yield put(ACTIONS_SETTINGS.setEcobeePin(null));
            } else {
                ecobee.data.ecobeePin = ecobee.data.pin;
                yield put(ACTIONS_SETTINGS.setEcobeePin(ecobee.data));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchThermostat({ payload }) {
    try {
        if (payload.fakeData) {
            yield put(ACTIONS_SETTINGS.setThermostat(DATABASE.ECOBEE_DATA));
        } else {
            const thermostat = yield call(API.POST, payload.url, payload.data);
            if (!thermostat || !thermostat.data) {
                yield put(ACTIONS_SETTINGS.setThermostat([]));
            } else {
                yield put(ACTIONS_SETTINGS.setThermostat(thermostat.data));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEcobeeToken({ payload }) {
    try {
        if (payload.fakeData) {
            yield put(ACTIONS_APP.setLoader(false));
        } else {
            const ecobee = yield call(API.POST, payload.url, payload.data);
            if (!ecobee || !ecobee.data) {
                yield put(ACTIONS_APP.setLoader(false));
            } else {
                yield put(ACTIONS_APP.setLoader(false));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateThermostatTemp({ payload }) {
    try {
        if (payload.fakeData) {
            yield put(ACTIONS_APP.setLoader(false));
            let tempEcobeeData = DATABASE.ECOBEE_DATA;
            tempEcobeeData.thermostatList[0].runtime.desiredHeat = payload.temp;
            tempEcobeeData.thermostatList[0].runtime.desiredCool = payload.temp;
            tempEcobeeData.thermostatList[0].runtime.actualTemperature = payload.temp;
            yield put(ACTIONS_SETTINGS.setThermostat(tempEcobeeData));
        } else {
            const ecobee = yield call(API.POST, payload.url, payload.data);
            if (!ecobee || !ecobee.data) {
                yield put(ACTIONS_APP.setLoader(false));
                yield put(ACTIONS_SETTINGS.fetchThermostat());
            } else {
                yield put(ACTIONS_APP.setLoader(false));
                yield put(ACTIONS_SETTINGS.fetchThermostat());
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSiteStats({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setSiteStats(null));
        const stats = yield call(API.POST, payload.url, payload.data);
        if (!stats || !stats.data) {
            yield put(ACTIONS_SETTINGS.setSiteStats({ totalGateways: 0, totalUCs: 0, totalUsers: 0 }));
        } else {
            yield put(ACTIONS_SETTINGS.setSiteStats(stats.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchHubspotFields({ payload }) {
    try {
        yield put(ACTIONS_SETTINGS.setHubspotFields(null));
        const fields = yield call(API.POST, payload.url, payload.data);
        if (!fields || !fields.data || !fields.data.fields) {
            yield put(ACTIONS_SETTINGS.setHubspotFields([]));
        } else {
            let fieldsDependent = fields.data.fields.filter((field) => field.dependentFieldFilters.length > 0);
            fields.data.fields.map(
                (field) =>
                    fieldsDependent &&
                    fieldsDependent.map((dependentField) =>
                        dependentField.dependentFieldFilters.map((dependentFieldFilter) => {
                            if (dependentFieldFilter.dependentFormField.name == field.name) {
                                field.dependentOn = {
                                    dependentField: dependentField.name,
                                    dependentOptions: dependentFieldFilter.filters[0].strValues
                                };
                            }
                        })
                    )
            );
            yield put(ACTIONS_SETTINGS.setHubspotFields(fields.data.fields));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSubmitCustomHubspotForm({ payload }) {
    try {
        const data = yield call(API.POST, payload.url, payload.data);
        if (!data) {
            yield put(ACTIONS_APP.setLoader(false));
        } else {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_APP.showMessage('notifications.success.sentTicket', 'success'));
            yield history.push(`/app/home/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestAddCompanyLanguage({ payload }) {
    try {
        const data = yield call(API.POST, payload.url, payload.data);
        if (!data) {
            yield put(ACTIONS_APP.setLoader(false));
        } else {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_SETTINGS.fetchLanguages());
            yield history.push(`/app/settings/messages/all`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchScreenImages({ payload }) {
    try {
        const data = yield call(API.POST, payload.url, payload.data);
        if (!data) {
            return;
        } else {
            yield put(ACTIONS_SETTINGS.setScreenImages(data && data.data && data.data.screens));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUploadScreenImage({payload}) {  
    const formData = new FormData();
    const { id, screenType, language, image } = payload.data;
    id && formData.append('id', id);
    screenType && formData.append('screenType', screenType);
    language && formData.append('language', language);
    image && formData.append('image', image);

    try {
        const data = yield call(API.POST, payload.url, formData);
        if (!data) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail.noEdit', 'error'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield put(ACTIONS_SETTINGS.setScreenImages(data && data.data && data.data.screens));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestBlacklistAllFobs({ payload }) {
    try {
        const fobs = yield call(API.POST, payload.url, payload.data);
        if (!fobs) {
            yield put(ACTIONS_APP.showMessage('notifications.error.fail.noEdit', 'error'));
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

export function* selectSettings() {
    yield takeEvery(CONSTANTS.FETCH_SETTINGS, requestSettings);
}

export function* selectPmsDetails() {
    yield takeEvery(CONSTANTS.FETCH_PMS_DETAILS, requestPmsDetails);
}

export function* editSettings() {
    yield takeEvery(CONSTANTS.EDIT_SETTINGS, requestEditSettings);
}

export function* selectSaveSiteHours() {
    yield takeEvery(CONSTANTS.SAVE_SITE_HOURS, requestSaveSiteHours);
}

export function* selectSaveSiteDailyHours() {
    yield takeEvery(CONSTANTS.SAVE_SITE_DAILY_HOURS, requestSaveSiteDailyHours);
}

export function* uploadImage() {
    yield takeEvery(CONSTANTS.UPLOAD_IMAGE, requestUploadImage);
}

export function* uploadSiteImage() {
    yield takeEvery(CONSTANTS.UPLOAD_SITE_IMAGE, requestUploadImage);
}

export function* createSite() {
    yield takeEvery(CONSTANTS.CREATE_SITE, requestCreateSite);
}

export function* createBulkSite() {
    yield takeEvery(CONSTANTS.CREATE_BULK_SITE, requestCreateBulkSite);
}

export function* editSite() {
    yield takeEvery(CONSTANTS.EDIT_SITE, requestEditSite);
}

export function* editSiteSilently() {
    yield takeEvery(CONSTANTS.EDIT_SITE_SILENTLY, requestEditSiteSilently);
}

export function* editSiteLocation() {
    yield takeEvery(CONSTANTS.EDIT_SITE_LOCATION, requestEditSiteLocation);
}

export function* selectFeatureFlags() {
    yield takeEvery(CONSTANTS.FETCH_FEATURE_FLAGS, requestFeatureFlags);
}

export function* selectFeatureFlagAssignments() {
    yield takeEvery(CONSTANTS.FETCH_FEATURE_FLAG_ASSIGNMENTS, requestFeatureFlagAssignments);
}

export function* editFeatureFlags() {
    yield takeEvery(CONSTANTS.EDIT_FEATURE_FLAGS, requestEditFeatureFlags);
}

export function* selectCreateFeatureFlag() {
    yield takeEvery(CONSTANTS.CREATE_FEATURE_FLAG, requestFeatureFlagCreate);
}

export function* editFeatureFlag() {
    yield takeEvery(CONSTANTS.EDIT_FEATURE_FLAG, requestEditFeatureFlag);
}

export function* deleteFeatureFlag() {
    yield takeEvery(CONSTANTS.DELETE_FEATURE_FLAG, requestDeleteFeatureFlag);
}

export function* selectFetchSiteHours() {
    yield takeEvery(CONSTANTS.FETCH_SITE_HOURS, requestFetchSiteHours);
}

export function* createSchedule() {
    yield takeEvery(CONSTANTS.CREATE_SCHEDULE, requestCreateSchedule);
}

export function* editSchedule() {
    yield takeEvery(CONSTANTS.EDIT_SCHEDULE, requestEditSchedule);
}

export function* fetchSchedule() {
    yield takeEvery(CONSTANTS.FETCH_SCHEDULE, requestFetchSchedule);
}

export function* fetchScheduleUsers() {
    yield takeEvery(CONSTANTS.FETCH_SCHEDULE_USERS, requestFetchScheduleUsers);
}

export function* fetchAllSchedules() {
    yield takeEvery(CONSTANTS.FETCH_ALL_SCHEDULES, requestFetchAllSchedules);
}

export function* selectFetchHolidayHours() {
    yield takeEvery(CONSTANTS.FETCH_HOLIDAY_HOURS, requestFetchHolidayHours);
}

export function* selectAddHoliday() {
    yield takeEvery(CONSTANTS.ADD_HOLIDAY, requestAddHoliday);
}

export function* selectDeleteHoliday() {
    yield takeEvery(CONSTANTS.DELETE_HOLIDAY, requestDeleteHoliday);
}

export function* deleteSchedule() {
    yield takeEvery(CONSTANTS.DELETE_SCHEDULE, requestDeleteSchedule);
}

export function* updateUserSchedule() {
    yield takeEvery(CONSTANTS.UPDATE_USER_SCHEDULE, requestUpdateUserSchedule);
}

export function* deleteUserSchedule() {
    yield takeEvery(CONSTANTS.DELETE_USER_SCHEDULE, requestDeleteUserSchedule);
}

export function* selectSendSiteHours() {
    yield takeEvery(CONSTANTS.SEND_SITE_HOURS, requestSendSiteHours);
}

export function* fetchMessages() {
    yield takeEvery(CONSTANTS.FETCH_MESSAGES, requestFetchMessages);
}

export function* fetchSiteMessages() {
    yield takeEvery(CONSTANTS.FETCH_SITE_MESSAGES, requestFetchSiteMessages);
}

export function* fetchSiteSMSMessages() {
    yield takeEvery(CONSTANTS.FETCH_SITE_SMS_MESSAGES, requestFetchSiteSMSMessages);
}

export function* fetchSiteEmailMessages() {
    yield takeEvery(CONSTANTS.FETCH_SITE_EMAIL_MESSAGES, requestFetchSiteEmailMessages);
}

export function* fetchSitePushMessages() {
    yield takeEvery(CONSTANTS.FETCH_SITE_PUSH_MESSAGES, requestFetchSitePushMessages);
}

export function* fetchSiteNotificationMessages() {
    yield takeEvery(CONSTANTS.FETCH_SITE_NOTIFICATION_MESSAGES, requestFetchSiteNotificationMessages);
}

export function* fetchNotificationMessages() {
    yield takeEvery(CONSTANTS.FETCH_NOTIFICATION_MESSAGES, requestFetchNotificationMessages);
}

export function* fetchLanguages() {
    yield takeEvery(CONSTANTS.FETCH_LANGUAGES, requestFetchLanguages);
}

export function* selectEditMessages() {
    yield takeEvery(CONSTANTS.EDIT_MESSAGES, requestEditMessages);
}

export function* selectEditSiteMessages() {
    yield takeEvery(CONSTANTS.EDIT_SITE_MESSAGES, requestEditSiteMessages);
}

export function* editSiteSMSMessages() {
    yield takeEvery(CONSTANTS.EDIT_SITE_SMS_MESSAGES, requestEditSiteSMSMessages);
}

export function* editSiteEmailMessages() {
    yield takeEvery(CONSTANTS.EDIT_SITE_EMAIL_MESSAGES, requestEditSiteEmailMessages);
}

export function* editSiteNotificationMessages() {
    yield takeEvery(CONSTANTS.EDIT_SITE_NOTIFICATION_MESSAGES, requestEditSiteNotificationMessages);
}

export function* editSitePushMessages() {
    yield takeEvery(CONSTANTS.EDIT_SITE_PUSH_MESSAGES, requestEditSitePushMessages);
}

export function* selectEditNotificationMessages() {
    yield takeEvery(CONSTANTS.EDIT_NOTIFICATION_MESSAGES, requestEditNotificationMessages);
}

export function* getSiteMessageVariables() {
    yield takeEvery(CONSTANTS.GET_SITE_MESSAGE_VARIABLES, requestGetSiteMessageVariables);
}

export function* selectSearchFob() {
    yield takeEvery(CONSTANTS.SEARCH_FOB, requestSearchFob);
}

export function* fetchTrainingList() {
    yield takeEvery(CONSTANTS.FETCH_TRAINING_LIST, requestFetchTrainingList);
}

export function* emailDailyActivity() {
    yield takeEvery(CONSTANTS.EMAIL_DAILY_ACTIVITY, requestEmailDailyActivity);
}

export function* fetchLoginTypes() {
    yield takeEvery(CONSTANTS.FETCH_LOGIN_TYPES, requestFetchLoginTypes);
}

export function* fetchEntryMessages() {
    yield takeEvery(CONSTANTS.FETCH_ENTRY_MESSAGES, requestFetchEntryMessages);
}

export function* selectCreateEntryMessage() {
    yield takeEvery(CONSTANTS.CREATE_ENTRY_MESSAGE, requestCreateEntryMessage);
}

export function* selectDeleteEntryMessage() {
    yield takeEvery(CONSTANTS.DELETE_ENTRY_MESSAGE, requestDeleteEntryMessage);
}

export function* selectUpdateEntryMessage() {
    yield takeEvery(CONSTANTS.EDIT_ENTRY_MESSAGE, requestUpdateEntryMessage);
}

export function* fetchAudit() {
    yield takeEvery(CONSTANTS.REQUEST_AUDIT, requestFetchAudit);
}

export function* fetchHistoricalAudits() {
    yield takeEvery(CONSTANTS.REQUEST_HISTORICAL_AUDITS, requestFetchHistoricalAudits);
}

export function* fetchIndividualAudit() {
    yield takeEvery(CONSTANTS.REQUEST_INDIVIDUAL_AUDIT, requestFetchIndividualAudit);
}

export function* fetchDigitalAuditsList() {
    yield takeEvery(CONSTANTS.FETCH_DIGITAL_AUDITS_LIST, requestFetchDigitalAuditsList)
}

export function* fetchDigitalAuditsFacilities() {
    yield takeEvery(CONSTANTS.FETCH_DIGITAL_AUDITS_FACILITIES, requestFetchDigitalAuditsFacilities)
}

export function* fetchDigitalAuditSummary() {
    yield takeEvery(CONSTANTS.FETCH_DIGITAL_AUDIT_SUMMARY, requestFetchDigitalAuditSummary)
}

export function* fetchDigitalAuditHistory() {
    yield takeEvery(CONSTANTS.FETCH_DIGITAL_AUDIT_HISTORY, requestFetchDigitalAuditHistory)
}

export function* fetchDigitalAuditDetail() {
    yield takeEvery(CONSTANTS.FETCH_DIGITAL_AUDIT_DETAIL, requestFetchDigitalAuditDetail)
}

export function* requestNewDigitalAudit() {
    yield takeEvery(CONSTANTS.REQUEST_NEW_DIGITAL_AUDIT, selectRequestNewDigitalAudit)
}

export function* requestBulkDigitalAudit() {
    yield takeEvery(CONSTANTS.REQUEST_BULK_DIGITAL_AUDIT, selectRequestBulkDigitalAudit)
}

export function* requestDigitalAuditSummaryExport() {
    yield takeEvery(CONSTANTS.REQUEST_DIGITAL_AUDIT_SUMMARY_EXPORT, selectRequestDigitalAuditSummaryExport)
}

export function* requestDigitalAuditParameterExport() {
    yield takeEvery(CONSTANTS.REQUEST_DIGITAL_AUDIT_PARAMETER_EXPORT, selectRequestDigitalAuditParameterExport)
}

export function* requestDigitalAuditFacilityPerformanceExport() {
    yield takeEvery(CONSTANTS.REQUEST_DIGITAL_AUDIT_FACILITY_PERFORMANCE_EXPORT, selectRequestFacilityPerformanceExport)
}


export function* requestUpdateAuditLifecycle() {
    yield takeEvery(CONSTANTS.UPDATE_AUDIT_LIFECYCLE, selectUpdateAuditLifecycle)
}

export function* selectFetchHubspotTickets() {
    yield takeEvery(CONSTANTS.FETCH_HUBSPOT_TICKETS, requestFetchHubspotTickets);
}

export function* selectFetchHubspotTicket() {
    yield takeEvery(CONSTANTS.FETCH_HUBSPOT_TICKET, requestFetchHubspotTicket);
}

export function* selectFetchHubspotCompanies() {
    yield takeEvery(CONSTANTS.FETCH_HUBSPOT_COMPANIES, requestFetchHubspotCompanies);
}

export function* selectSubmitHubspotFeedback() {
    yield takeEvery(CONSTANTS.SUBMIT_HUBSPOT_FEEDBACK, requestSubmitHubspotFeedback);
}

export function* selectRequestEcobeePin() {
    yield takeEvery(CONSTANTS.REQUEST_ECOBEE_PIN, requestEcobeePin);
}

export function* selectFetchEcobeePin() {
    yield takeEvery(CONSTANTS.FETCH_ECOBEE_PIN, requestFetchEcobeePin);
}

export function* selectFetchThermostat() {
    yield takeEvery(CONSTANTS.FETCH_THERMOSTAT, requestFetchThermostat);
}

export function* selectRequestEcobeeToken() {
    yield takeEvery(CONSTANTS.REQUEST_ECOBEE_TOKEN, requestEcobeeToken);
}

export function* selectUpdateThermostatTemp() {
    yield takeEvery(CONSTANTS.UPDATE_THERMOSTAT_TEMP, requestUpdateThermostatTemp);
}

export function* selectFetchSiteStats() {
    yield takeEvery(CONSTANTS.FETCH_SITE_STATS, requestFetchSiteStats);
}

export function* selectFetchHubspotFields() {
    yield takeEvery(CONSTANTS.FETCH_HUBSPOT_FIELDS, requestFetchHubspotFields);
}

export function* selectSubmitCustomHubspotForm() {
    yield takeEvery(CONSTANTS.SUBMIT_CUSTOM_HUBSPOT_FORM, requestSubmitCustomHubspotForm);
}

export function* selectAddCompanyLanguage() {
    yield takeEvery(CONSTANTS.ADD_COMPANY_LANGUAGE, requestAddCompanyLanguage);
}

export function* selectFetchScreenImages() {
    yield takeEvery(CONSTANTS.FETCH_SCREEN_IMAGES, requestFetchScreenImages)
}

export function* selectUploadScreenImage() {
    yield takeEvery(CONSTANTS.UPLOAD_SCREEN_IMAGE, requestUploadScreenImage)
}

export function* selectBlacklistAllFobs() {
    yield takeEvery(CONSTANTS.BLACKLIST_ALL_FOBS, requestBlacklistAllFobs)
}

export default function* rootSaga() {
    yield all([
        fork(selectSettings),
        fork(selectPmsDetails),
        fork(selectSaveSiteHours),
        fork(selectSaveSiteDailyHours),
        fork(editSettings),
        fork(uploadImage),
        fork(uploadSiteImage),
        fork(createSite),
        fork(createBulkSite),
        fork(editSite),
        fork(editSiteSilently),
        fork(editSiteLocation),
        fork(selectFeatureFlags),
        fork(selectFeatureFlagAssignments),
        fork(editFeatureFlags),
        fork(selectCreateFeatureFlag),
        fork(editFeatureFlag),
        fork(deleteFeatureFlag),
        fork(selectFetchSiteHours),
        fork(createSchedule),
        fork(editSchedule),
        fork(fetchSchedule),
        fork(fetchScheduleUsers),
        fork(fetchAllSchedules),
        fork(selectFetchHolidayHours),
        fork(selectAddHoliday),
        fork(selectDeleteHoliday),
        fork(deleteSchedule),
        fork(updateUserSchedule),
        fork(deleteUserSchedule),
        fork(selectSendSiteHours),
        fork(fetchMessages),
        fork(fetchSiteMessages),
        fork(fetchSiteSMSMessages),
        fork(fetchSiteEmailMessages),
        fork(fetchSitePushMessages),
        fork(fetchSiteNotificationMessages),
        fork(fetchNotificationMessages),
        fork(fetchLanguages),
        fork(selectEditMessages),
        fork(selectEditSiteMessages),
        fork(editSiteSMSMessages),
        fork(editSiteNotificationMessages),
        fork(editSitePushMessages),
        fork(editSiteEmailMessages),
        fork(selectEditNotificationMessages),
        fork(getSiteMessageVariables),
        fork(selectSearchFob),
        fork(fetchTrainingList),
        fork(emailDailyActivity),
        fork(fetchLoginTypes),
        fork(fetchEntryMessages),
        fork(selectCreateEntryMessage),
        fork(selectDeleteEntryMessage),
        fork(selectUpdateEntryMessage),
        fork(fetchAudit),
        fork(fetchHistoricalAudits),
        fork(fetchIndividualAudit),
        fork(fetchDigitalAuditsList),
        fork(fetchDigitalAuditsFacilities),
        fork(fetchDigitalAuditSummary),
        fork(fetchDigitalAuditHistory),
        fork(fetchDigitalAuditDetail),
        fork(requestNewDigitalAudit),
        fork(requestBulkDigitalAudit),
        fork(requestDigitalAuditSummaryExport),
        fork(requestDigitalAuditParameterExport),
        fork(requestDigitalAuditFacilityPerformanceExport),
        fork(requestUpdateAuditLifecycle),
        fork(selectFetchHubspotTickets),
        fork(selectFetchHubspotTicket),
        fork(selectFetchHubspotCompanies),
        fork(selectSubmitHubspotFeedback),
        fork(selectRequestEcobeePin),
        fork(selectFetchEcobeePin),
        fork(selectFetchThermostat),
        fork(selectRequestEcobeeToken),
        fork(selectUpdateThermostatTemp),
        fork(selectFetchSiteStats),
        fork(selectFetchHubspotFields),
        fork(selectSubmitCustomHubspotForm),
        fork(selectAddCompanyLanguage),
        fork(selectFetchScreenImages),
        fork(selectUploadScreenImage),
        fork(selectBlacklistAllFobs)
    ]);
}
