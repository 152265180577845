import React from 'react';
import { Button, Form, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal/new';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import cuid from 'cuid';
import CustomButton from 'components/Button';
import ReactCodeInput from 'react-code-input';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';

import LanguageSwitcher from 'components/LanguageSwitcher';
import TextInput from 'components/Inputs/text';
import { userSignIn, sendAuthPin, fetchAuthPin, setLoginAttemptModal } from 'actions/Auth';
import { showMessage, setLoginPerformance, submitNotificationPerformance } from 'actions/App';
import { isIPad13 } from 'react-device-detect';
class SignIn extends React.Component {
    constructor(props) {
        super(props);
        const nse_login_data = localStorage.getItem('nse_login_obj');
        this.state = {
            country: props.country,
            phone: nse_login_data ? JSON.parse(nse_login_data).phone : '',
            email: nse_login_data ? JSON.parse(nse_login_data).email : '',
            password: '',
            capsLockOn: false,
            phoneLogin: nse_login_data ? (JSON.parse(nse_login_data).phone ? true : false) : true,
            reveal: false,
            revealPin: false,
            inputCode: ''
        };
    }
    componentDidMount() {
        document.title = 'Login | Smart Entry';
    }
    componentDidUpdate(prevProps) {
        if (prevProps.country !== this.props.country) {
            let { country } = this.props;
            this.setState({ country });
        }
    }
    login = (e) => {
        e.preventDefault();
        const { userSignIn, loginConfirm, loginPerformance, setLoginPerformance } = this.props;
        const sessionLoginConfirm = JSON.parse(sessionStorage.getItem('login_confirm'));
        const localLoginConfirm = JSON.parse(localStorage.getItem('login_confirm'));
        let { country, password } = this.state;
        sessionLoginConfirm
            ? (setLoginPerformance({
                    ...loginPerformance,
                    start_time: moment(),
                    loginType: sessionLoginConfirm.phone !== '' ? 'Phone' : 'Email',
                    emailOrPhone: sessionLoginConfirm.phone !== '' ? sessionLoginConfirm.phone : sessionLoginConfirm.email
                }),
                userSignIn({
                    country,
                    phone: sessionLoginConfirm.phone,
                    email: sessionLoginConfirm.email,
                    password,
                    phoneLogin: sessionLoginConfirm.phone !== '' ? true : false,
                    countryCode: sessionLoginConfirm.countryCode,
                    deviceUUID: sessionLoginConfirm.deviceUUID
              }))
            : loginConfirm
            ? (setLoginPerformance({
                    ...loginPerformance,
                    start_time: moment(),
                    loginType: loginConfirm.phone !== '' ? 'Phone' : 'Email',
                    emailOrPhone: loginConfirm.phone !== '' ? loginConfirm.phone : loginConfirm.email
                }),
                userSignIn({
                    country,
                    phone: loginConfirm.phone,
                    email: loginConfirm.email,
                    password,
                    phoneLogin: loginConfirm.phone !== '' ? true : false,
                    countryCode: loginConfirm.countryCode,
                    deviceUUID: loginConfirm.deviceUUID
              }))
            : localLoginConfirm
            ? (setLoginPerformance({
                    ...loginPerformance,
                    start_time: moment(),
                    loginType: localLoginConfirm.phone !== '' ? 'Phone' : 'Email',
                    emailOrPhone: localLoginConfirm.phone !== '' ? localLoginConfirm.phone : localLoginConfirm.email
                }),
                userSignIn({
                    country,
                    phone: localLoginConfirm.phone,
                    email: localLoginConfirm.email,
                    password,
                    phoneLogin: localLoginConfirm.phone !== '' ? true : false,
                    countryCode: localLoginConfirm.countryCode,
                    deviceUUID: localLoginConfirm.deviceUUID
              }))
            : this.redirectBack();
    };
    redirectBack = () => {
        this.props.showMessage('notifications.error.fail', 'warning');
        this.props.history.push('/signin');
    };
    submitCode = () => {
        let { inputCode, country, phone, email, password, phoneLogin, capsLockOn } = this.state;
        let { fetchAuthPin } = this.props;
        let deviceUUID = cuid();
        fetchAuthPin({
            pin: inputCode,
            login: { country, phone, email, password, phoneLogin, countryCode: country.phone[0].toString(), deviceUUID: deviceUUID }
        });
    };
    newPin = () => {
        const { sendAuthPin, submitNotificationPerformance } = this.props;
        const { phone, email, phoneLogin } = this.state;
        submitNotificationPerformance({
            notification_id: phoneLogin ? phone : email,
            notification_type: 'password_reset',
            notification_name: '2_factor_pin',
            start_time: moment().utc()
        });
        sendAuthPin();
    };
    onChangePin = (value) => {
        this.setState({ inputCode: value });
    };
    viewKnowledgeBase = () => {
        window.location = 'https://www.janusintl.com/knowledge';
    };
    handleEnter = (e) => {
        if (e.keyCode == 13) {
            this.submitCode();
        }
    };
    passwordIssue = () => {
        const { password, capsLockOn } = this.state;
        const { intl } = this.props;
        const passwordContainsSpace = /^\s|\s$/.test(password);
        if (capsLockOn && passwordContainsSpace) {
            return <div className={css(this.styles.passwordIssueSpan)}>{intl.formatMessage({ id: 'form.login.passwordCapsLockSpace' })}</div>
        } else if (capsLockOn) {
            return <div className={css(this.styles.passwordIssueSpan)}>{intl.formatMessage({ id: 'form.login.passwordCapsLock' })}</div>
        } else if (passwordContainsSpace) {
            return <div className={css(this.styles.passwordIssueSpan)}>{intl.formatMessage({ id: 'form.login.passwordSpace' })}</div>
        } else {
            return <div className={css(this.styles.passwordIssueSpan)}></div>
        }
    }
    render() {
        const { intl, theme, showPin, loader, invalidLoginModal, setLoginAttemptModal, width } = this.props;
        let { phone, email, password, phoneLogin, reveal, inputCode } = this.state;
        this.styles = styles(theme);
        let currentOutage = JSON.parse(sessionStorage.getItem('current_outage'));
        let pendingOutage = JSON.parse(sessionStorage.getItem('pending_outage'));
        return (
            <div className={css(this.styles.wrapper)}>
                <Modal isOpen={showPin} autoFocus={false}>
                    <ModalHeader
                        style={{
                            border: 'none',
                            background: theme.color.compColor,
                            color: theme.color.fontColor,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4
                        }}>
                        <div>
                            <i className="material-icons" style={{ color: theme.color.themeColor, marginRight: 5 }}>
                                security
                            </i>
                            {intl.formatMessage({ id: 'form.device2FA' })}
                        </div>
                    </ModalHeader>
                    <ModalBody
                        style={{ border: 'none', background: theme.color.compColor, color: theme.color.fontColor }}>
                        <p style={{ color: theme.color.fontColor }}>{intl.formatMessage({ id: 'form.deviceCode' })}:</p>
                        <div className={css(this.styles.inputWrapperPin)} onKeyDown={(e) => this.handleEnter(e)}>
                            <ReactCodeInput
                                fields={5}
                                inputMode={'numeric'}
                                onChange={this.onChangePin}
                                value={inputCode}
                                inputStyle={{
                                    outline: 'none',
                                    textAlign: 'center',
                                    width: width > 1135 ? 40 : width > 320 ? 30 : 20,
                                    height: width > 1135 ? 40 : width > 320 ? 30 : 20,
                                    marginRight: 5,
                                    border: '1px solid' + theme.color.borderColor,
                                    borderRadius: 4,
                                    background: theme.color.compColor,
                                    color: theme.color.fontColor
                                }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter
                        style={{
                            border: 'none',
                            background: theme.color.compColor,
                            color: theme.color.fontColor,
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4
                        }}>
                        <CustomButton action={() => this.newPin()} text={`form.resendPin`} style={{ float: 'left' }} />
                        <CustomButton
                            action={() => this.submitCode()}
                            text={`comp.button.submit`}
                            style={{ float: 'right' }}
                        />
                    </ModalFooter>
                </Modal>
                <Modal isOpen={invalidLoginModal} autoFocus={false}>
                    <ModalHeader
                        toggle={() => setLoginAttemptModal(false)}
                        style={{
                            border: 'none',
                            background: theme.color.compColor,
                            color: theme.color.fontColor,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4
                        }}>
                        <div>
                            <i className="material-icons" style={{ color: theme.color.themeColor, marginRight: 5 }}>
                                help_outline
                            </i>
                            {intl.formatMessage({ id: 'form.login.issues' })}?
                        </div>
                    </ModalHeader>
                    <ModalBody
                        style={{
                            border: 'none',
                            background: theme.color.compColor,
                            color: theme.color.fontColor,
                            padding: '16px 28px'
                        }}>
                        <p style={{ color: theme.color.fontColor }}>
                            {intl.formatMessage({ id: 'form.login.visitKnowledgeBase' })}
                        </p>
                    </ModalBody>
                    <ModalFooter
                        style={{
                            border: 'none',
                            background: theme.color.compColor,
                            color: theme.color.fontColor,
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                        <CustomButton
                            action={() => setLoginAttemptModal(false)}
                            text={`pages.users.dismissTraining`}
                            width={'157px'}
                            style={{ float: 'left' }}
                        />
                        <CustomButton
                            action={() => this.viewKnowledgeBase()}
                            text={`form.login.knowledgeBase`}
                            width={'157px'}
                            style={{ float: 'right' }}
                        />
                    </ModalFooter>
                </Modal>
                <Form style={{ minHeight: 90 }} onSubmit={this.login}>
                    <Label style={{ minHeight: 34 }} className={css(this.styles.label, this.styles.login)}>
                        {intl.formatMessage({ id: 'form.password' })}
                    </Label>
                    {this.passwordIssue()}
                    <div className={css(this.styles.inputWrapper)}>
                        <TextInput
                            placeholder={intl.formatMessage({ id: 'form.password' })}
                            fontSize={14}
                            type={reveal ? 'text' : 'password'}
                            value={password}
                            noBorder={true}
                            width={'100%'}
                            margin={'0px 0px 0px 0px'}
                            onChange={(e) => this.setState({ password: e.target.value })}
                            onKeyUp={(e) => e.getModifierState("CapsLock") ? this.setState({capsLockOn: true}) : this.setState({capsLockOn: false})}
                            autoFocus={isIPad13 ? false : phoneLogin ? phone != '' : email != ''}
                        />
                        <Button
                            className={css(this.styles.reveal)}
                            onClick={() => this.setState({ reveal: !reveal })}
                            color="link">
                            <i className="material-icons" style={{ float: 'right' }}>
                                {reveal ? 'visibility' : 'visibility_off'}
                            </i>
                        </Button>
                        {loader ? (
                            <div className={css(this.styles.submitLoad)}>
                                <TailSpin color={theme.color.themeColor} width={24} height={24} />
                            </div>
                        ) : (
                            <Button className={css(this.styles.submit)} type="submit" color="link">
                                <i className="material-icons" style={{ float: 'right' }}>
                                    arrow_forward
                                </i>
                            </Button>
                        )}
                    </div>
                </Form>
                <div style={{ width: '100%', marginTop: 20, display: 'flex' }}>
                    <Link to={`/signin`} style={{ textDecoration: 'none' }}>
                        <span className={css(this.styles.link)}>{intl.formatMessage({ id: 'auth.reset.back' })}</span>
                    </Link>
                    <Link to={`/signin/forgot`} style={{ marginLeft: 17, textDecoration: 'none' }}>
                        <span className={css(this.styles.link)}>
                            {intl.formatMessage({ id: 'comp.button.forgotPassword' })}
                        </span>
                    </Link>
                </div>
                <div className={css(this.styles.lang)}>
                    <LanguageSwitcher />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, app }) => {
    const { showPin, loginConfirm, loader, invalidLoginModal } = auth;
    const { theme, country, width, loginPerformance } = app;
    return { showPin, loginConfirm, theme, country, loader, invalidLoginModal, width, loginPerformance };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, {
            userSignIn,
            sendAuthPin,
            fetchAuthPin,
            showMessage,
            setLoginAttemptModal,
            setLoginPerformance,
            submitNotificationPerformance
        })(SignIn)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            marginTop: '10vh',
            fontWeight: 400,
            backgroundColor: theme.color.compColor
        },
        label: {
            color: theme.color.themeColor,
            fontSize: 18,
            fontFamily: 'Poppins'
        },
        login: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: 20,
            fontWeight: 600,
            textTransform: 'uppercase'
        },
        inputWrapper: {
            margin: '0px 0 5px 0',
            borderBottom: `solid ${theme.color.borderColor} 1px`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'space-between'
        },
        inputWrapperPin: {
            margin: '20px 0 5px 0'
        },
        submit: {
            borderRadius: 0,
            fontSize: 12,
            padding: 0,
            margin: 0,
            width: '7%',
            color: theme.color.themeColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        submitLoad: {
            textAlign: 'right',
            borderRadius: 0,
            padding: 0,
            margin: 0,
            width: '7%',
            maxHeight: '100%',
            color: theme.color.themeColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        reveal: {
            borderRadius: 0,
            fontSize: 12,
            padding: 0,
            margin: 0,
            width: '7%',
            color: theme.color.themeColor,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        link: {
            color: theme.color.buttonFontColor,
            background: theme.color.themeColor,
            textDecoration: 'none',
            outline: 'none',
            padding: '5px 15px',
            fontSize: 12,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            borderRadius: 4,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8',
                textDecoration: 'none',
                outline: 'none'
            }
        },
        lang: {
            marginTop: 20,
            display: 'flex',
            gap: 10,
            alignItems: 'center',
        },
        status: {
            position: 'absolute',
            right: 120,
            bottom: 20,
            padding: 10,
            backgroundColor: theme.color.themeColor,
            border: 'none',
            borderRadius: 4,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                opacity: 0.8
            },
            '@media(max-width:500px)': {
                right: 10
            }
        },
        statusOutage: {
            position: 'absolute',
            right: 120,
            bottom: 20,
            padding: 10,
            backgroundColor: '#FF1919',
            border: 'none',
            borderRadius: 4,
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                opacity: 0.8
            },
            '@media(max-width:500px)': {
                right: 10
            }
        },
        passwordIssueSpan: {
            height: 25,
            color: '#FF1919'
        }
    });
