const guide = {
    en: 'en-US',
    da: 'da-DA',
    de: 'de-DE',
    pt: 'pt-PT',
    es: 'es-ES',
    fr: 'fr-FR',
    se: 'se-SE',
    no: 'no-NO',
    pl: 'pl-PL',
    nl: 'nl-NL',
    et: 'et-ET',
    it: 'it-IT',
    lt: 'lt-LT',
    fi: 'fi-FI',
    sk: 'sk-SK'
}

const dynamicLoad = async (file, cb) => {
    // console.log("file" ,file );
    import(`${file}`)
    .then((res) => {
        return cb(res, null);
    })
    .catch((error) => {
        cb(null, error);
        console.error("Couldn't load file - ", error);
    });
};

const getLang = async (locale) => {
    const p = new Promise((resolve, reject) => {
        dynamicLoad(`./entries/${guide[locale]}`, (data, error) => { 
           // console.log("guide[locale]" ,guide[locale] );
           // console.log("locale" ,locale );
            if (error) reject(error);
            resolve(data);
        });
    })
    let data = await p;
    return data;
};

export { getLang };
