import React from 'react';
import { Button, Form, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import LanguageSwitcher from 'components/LanguageSwitcher';
import AuthTemplate from 'components/Auth';
import SelectInput from 'components/Inputs/select';
import { userSignIn, userSignOut } from 'actions/Auth';
import { setLoginPerformance } from 'actions/App';

class Site extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: null,
            sites: null,
            site: 0
        };
    }
    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/signin');
        } else {
            let { login, sites } = this.props.location.state;
            this.setState({
                login,
                sites,
                site: JSON.parse(localStorage.getItem('nse_login_data')).site || sites[0].id
            });
        }
        document.title = 'Facility Select | Smart Entry';
    }
    login = (e) => {
        e.preventDefault();
        let { login, site } = this.state;
        const { loginPerformance, setLoginPerformance } = this.props;
        login.siteId = JSON.parse(site);
        setLoginPerformance({
            ...loginPerformance,
            start_time: moment(),
            loginType: login.phoneLogin ? 'Phone' : 'Email',
            emailOrPhone: login.phoneLogin ? login.phone : login.email
        });
        this.props.userSignIn(login);
    };
    render() {
        const { intl, theme } = this.props;
        let { sites, site } = this.state;
        this.styles = styles(theme);
        return (
            sites && (
                <AuthTemplate theme={theme}>
                    <div className={css(this.styles.wrapper)}>
                        <Form onSubmit={this.login}>
                            <Label className={css(this.styles.label)}>
                                {intl.formatMessage({ id: 'auth.site.header' })}
                            </Label>
                            <div className={css(this.styles.inputWrapper)}>
                                <SelectInput
                                    options={sites && sites.map((s) => ({ text: s.name, key: s.uuid, value: s.uuid }))}
                                    value={site}
                                    width={'90%'}
                                    onChange={(e) => this.setState({ site: e.target.value })}
                                    fontSize={14}
                                    hideDropdown={true}
                                    noBorder={true}
                                    noMargin={true}
                                />
                                <Button className={css(this.styles.submit)} type="submit" color="link">
                                    <i className="material-icons" style={{ float: 'right' }}>
                                        arrow_forward
                                    </i>
                                </Button>
                            </div>
                        </Form>
                        <a title="Sign Out Link" onClick={() => this.props.userSignOut()} style={{ marginBottom: 50 }}>
                            <span className={css(this.styles.link)}>
                                {intl.formatMessage({ id: 'comp.button.cancel' })}
                            </span>
                        </a>
                        <div className={css(this.styles.lang)}>
                            <LanguageSwitcher />
                        </div>
                    </div>
                </AuthTemplate>
            )
        );
    }
}

const mapStateToProps = ({ app }) => {
    const { theme, loginPerformance } = app;
    return { theme, loginPerformance };
};

export default injectIntl(
    connect(mapStateToProps, {
        userSignIn,
        userSignOut,
        setLoginPerformance
    })(Site)
);

const styles = (theme) =>
    StyleSheet.create({
        wrapper: {
            marginTop: '10vh',
            fontWeight: 400,
            backgroundColor: theme.color.compColor
        },
        label: {
            color: theme.color.themeColor,
            fontSize: 18
        },
        inputWrapper: {
            margin: '20px 0 5px 0',
            display: 'flex',
            borderBottom: `solid ${theme.color.borderColor} 1px`
        },
        submit: {
            borderRadius: 0,
            fontSize: 12,
            padding: 0,
            margin: '0px 0px 7px 0px',
            width: '10%',
            color: theme.color.themeColor
        },
        link: {
            color: theme.color.fontColor,
            textDecoration: 'underline',
            fontSize: 12,
            ':hover': {
                cursor: 'pointer',
                opacity: '.8'
            }
        },
        lang: {
            marginTop: 40
        }
    });
