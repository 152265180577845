import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore from './store';
import { history } from './store/history';
import App from 'app';
import * as Sentry from "@sentry/react";

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import packageInfo from '../package.json';
import withGAHistoryTrack from 'components/Analytics';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// refactor a function generating Sentry init parameters to be dynamic based on the environment
const initParams = (env) => {
  return {
    dsn: 'https://d1b064014eb84f1491713022609fdee9@sentry.io/1827076',
    environment: env,
    ignoreErrors: [
      "Loading chunk",
      "ChunkLoadError",
      "Extension context invalidated",
      "ResizeObserver loop limit exceeded",
    ],
    //debug: true,
    release: "janus-react@" + packageInfo.version,
  }
}

const domain = window.location.href;
const frontendVersion = sessionStorage.getItem('frontendVersion');
const originalPath = sessionStorage.getItem('originalPath');

if((domain.includes('com') || domain.includes('party')) && !window.navigator.webdriver)
    {
        LogRocket.init('runtfp/janus-react', {
          release: packageInfo.version,
          network: {
              requestSanitizer: (request) => {
                  if (
                      request.url.toLowerCase().indexOf('login') !== -1 ||
                      request.url.toLowerCase().indexOf('password') !== -1
                  ) {
                      request.body = null;
                  }
                  if (request.headers['Authorization']) {
                      request.headers['Authorization'] = '';
                  }
                  return request;
              },
              responseSanitizer: (response) => {
                  var parsedResponseBody = null;
                  try {
                      parsedResponseBody = JSON.parse(response.body);
                      if (parsedResponseBody['token']) {
                      parsedResponseBody['token'] = '';
                      response.body = JSON.stringify(parsedResponseBody);
                    }
                  }
                  catch (e) { }
                  return response;
                }
          }
      })
      setupLogRocketReact(LogRocket)
    } else {
        null
    };
domain.includes('run')
  ? Sentry.init(initParams('dev'))
  : domain.includes('dev')
    ? Sentry.init(initParams('dev'))
    : domain.includes('noke')
      ? Sentry.init(initParams('prod'))
      : Sentry.init(initParams('prod'))

LogRocket.getSessionURL((sessionURL) => {
  Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
});

export const store = configureStore();
const container = document.getElementById('app-site');
const root = createRoot(container);

const handleVersionChange = () => {
  const frontendVersion = sessionStorage.getItem('frontendVersion');
  const currentPath = window.location.pathname;
  const versionRegex = /^\/v\d+\//; // Matches paths like "/v1/", "/v2/", etc.

  // Remove any existing version from the path
  const cleanPath = currentPath.replace(versionRegex, '/');

  let newPath;
  
  if (!frontendVersion || frontendVersion === "undefined" || frontendVersion === "default") {
      // If frontendVersion is default or undefined, use cleanPath without version
      newPath = cleanPath;
  } else {
      // Otherwise, construct the versioned path
      newPath = `/${frontendVersion}${cleanPath}`;
  }
  // Prevent unnecessary redirection if already on the correct version
  if (window.location.pathname !== newPath) {
      sessionStorage.setItem('originalPath', cleanPath + window.location.search);
      window.location.replace(newPath);
  }
};

// Run check on page load
document.addEventListener("DOMContentLoaded", handleVersionChange);

// Detect sessionStorage changes dynamically
window.addEventListener('storage', (event) => {
    if (event.key === 'frontendVersion') {
        handleVersionChange();
    }
});

// Detect changes dynamically in the same tab
let lastFrontendVersion = sessionStorage.getItem('frontendVersion');
setInterval(() => {
    const currentFrontendVersion = sessionStorage.getItem('frontendVersion');
    if (currentFrontendVersion && currentFrontendVersion !== lastFrontendVersion) {
        lastFrontendVersion = currentFrontendVersion;
        handleVersionChange();
    }
}, 500);
handleVersionChange();

// Also listen for **sessionStorage changes across tabs**
window.addEventListener('storage', (event) => {
    if (event.key === 'frontendVersion') {
        handleVersionChange();
    }
});

root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={withGAHistoryTrack(App)} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </Sentry.ErrorBoundary>,
);

