import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Container, Row, Col } from 'reactstrap';
import moment from 'moment';

import { submitLoginPerformance, setLoginPerformance } from 'actions/App';

import Box from 'components/Box';
import MyStores from 'components/EnterpriseDashboard/MyStores';
import Notifications from 'components/EnterpriseDashboard/Notifications';
import Employees from 'components/EnterpriseDashboard/EmployeesOnline';
import CrossSiteSearch from 'components/EnterpriseDashboard/CrossSiteSearch';
import SuspiciousActivity from 'components/EnterpriseDashboard/SuspiciousActivity';
class DashboardDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoadedCount: 0,
            allDataLoaded: false,
        }
    }
    componentDidMount() {
        document.title = 'Dashboard | Smart Entry';
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevState.allDataLoaded && this.state.allDataLoaded) {
            const { authUser, loginPerformance, submitLoginPerformance, setLoginPerformance } = this.props;
            if (loginPerformance.start_time) {
                submitLoginPerformance({
                    ...loginPerformance,
                    start_time: loginPerformance.start_time.utc(),
                    overall_time: moment().diff(loginPerformance.start_time),
                    end_time: moment().utc(),
                    site_id: authUser.defaultSiteId,
                    userId: authUser.id,
                    success: 1,
                    isValid: '1'
                })
                setLoginPerformance({
                    site_id: 0,
                    platform: 'Portal',
                    userId: 0,
                    loginType: '',
                    emailOrPhone: '',
                    start_time: null,
                    login_api_end_time: null,
                    overall_time: 0,
                    end_time: null,
                    failure_reason: '',
                    isOnline: 1,
                    success: 0,
                    isValid: '0'
                })
            }
        }
    }
    handleChildDataLoaded = () => {
        this.setState(
            (prevState) => ({
                dataLoadedCount: prevState.dataLoadedCount + 1,
            }),
            () => {
                if (this.state.dataLoadedCount === 3) {
                    this.setState({ allDataLoaded: true });
                }
            }
        );
    };
    render() {
        const { width } = this.props;
        return (
            <Container fluid>
                <Row style={{paddingBottom: 17}}>
                    <Col xs={12} xl={6} style={{paddingRight: width < 1200 ? 17 : 0}}>
                        <Box customHeight={500}>
                            <MyStores onDataLoaded={this.handleChildDataLoaded} />
                        </Box>
                    </Col>
                    <Col xs={12} xl={6}>
                        <Box customHeight={500}>
                            <CrossSiteSearch />
                        </Box>
                    </Col>
                    <Col xs={12} xl={6} style={{paddingRight: width < 1200 ? 17 : 0}}>
                        <Box customHeight={500}>
                            <Notifications onDataLoaded={this.handleChildDataLoaded} />
                        </Box>
                    </Col>
                    <Col xs={12} xl={6}>
                        <Box customHeight={500}>
                            <SuspiciousActivity onDataLoaded={this.handleChildDataLoaded} />
                        </Box>
                    </Col>
                    {/* <Col xs={12}>
                        <Box>
                            <Employees />
                        </Box>
                    </Col> */}
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ app, auth }) => {
    const { authUser } = auth;
    const { theme, width, height, loginPerformance } = app;
    return { theme, width, height, loginPerformance, authUser };
};

export default withRouter(injectIntl(connect(mapStateToProps, {submitLoginPerformance, setLoginPerformance})(DashboardDisplay)));
