import * as CONSTANTS from 'constants';
import * as ICONS from 'utilities/icons';

const INIT_STATE = {
    settingsData: null,
    pmsDetails: null,
    activeSetting: 'theme',
    featureFlagsList: null,
    featureFlagsAssignmentsList: [],
    featureFlag: [],
    scheduleSuite: null,
    scheduleSuiteUsers: null,
    siteSchedules: null,
    holidayHours: null,
    siteHours: null,
    siteCreateHours: null,
    siteMessages: null,
    siteSpecificMessages: null,
    siteSpecificVariables: null,
    siteSpecificSMSMessages: null,
    siteSpecificEmailMessages: null,
    siteSpecificPushMessages: null,
    siteSpecificNotificationMessages: null,
    siteNotificationMessages: null,
    siteLanguages: null,
    fobDetails: null,
    trainingList: null,
    loginTypes: null,
    entryMessages: null,
    auditData: null,
    historicalAuditData: null,
    individualAuditData: null,
    digitalAuditsList: null,
    digitalAuditsFacilities: null,
    digitalAuditsFacilitiesStats: {
        fail: {
            finalApproval: {
                approved: 0,
                approvedPercent: 0.0,
                denied: 0,
                deniedPercent: 0.0,
                pending: 0,
                pendingPercent: 0.0
            },
            initialApproval: {
                approved: 0.0,
                approvedPercent: 0.0,
                denied: 0,
                deniedPercent: 0.0,
                pending: 0,
                pendingPercent: 0.0
            },
            pendingApprovalSubmision: 0,
            pendingApprovalSubmisionPercent: 0.0,
            total: 0,
            totalPercent: 0.0
        },
        notConducted: 0,
        notConductedPercent: 0.0,
        pass: {
            finalApproval: {
                approved: 0,
                approvedPercent: 0.0,
                denied: 0,
                deniedPercent: 0.0,
                pending: 0,
                pendingPercent: 0.0
            },
            initialApproval: {
                approved: 0,
                approvedPercent: 0.0,
                denied: 0,
                deniedPercent: 0.0,
                pending: 0,
                pendingPercent: 0.0
            },
            pendingApprovalSubmision: 0,
            pendingApprovalSubmisionPercent: 0.0,
            total: 0,
            totalPercent: 0.0
        },
        review: {
            finalApproval: {
                approved: 0,
                approvedPercent: 0.0,
                denied: 0,
                deniedPercent: 0.0,
                pending: 0,
                pendingPercent: 0.0
            },
            initialApproval: {
                approved: 0,
                approvedPercent: 0.0,
                denied: 0,
                deniedPercent: 0.0,
                pending: 0,
                pendingPercent: 0.0
            },
            pendingApprovalSubmision: 0,
            pendingApprovalSubmisionPercent: 0.0,
            total: 0,
            totalPercent: 0.0
        },
        Sumations: {
            IAPending: 0,
            IAPendingPercent: 0.0,
            IAApproved: 0,
            IAApprovedPercent: 0.0,
            IADenied: 0,
            IADeniedPercent: 0.0,
            FAPending: 0,
            FAPendingPercent: 0.0,
            FAApproved: 0,
            FAApprovedPercent: 0.0,
            FADenied: 0,
            FADeniedPercent: 0.0,
            PASubmission: 0,
            PASubmissionPercent: 0.0
        },
        total: 0
    },
    digitalAuditsFacilitiesCompanies: null,
    digitalAuditsFacilities: null,
    digitalAuditSummary: null,
    digitalAuditHistory: null,
    digitalAuditDetail: null,
    hubspotTickets: null,
    hubspotTicket: null,
    ecobeePinData: null,
    thermostatData: null,
    siteStatTotals: null,
    hubspotFields: null,
    siteCreateData: null,
    companyCreateData: null,
    screenImages: null,
    scheduleHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'db.blank',
            active: false,
            sortable: false,
            order: true,
            mobile: false,
            sortTitle: 'hours',
            style: { textAlign: 'left' }
        }
    ],
    trainingHeader: [
        {
            id: 0,
            title: 'db.blank',
            active: false,
            sortable: false,
            order: false,
            sortTitle: 'name',
            mobile: false,
            width: 60,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.username',
            active: true,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'userFirstName',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'pages.users.supportUsername',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'supportUserFirstName',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'pages.sites.Type',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'siteName',
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'comp.table.header.date.update',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'updated',
            style: { textAlign: 'right' }
        }
    ],
    entryMessageHeader: [
        {
            id: 0,
            title: 'db.blank',
            active: false,
            sortable: false,
            order: false,
            sortTitle: 'name',
            mobile: false,
            width: 60,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'form.title',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'messageTitle',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'form.body',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'messageBody',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'form.messageFrequency',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'messageFrequency',
            width: 145,
            style: { textAlign: 'left' }
        },
        {
            id: 4,
            title: 'form.from',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'startTime',
            width: 160,
            style: { textAlign: 'right' }
        },
        {
            id: 5,
            title: 'form.to',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'endTime',
            width: 160,
            style: { textAlign: 'right' }
        }
    ],
    settingsMenuList: [
        {
            title: 'pages.themes.Type',
            url: 'theme/all',
            reference: 'theme',
            icon: ICONS.NewsIcon,
            tooltip: 'tooltip.settings.theme'
        },
        {
            title: 'pages.sites.Type',
            url: 'info/all',
            reference: 'info',
            icon: ICONS.SiteIcon,
            permission: ['manage_sites'],
            tooltip: 'tooltip.settings.site'
        },
        {
            title: 'pages.company.Type',
            url: 'company/all',
            reference: 'company',
            icon: ICONS.SitemapIcon,
            permission: ['support_view_all_companies'],
            tooltip: 'tooltip.settings.company'
        },
        {
            title: 'pages.branding.Type',
            url: 'branding/all',
            reference: 'branding',
            icon: ICONS.SettingsIcon,
            permission: ['manage_sites'],
            tooltip: 'tooltip.settings.branding'
        },
        {
            title: 'pages.zones.Types',
            url: 'zones/all',
            reference: 'zones',
            icon: ICONS.SiteIcon,
            permission: ['view_zones'],
            tooltip: 'tooltip.settings.zones'
        },
        {
            title: 'pages.featureflags.Types',
            url: 'features/all',
            reference: 'features',
            icon: ICONS.EmojiFlags,
            permission: ['support_manage_featureflags'],
            tooltip: 'tooltip.settings.features'
        },
        {
            title: 'comp.rolesPermissions.HEADERS',
            url: 'roles/all',
            reference: 'roles',
            icon: ICONS.UserIcon,
            permission: ['manage_roles'],
            tooltip: 'tooltip.settings.roles'
        },
        {
            title: 'pages.home.notifications',
            url: 'globalnotifs/all',
            reference: 'globalnotifs',
            icon: ICONS.NotifyIcon,
            tooltip: 'tooltip.settings.notifications'
        },
        {
            title: 'pages.logs.Types',
            url: 'logs/all',
            reference: 'logs',
            icon: ICONS.ActivityIcon,
            permission: ['support_view_logs'],
            tooltip: 'tooltip.settings.logs'
        },
        {
            title: 'pages.update.Type',
            url: 'sync/all',
            reference: 'sync',
            icon: ICONS.SyncIcon,
            permission: ['support_view_pms_data'],
            pms: true,
            tooltip: 'tooltip.settings.sync'
        },
        {
            title: 'pages.messages.Types',
            url: 'messages/all',
            reference: 'messages',
            icon: ICONS.EmailIcon,
            permission: ['manage_sites'],
            tooltip: 'tooltip.settings.messages'
        },
        {
            title: 'pages.pms.short',
            url: 'pms',
            reference: 'pms',
            icon: ICONS.SettingsIcon,
            permission: ['support_view_pms_data'],
            tooltip: 'tooltip.settings.pms'
        },
        {
            title: 'filters.units.nokeScreen',
            url: 'screen',
            icon: ICONS.ImageIcon,
            tooltip: 'filters.units.nokeScreen',
            permission: ['manage_company'],
            siteProperty: 'has4K',
            newFeature: 'noke_screen_branding'
        },
        {
            title: 'pages.wifi.type',
            url: 'wifi/all',
            reference: 'wifi',
            icon: ICONS.WifiIcon,
            permission: ['manage_sites'],
            tooltip: 'tooltip.settings.wifi'
        },
        {
            title: 'pages.fobs.TYPES',
            url: 'fobs/all',
            reference: 'fobs',
            icon: ICONS.FobIcon,
            permission: ['user_fobs'],
            tooltip: 'tooltip.settings.fobs'
        },
        {
            title: 'pages.users.training',
            url: 'training/all',
            reference: 'training',
            icon: ICONS.FileIcon,
            permission: ['support_send_digital_ack'],
            tooltip: 'tooltip.settings.training'
        },
        {
            title: 'comp.forwarding.Header',
            url: 'forwarding/all',
            reference: 'forwarding',
            icon: ICONS.ActivityIcon,
            permission: ['support_manage_activity_redirects'],
            tooltip: 'tooltip.settings.forwarding'
        },
        {
            title: 'comp.integration.Header',
            url: 'integrations/all',
            reference: 'integrations',
            icon: ICONS.IntegrationIcon,
            permission: ['manage_thermostat'],
            tooltip: 'tooltip.settings.integrations',
            newFeature: 'ecobee_integration'
        },
        {
            title: 'pages.security.Type',
            url: 'security/all',
            reference: 'security',
            icon: ICONS.AlarmIcon,
            permission: ['manage_locks'],
            tooltip: 'tooltip.settings.security'
        },
        {
            title: 'pages.repeaters.Types',
            url: 'repeaters/all',
            reference: 'repeaters',
            icon: ICONS.RepeaterIcon,
            permission: ['view_site_locks'],
            tooltip: 'tooltip.settings.repeaters'
        }
    ],
    featureFlagHeader: [
        {
            id: 0,
            title: 'comp.activity.user',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'type',
            mobile: true,
            width: 150,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'form.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 225,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'form.description',
            active: false,
            sortable: true,
            order: true,
            sortTitle: 'description',
            mobile: false,
            style: { textAlign: 'left' }
        },
    ],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_SETTINGS: {
            return {
                ...state,
                settingsData: action.payload
            };
        }
        case CONSTANTS.SET_PMS_DETAILS: {
            return {
                ...state,
                pmsDetails: action.payload
            };
        }
        case CONSTANTS.SET_SETTINGS_ACTIVE: {
            return {
                ...state,
                activeSetting: action.payload
            };
        }
        case CONSTANTS.SET_FEATURE_FLAGS: {
            return {
                ...state,
                featureFlagsList: action.payload
            };
        }
        case CONSTANTS.SET_FEATURE_FLAG_ASSIGNMENTS: {
            return {
                ...state,
                featureFlagsAssignmentsList: action.payload
            };
        }
        case CONSTANTS.SET_FEATURE_FLAG: {
            return {
                ...state,
                featureFlag: action.payload
            };
        }
        case CONSTANTS.SET_SITE_HOURS: {
            return {
                ...state,
                siteHours: action.payload
            };
        }
        case CONSTANTS.SET_SITE_CREATE_HOURS: {
            return {
                ...state,
                siteCreateHours: action.payload
            };
        }
        case CONSTANTS.SET_SCHEDULE: {
            return {
                ...state,
                scheduleSuite: action.payload
            };
        }
        case CONSTANTS.SET_SCHEDULE_USERS: {
            return {
                ...state,
                scheduleSuiteUsers: action.payload
            };
        }
        case CONSTANTS.SET_ALL_SCHEDULES: {
            return {
                ...state,
                siteSchedules: action.payload
            };
        }
        case CONSTANTS.SET_HOLIDAY_HOURS: {
            return {
                ...state,
                holidayHours: action.payload
            };
        }
        case CONSTANTS.SET_MESSAGES: {
            return {
                ...state,
                siteMessages: action.payload
            };
        }
        case CONSTANTS.SET_SITE_MESSAGES: {
            return {
                ...state,
                siteSpecificMessages: action.payload
            };
        }
        case CONSTANTS.SET_SITE_VARIABLES: {
            return {
                ...state,
                siteSpecificVariables: action.payload
            };
        }
        case CONSTANTS.SET_NOTIFICATION_MESSAGES: {
            return {
                ...state,
                siteNotificationMessages: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SPECIFIC_SMS_MESSAGES: {
            return {
                ...state,
                siteSpecificSMSMessages: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SPECIFIC_EMAIL_MESSAGES: {
            return {
                ...state,
                siteSpecificEmailMessages: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SPECIFIC_PUSH_MESSAGES: {
            return {
                ...state,
                siteSpecificPushMessages: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SPECIFIC_NOTIFICATION_MESSAGES: {
            return {
                ...state,
                siteSpecificNotificationMessages: action.payload
            };
        }
        case CONSTANTS.SET_LANGUAGES: {
            return {
                ...state,
                siteLanguages: action.payload
            };
        }
        case CONSTANTS.SET_FOB_DETAILS: {
            return {
                ...state,
                fobDetails: action.payload
            };
        }
        case CONSTANTS.SET_TRAINING_LIST: {
            return {
                ...state,
                trainingList: action.payload
            };
        }
        case CONSTANTS.SET_LOGIN_TYPES: {
            return {
                ...state,
                loginTypes: action.payload
            };
        }
        case CONSTANTS.SET_ENTRY_MESSAGES: {
            return {
                ...state,
                entryMessages: action.payload
            };
        }
        case CONSTANTS.SET_AUDIT: {
            return {
                ...state,
                auditData: action.payload
            };
        }
        case CONSTANTS.SET_HISTORICAL_AUDITS: {
            return {
                ...state,
                historicalAuditData: action.payload
            };
        }
        case CONSTANTS.SET_INDIVIDUAL_AUDIT: {
            return {
                ...state,
                individualAuditData: action.payload
            };
        }
        case CONSTANTS.SET_DIGITAL_AUDITS_LIST: {
            return {
                ...state,
                digitalAuditsList: action.payload
            };
        }
        case CONSTANTS.SET_DIGITAL_AUDITS_FACILITIES: {
            return {
                ...state,
                digitalAuditsFacilities: action.payload
            };
        }
        case CONSTANTS.SET_DIGITAL_AUDITS_FACILITIES_STATS: {
            return {
                ...state,
                digitalAuditsFacilitiesStats: action.payload
            };
        }
        case CONSTANTS.SET_DIGITAL_AUDITS_FACILITIES_COMPANIES: {
            return {
                ...state,
                digitalAuditsFacilitiesCompanies: action.payload
            };
        }
        case CONSTANTS.SET_DIGITAL_AUDIT_SUMMARY: {
            return {
                ...state,
                digitalAuditSummary: action.payload
            };
        }
        case CONSTANTS.SET_DIGITAL_AUDIT_HISTORY: {
            return {
                ...state,
                digitalAuditHistory: action.payload
            };
        }
        case CONSTANTS.SET_DIGITAL_AUDIT_DETAIL: {
            return {
                ...state,
                digitalAuditDetail: action.payload
            };
        }
        case CONSTANTS.SET_HUBSPOT_TICKETS: {
            return {
                ...state,
                hubspotTickets: action.payload
            };
        }
        case CONSTANTS.SET_HUBSPOT_TICKET: {
            return {
                ...state,
                hubspotTicket: action.payload
            };
        }
        case CONSTANTS.SET_ECOBEE_PIN: {
            return {
                ...state,
                ecobeePinData: action.payload
            };
        }
        case CONSTANTS.SET_THERMOSTAT: {
            return {
                ...state,
                thermostatData: action.payload
            };
        }
        case CONSTANTS.SET_SITE_STATS: {
            return {
                ...state,
                siteStatTotals: action.payload
            };
        }
        case CONSTANTS.SET_HUBSPOT_FIELDS: {
            return {
                ...state,
                hubspotFields: action.payload
            };
        }
        case CONSTANTS.SET_TRAINING_HEADERS: {
            return {
                ...state,
                trainingHeader: action.payload
            };
        }
        case CONSTANTS.SET_SITE_CREATE_DATA: {
            return {
                ...state,
                siteCreateData: action.payload
            }
        }
        case CONSTANTS.SET_COMPANY_CREATE_DATA: {
            return {
                ...state,
                companyCreateData: action.payload
            }
        }
        case CONSTANTS.SET_SCREEN_IMAGES: {
            return {
                ...state,
                screenImages: action.payload,
            }
        }
        case CONSTANTS.SET_FEATURE_FLAG_HEADERS: {
            return {
                ...state,
                featureFlagHeader: action.payload,
            }
        }
        default:
            return state;
    }
};
