const languageData = [
    {
        locale: 'en',
        name: 'English'
    },
    {
        locale: 'da',
        name: 'Dansk'
    },
    {
        locale: 'de',
        name: 'Deutsche'
    },
    {
        locale: 'et',
        name: 'Eesti Keel'
    },
    {
        locale: 'es',
        name: 'Español'
    },
    {
        locale: 'fr',
        name: 'Français'
    },
    {
        locale: 'it',
        name: 'Italiano'
    },
    {
        locale: 'lt',
        name: 'Lietuvių'
    },
    {
        locale: 'nl',
        name: 'Nederlands'
    },
    {
        locale: 'no',
        name: 'Norsk'
    },
    {
        locale: 'pl',
        name: 'Polskie'
    },
    {
        locale: 'pt',
        name: 'Português'
    },
    {
        locale: 'se',
        name: 'Svensk'
    },
    {
        locale: 'fi',
        name: 'Suomi'
    },
    {
        locale: 'sk',
        name: 'Slovensko'
    },
  
];
export default languageData;
