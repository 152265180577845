import * as CONSTANTS from 'constants';

const INIT_STATE = {
    unitsList: null,
    locksList: null,
    unitsListStats: null,
    unitsListLockStats: null,
    unitsListLockStatusStats: null,
    unitObject: null,
    lockObject: null,
    lockMotionObject: null,
    siteMotionObject: null,
    activeUnit: 'device',
    activeLock: 'info',
    unitExternalId: null,
    zoneSelected: '',
    openUnits: null,
    lockHolds: null,
    unlockStatus: null,
    lowBatteryUnits: null,
    lowBatteryUnitsReplaced: null,
    newBatteryEndpointNotExist: false,
    lockFilterUnit: false,
    unitsFilter: 'filters.all',
    lockTypeFilter: 'all',
    lockStatusFilter: 'all',
    lockSettings: null,
    currentLockSchedules: null,
    currentSiteLockSchedules: null,
    showDepth: false,
    lastSyncInfo: null,
    syncUnits: null,
    syncPmsUnits: null,
    syncPmsChanges: null,
    sharedUnits: null,
    siteShares: null,
    siteFobs: null,
    allUnitNotes: null,
    unitNotes: null,
    unitNote: null,
    lockManufacturingData: null,
    temperatureUnits: null,
    unitTaskComments: null,
    repeaterList: null,
    unitHeaderWebhooks: [
        {
            id: 0,
            title: 'comp.table.header.name',
            width: 200,
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 100,
            sortTitle: 'rentalState',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'form.state',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'lockState',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.price',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            hide: true,
            laptop: true,
            sortTitle: 'detailsPrice'
        },
        {
            id: 4,
            title: 'comp.table.header.depth',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            hide: true,
            laptop: true,
            sortTitle: 'detailsDepth'
        },
        {
            id: 5,
            title: 'comp.table.header.width',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            hide: true,
            laptop: true,
            sortTitle: 'detailsWidth'
        },
        {
            id: 6,
            title: 'pages.users.schedule.type',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 85,
            sortTitle: 'userSchedule',
            style: { textAlign: 'left' }
        },
        {
            id: 7,
            title: 'db.shared',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 80,
            tablet: true,
            sortTitle: 'shared'
        },
        {
            id: 8,
            title: 'form.sharedManager',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 125,
            tablet: true,
            sortTitle: 'shareWManagers'
        },
        {
            id: 9,
            title: 'pages.zones.Types',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            laptop: true,
            sortTitle: 'zoneUUIDs',
            style: { textAlign: 'left' }
        },
        {
            id: 10,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 125,
            laptop: true
        },
        {
            id: 11,
            title: 'comp.table.header.moveInDate',
            active: false,
            sortable: true,
            sortTitle: 'moveInDate',
            order: false,
            mobile: true,
            width: 125,
            laptop: false,
            style: { textAlign: 'right' }
        }

    ],
    unitsHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            width: 200,
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 100,
            sortTitle: 'rentalState',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'form.state',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 110,
            sortTitle: 'lockState',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.price',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            hide: true,
            laptop: true,
            sortTitle: 'detailsPrice'
        },
        {
            id: 4,
            title: 'comp.table.header.depth',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            hide: true,
            laptop: true,
            sortTitle: 'detailsDepth'
        },
        {
            id: 5,
            title: 'comp.table.header.width',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            hide: true,
            laptop: true,
            sortTitle: 'detailsWidth'
        },
        {
            id: 6,
            title: 'pages.users.schedule.type',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 100,
            sortTitle: 'userSchedule',
            style: { textAlign: 'left' }
        },
        {
            id: 7,
            title: 'db.shared',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 90,
            tablet: true,
            sortTitle: 'shared'
        },
        {
            id: 8,
            title: 'form.sharedManager',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 150,
            tablet: true,
            sortTitle: 'shareWManagers'
        },
        {
            id: 9,
            title: 'pages.zones.Types',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            laptop: true,
            sortTitle: 'zoneUUIDs',
            style: { textAlign: 'left' }
        },
        {
            id: 10,
            title: 'blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 125,
            laptop: true
        }
    ],
    usersUnitsHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            width: '200px',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'rentalState'
        },
        {
            id: 2,
            title: 'comp.table.header.price',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            hide: true,
            sortTitle: 'detailsPrice'
        },
        {
            id: 3,
            title: 'comp.table.header.depth',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            hide: true,
            sortTitle: 'detailsDepth'
        },
        {
            id: 4,
            title: 'comp.table.header.width',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            hide: true,
            sortTitle: 'detailsWidth'
        }
    ],
    locksHeader: [
        {
            id: 0,
            title: 'comp.table.header.blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 50
        },
        {
            id: 1,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.mac',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'mac'
        },
        {
            id: 3,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'hwState'
        },
        {
            id: 4,
            title: 'comp.table.header.batteryVoltage',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'voltageBattery'
        },
        {
            id: 5,
            title: 'comp.table.header.wiredVoltage',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'voltageWired'
        }
    ],
    assignUnitHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            width: 200,
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'pages.zones.Types',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'zoneUUIDs',
            style: { textAlign: 'left' }
        }
    ],
    activityUnitHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            width: 200,
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'rentalState'
        }
    ],
    assignUserHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 200,
            icon: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.phone',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'phone'
        },
        {
            id: 2,
            title: 'comp.table.header.email',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'email'
        }
    ],
    unitsSyncHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            width: 230,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.externalID',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'externalId'
        },
        {
            id: 2,
            title: 'comp.table.header.blank',
            active: false,
            sortable: false,
            order: false,
            mobile: true,
            width: 150
        }
    ],
    unitsSharedHeader: [
        {
            id: 0,
            title: 'comp.table.header.blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 50
        },
        {
            id: 1,
            title: 'comp.table.header.name',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.status',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'rentalState'
        },
        {
            id: 3,
            title: 'comp.table.header.blank',
            active: false,
            sortable: false,
            order: false,
            mobile: true,
            width: 200
        }
    ],
    unitsFilters: [
        {
            title: 'filters.all',
            value: ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry', 'prelet', 'checkout'],
            active: true
        },
        {
            title: 'filters.units.available',
            value: ['available'],
            active: false
        },
        {
            title: 'filters.units.occupied',
            value: ['inuse'],
            active: false
        },
        {
            title: 'filters.units.overlock',
            value: ['available', 'inuse', 'pending'],
            active: false
        },
        {
            title: 'filters.units.gatelock',
            value: ['available', 'inuse', 'pending'],
            active: false
        },
        {
            title: 'filters.units.repo',
            value: ['available', 'inuse', 'pending'],
            active: false
        },
        {
            title: 'filters.units.auction-smartentry',
            value: ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry', 'prelet', 'checkout'],
            active: false
        },
        {
            title: 'filters.units.transfer',
            value: ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry', 'prelet', 'checkout'],
            active: false
        },
        {
            title: 'filters.units.prelet',
            value: ['prelet'],
            active: false
        },
        {
            title: 'filters.units.checkout',
            value: ['checkout'],
            active: false
        },
        {
            title: 'filters.units.other',
            value: ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry', 'prelet', 'checkout'],
            active: false
        } 
    ],
    manufacturingDataHeader: [
        {
            id: 0,
            title: 'comp.table.header.mechRevision',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'mechRevision',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.model',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'model'
        },
        {
            id: 2,
            title: 'comp.table.header.pcbVersion',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'pcbVersion'
        },
        {
            id: 3,
            title: 'comp.table.header.pcbio',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'pcbio'
        },
        {
            id: 4,
            title: 'comp.table.header.supplier',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'supplier'
        },
        {
            id: 5,
            title: 'pages.filter.units.openedDate',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'createdDate',
            width: 110,
            style: { textAlign: 'right' }
        }
    ],
    repeatScheduleHeaders: [
        {
            id: 0,
            title: 'db.blank',
            active: false,
            sortable: false,
            order: false,
            mobile: false,
            width: 45
        },
        {
            id: 1,
            title: 'form.name',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'name',
            width: 120
        },
        {
            id: 2,
            title: 'form.description',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'description',
            width: 150
        },
        {
            id: 3,
            title: 'pages.entry.Type',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'lockName',
            width: 120
        },
        {
            id: 4,
            title: 'form.startTime',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'startTime',
            width: 100
        },
        {
            id: 5,
            title: 'form.endTime',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'endTime',
            width: 100
        },
        {
            id: 6,
            title: 'comp.repeatSchedule.interval',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'interval',
            width: 100
        },
        {
            id: 7,
            title: 'comp.repeatSchedule.expirationDate',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'endDate',
            width: 80
        }
    ],
    repeatScheduleBoxHeaders: [
        {
            id: 0,
            title: 'form.name',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'name',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.repeatSchedule.interval',
            width: 80,
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'interval'
        }
    ],
    syncUnitsHeaders: [
        {
            id: 0,
            title: 'form.unit',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'unitName',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.name',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'firstName',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'db.Action',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'statusChange',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.date',
            width: '80px',
            active: true,
            sortable: true,
            order: false,
            mobile: false,
            sortTitle: 'createdDate',
            style: { textAlign: 'right' }
        }
    ],
    sharedUnitsHeaders: [
        {
            id: 0,
            title: 'form.unit',
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'unitName',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.user',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'unitOwnerName',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'db.share_user',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'sharedName',
            style: { textAlign: 'left' }
        }
    ],
    notesHeaders: [
        {
            id: 0,
            title: 'pages.notes.heading',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'heading',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'pages.notes.dueDate',
            width: 80,
            active: true,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'dueDate'
        }
    ],
    commentsHeaders: [
        {
            id: 0,
            title: 'form.comment',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            sortTitle: 'comment',
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'comp.table.header.user',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'firstName'
        },
        {
            id: 2,
            title: 'comp.table.header.date.create',
            width: 90,
            active: true,
            sortable: true,
            order: true,
            mobile: false,
            sortTitle: 'createdDate'
        }
    ],
    repeaterHeader: [
        {
            id: 0,
            title: 'comp.table.header.name',
            width: '200px',
            active: true,
            sortable: true,
            order: true,
            sortTitle: 'name',
            mobile: true,
            style: { textAlign: 'left' }
        },
        {
            id: 1,
            title: 'form.meshStrength',
            active: false,
            sortable: true,
            order: true,
            mobile: true,
            width: 120,
            sortTitle: 'linkQuality',
            style: { textAlign: 'left' }
        },
        {
            id: 2,
            title: 'comp.table.header.battery',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 100,
            sortTitle: 'voltageBat',
            style: { textAlign: 'left' }
        },
        {
            id: 3,
            title: 'comp.table.header.wired',
            active: false,
            sortable: true,
            order: true,
            mobile: false,
            width: 100,
            sortTitle: 'voltageWired',
            style: { textAlign: 'left' }
        }
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.SET_UNITS_LIST: {
            return {
                ...state,
                unitsList: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_LIST: {
            return {
                ...state,
                locksList: action.payload
            };
        }
        case CONSTANTS.SET_UNITS_LIST_STATS: {
            return {
                ...state,
                unitsListStats: action.payload
            };
        }
        case CONSTANTS.SET_UNITS_LIST_LOCK_STATS: {
            return {
                ...state,
                unitsListLockStats: action.payload
            };
        }
        case CONSTANTS.SET_UNITS_LIST_LOCK_STATUS_STATS: {
            return {
                ...state,
                unitsListLockStatusStats: action.payload
            };
        }
        case CONSTANTS.SET_UNIT: {
            return {
                ...state,
                unitObject: action.payload
            };
        }
        case CONSTANTS.SET_LAST_SYNC_INFO: {
            return {
                ...state,
                lastSyncInfo: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_LOCK_DETAILS: {
            return {
                ...state,
                lockObject: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_HEADERS: {
            return {
                ...state,
                unitsHeader: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_HEADERS_WEBHOOKS: {
            return {
                ...state,
                unitHeaderWebhooks: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_HEADERS: {
            return {
                ...state,
                locksHeader: action.payload
            };
        }
        case CONSTANTS.SET_ASSIGN_UNIT_HEADERS: {
            return {
                ...state,
                assignUnitHeader: action.payload
            };
        }
        case CONSTANTS.SET_ASSIGN_USER_HEADERS: {
            return {
                ...state,
                assignUserHeader: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_FILTERS: {
            return {
                ...state,
                unitsFilters: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_ACTIVE: {
            return {
                ...state,
                activeUnit: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_ACTIVE: {
            return {
                ...state,
                activeLock: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_EXTERNAL_ID: {
            return {
                ...state,
                unitExternalId: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_ZONE_SELECTED: {
            return {
                ...state,
                zoneSelected: action.payload
            };
        }
        case CONSTANTS.SET_OPEN_UNITS: {
            return {
                ...state,
                openUnits: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_HOLDS: {
            return {
                ...state,
                lockHolds: action.payload
            };
        }
        case CONSTANTS.SET_UNLOCK_STATUS: {
            return {
                ...state,
                unlockStatus: action.payload
            };
        }
        case CONSTANTS.SET_LOW_BATTERY_UNITS: {
            return {
                ...state,
                lowBatteryUnits: action.payload
            };
        }
        case CONSTANTS.SET_LOW_BATTERY_UNITS_REPLACED: {
            return {
                ...state,
                lowBatteryUnitsReplaced: action.payload
            };
        }
        case CONSTANTS.SET_NEW_BATTERY_ENDPOINT_NOT_EXIST: {
            return {
                ...state,
                newBatteryEndpointNotExist: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_FILTER_UNIT: {
            return {
                ...state,
                lockFilterUnit: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_FILTER: {
            return {
                ...state,
                unitsFilter: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_TYPE_FILTER: {
            return {
                ...state,
                lockTypeFilter: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_STATUS_FILTER: {
            return {
                ...state,
                lockStatusFilter: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_SETTINGS: {
            return {
                ...state,
                lockSettings: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_MOTION_SETTINGS: {
            return {
                ...state,
                lockMotionSettings: action.payload
            }
        }
        case CONSTANTS.SET_SITE_MOTION_SETTINGS: {
            return {
                ...state,
                siteMotionSettings: action.payload
            }
        }
        case CONSTANTS.SET_CURRENT_LOCK_SCHEDULES: {
            return {
                ...state,
                currentLockSchedules: action.payload
            };
        }
        case CONSTANTS.SET_CURRENT_SITE_LOCK_SCHEDULES: {
            return {
                ...state,
                currentSiteLockSchedules: action.payload
            };
        }
        case CONSTANTS.SET_SYNC_UNITS: {
            return {
                ...state,
                syncUnits: action.payload
            };
        }
        case CONSTANTS.SET_PMS_SYNC_UNITS: {
            return {
                ...state,
                syncPmsUnits: action.payload
            };
        }
        case CONSTANTS.SET_PMS_SYNC_CHANGES: {
            return {
                ...state,
                syncPmsChanges: action.payload
            };
        }
        case CONSTANTS.SET_SHARED_UNITS: {
            return {
                ...state,
                sharedUnits: action.payload
            };
        }
        case CONSTANTS.SET_SITE_SHARES: {
            return {
                ...state,
                siteShares: action.payload
            };
        }
        case CONSTANTS.SET_SITE_FOBS: {
            return {
                ...state,
                siteFobs: action.payload
            };
        }
        case CONSTANTS.SET_ALL_UNIT_NOTES: {
            return {
                ...state,
                allUnitNotes: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_NOTES: {
            return {
                ...state,
                unitNotes: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_NOTE: {
            return {
                ...state,
                unitNote: action.payload
            };
        }
        case CONSTANTS.SET_LOCK_MANUFACTURING_DATA: {
            return {
                ...state,
                lockManufacturingData: action.payload
            };
        }
        case CONSTANTS.SET_TEMPERATURE_UNITS: {
            return {
                ...state,
                temperatureUnits: action.payload
            };
        }
        case CONSTANTS.SET_UNIT_TASK_COMMENTS: {
            return {
                ...state,
                unitTaskComments: action.payload
            };
        }
        case CONSTANTS.SET_SYNC_UNITS_HEADERS: {
            return {
                ...state,
                syncUnitsHeaders: action.payload
            };
        }
        case CONSTANTS.SET_SHARED_UNITS_HEADERS: {
            return {
                ...state,
                sharedUnitsHeaders: action.payload
            };
        }
        case CONSTANTS.SET_UNITS_TASKS_HEADERS: {
            return {
                ...state,
                notesHeaders: action.payload
            };
        }
        case CONSTANTS.SET_UNITS_TASKS_COMMENTS_HEADERS: {
            return {
                ...state,
                commentsHeaders: action.payload
            };
        }
        case CONSTANTS.SET_REPEATER_LIST: {
            return {
                ...state,
                repeaterList: action.payload
            };
        }
        case CONSTANTS.SET_REPEATER_HEADER: {
            return {
                ...state,
                repeaterHeader: action.payload
            };
        }
        default:
            return state;
    }
};
