import { all } from 'redux-saga/effects';
import appSagas from './App';
import authSagas from './Auth';
import unitsSagas from './Units';
import usersSagas from './Users';
import settingsSagas from './Settings';
import reportsSagas from './Reports';
import supportSagas from './Support';
import entrySagas from './Entry';
import gatewaysSagas from './Gateways';
import gatewayKeeperSagas from './GatewayKeeper';
import zonesSagas from './Zones';
import activitySagas from './Activity';
import activityForwardingSagas from './ActivityForwarding';
import rolesSagas from './Roles';
import companySagas from './Company';
import notificationsSagas from './Notifications';
import securitySagas from './Security';
import accessCodesSagas from './AccessCodes';
import mapsSagas from './Maps';
import releasesSagas from './Releases';
import camerasSagas from './Cameras';
import helpWizardSagas from './HelpWizard';
import wifiSagas from './Wifi';

export default function* rootSaga(getState) {
    yield all([
        appSagas(),
        authSagas(),
        unitsSagas(),
        usersSagas(),
        settingsSagas(),
        reportsSagas(),
        supportSagas(),
        entrySagas(),
        gatewaysSagas(),
        zonesSagas(),
        rolesSagas(),
        activitySagas(),
        activityForwardingSagas(),
        companySagas(),
        notificationsSagas(),
        securitySagas(),
        accessCodesSagas(),
        gatewayKeeperSagas(),
        mapsSagas(),
        releasesSagas(),
        camerasSagas(),
        helpWizardSagas(),
        wifiSagas()
    ]);
}
