import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';

export const fetchUnitList = (
    filter = ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry'],
    fetchStats,
    handleRefresh,
    paginate = false,
    includeService = false,
    repeaters = false,
    security = false,
    only1As = false,
    sortBasedOnBatteryDate = false,

) => {
    return {
        type: CONSTANTS.FETCH_UNITS_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_LIST,
            data: { filter },
            fetchStats,
            handleRefresh,
            paginate,
            includeService,
            repeaters,
            security,
            only1As,
            sortBasedOnBatteryDate,

        }
    };
};

export const fetchUnitsStats = () => {
    return {
        type: CONSTANTS.FETCH_UNITS_STATS,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNITS_STATS,
            data: {}
        }
    };
};

export const fetchUnitListByZone = (zoneUUID = '') => {
    return {
        type: CONSTANTS.FETCH_UNITS_LIST_BY_ZONE,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_LIST_BY_ZONE,
            data: { zoneUUID }
        }
    };
};

export const fetchUnitDetails = (unitUUID = '') => {
    return {
        type: CONSTANTS.FETCH_UNIT_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_DETAILS,
            data: { unitUUID }
        }
    };
};

export const fetchLastSyncInfo = (unitUUID = '') => {
    return {
        type: CONSTANTS.FETCH_LAST_SYNC_INFO,
        payload: {
            url: ENDPOINTS.ENDPOINT_LAST_SYNC_INFO,
            data: { unitUUID }
        }
    };
};

export const editUnitExternalId = (units = []) => {
    return {
        type: CONSTANTS.EDIT_UNIT_EXTERNAL_ID,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_EDIT_EXTERNAL_ID,
            data: {
                units
            }
        }
    };
};

export const fetchUnitLockDetails = (
    lockUUID = '',
    filter = ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry']
) => {
    return {
        type: CONSTANTS.FETCH_UNIT_LOCK_DETAILS,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_LIST,
            data: { filter },
            lockUUID
        }
    };
};

export const editUnit = (data) => {
    return {
        type: CONSTANTS.EDIT_UNIT,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_EDIT,
            data
        }
    };
};

export const editLock = (data) => {
    return {
        type: CONSTANTS.EDIT_LOCK,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_LOCK_EDIT,
            data
        }
    };
};

export const editLockSilently = (data) => {
    return {
        type: CONSTANTS.EDIT_LOCK_SILENTLY,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_LOCK_EDIT,
            data
        }
    };
};

export const editLockSettings = ({
    uuid = '',
    fireTime = 0,
    lockLights = false,
    radioStrength = 0,
    ignoreAccessCodesLeadingZeros = false,
    ignoreSchedules = false,
    freeExit = false,
    timeNotSetFailSecure = false,
    bleTxPower = 1,
    logMotionEvents = false,
    allowAllAccessCodes = false,
    ignoreLockStatus = false,
    chargeCapOnWake = false,
    wakeOnMotion = false,
    entryMotionMode = false,
    capFireVoltage = 0,
    forcedEntryEnabled = false
}) => {
    return {
        type: CONSTANTS.EDIT_LOCK_SETTINGS,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_LOCK_SETTINGS_EDIT,
            data: {
                uuid,
                fireTime: Number(fireTime),
                lockLights: lockLights,
                radioStrength: Number(radioStrength),
                ignoreAccessCodesLeadingZeros: ignoreAccessCodesLeadingZeros,
                ignoreSchedules: ignoreSchedules,
                freeExit: freeExit,
                timeNotSetFailSecure: timeNotSetFailSecure,
                bleTxPower: Number(bleTxPower),
                logMotionEvents: logMotionEvents,
                allowAllAccessCodes: allowAllAccessCodes,
                ignoreLockStatus: ignoreLockStatus,
                chargeCapOnWake: chargeCapOnWake,
                wakeOnMotion: wakeOnMotion,
                entryMotionMode: entryMotionMode,
                capFireVoltage: Number(capFireVoltage),
                forcedEntryEnabled: forcedEntryEnabled
            }
        }
    };
};

export const editLockMotionSettings = ({
    uuid = '',
    motionEnabled = false,
    longMotionDurationDesired = 0,
    shortMotionDurationDesired = 0,
    shortMotionCountsDesired = 0,
    windowTimeDesired = 120,
    timeBeforeStopDesired = 180,
    repeatedMotionMinDesired = 5,
    overrideSiteSetting = false

}) => { 
    return {
        type: CONSTANTS.EDIT_LOCK_MOTION_SETTINGS,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_LOCK_MOTION_SETTINGS_EDIT,
            data: {
                uuid,
                motionEnabled: motionEnabled,
                longMotionDuration: Number(longMotionDurationDesired),
                shortMotionDuration: Number(shortMotionDurationDesired),
                shortMotionCounts: Number(shortMotionCountsDesired),
                windowTime: 120,
                timeBeforeStop: 180,
                repeatedMotionMin: 5,
                overrideSiteSetting: overrideSiteSetting
            }
        }
    };

};
export const editSiteMotionSettings = ({
    siteUUID = '',
    motionEnabled = false,
    longMotionDurationDesired = 0,
    shortMotionDurationDesired = 0,
    shortMotionCountsDesired = 0,
    windowTime = 120,
    timeBeforeStop = 180,
    repeatedMotionMin = 5
}) => {
    return {
        type: CONSTANTS.EDIT_SITE_MOTION_SETTINGS,
        payload: {
            url: ENDPOINTS.ENDPOINT_SITE_MOTION_SETTINGS_EDIT,
            data: {
                siteUUID,
                motionEnabled: motionEnabled,
                longMotionDuration: Number(longMotionDurationDesired),
                shortMotionDuration: Number(shortMotionDurationDesired),
                shortMotionCounts: Number(shortMotionCountsDesired),
                windowTime: 120,
                timeBeforeStop: 180,
                repeatedMotionMin: 5
            }
        }
    }
}

export const editLockMac = (data) => {
    return {
        type: CONSTANTS.EDIT_LOCK_MAC,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_LOCK_EDIT_MAC,
            data
        }
    };
};

export const rekeyUnit = (unitUUID) => {
    return {
        type: CONSTANTS.REKEY_UNIT,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_REKEY,
            data: {
                unitUUID
            }
        }
    };
};

export const checkoutUnit = (lockUUID = '') => {
    return {
        type: CONSTANTS.CHECKOUT_UNIT,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_CHECKOUT,
            data: { lockUUID }
        }
    };
};

export const assignUnit = ({
    firstName = '',
    lastName = '',
    email = '',
    countryCode = '',
    phone = '',
    unitUUID = '',
    accessCode = '',
    roleUUID = ''
}) => {
    return {
        type: CONSTANTS.ASSIGN_UNIT,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_ASSIGN,
            required: {
                firstName,
                lastName,
                unitUUID
            },
            data:
                roleUUID !== ''
                    ? {
                        firstName,
                        lastName,
                        email,
                        phone,
                        countryCode,
                        accessCode,
                        unitUUID,
                        companyUUID: JSON.parse(localStorage.getItem('nse_login_data')).companyUUID,
                        roleUUID
                    }
                    : {
                        firstName,
                        lastName,
                        email,
                        phone,
                        countryCode,
                        accessCode,
                        unitUUID,
                        companyUUID: JSON.parse(localStorage.getItem('nse_login_data')).companyUUID
                    }
        }
    };
};

export const unassignUnit = (unitUUID = '') => {
    return {
        type: CONSTANTS.UNASSIGN_UNIT,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_UNASSIGN,
            data: { unitUUID }
        }
    };
};

export const auctionUnit = (data) => {
    return {
        type: CONSTANTS.AUCTION_UNIT,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_AUCTION,
            data
        }
    };
};

export const reassignAuction = (data) => {
    return {
        type: CONSTANTS.REASSIGN_AUCTION,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_REASSIGN_AUCTION,
            data
        }
    };
};

export const unauctionUnit = (data) => {
    return {
        type: CONSTANTS.UNAUCTION_UNIT,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_UNAUCTION,
            data
        }
    };
};

export const transferUnit = (data) => {
    return {
        type: CONSTANTS.TRANSFER_UNIT,
        payload: {
            url: '/site/unit/transfer/assign/',
            data
        }
    };
};

export const untransferUnit = ({ userUUID = '', unitUUID = '' }) => {
    return {
        type: CONSTANTS.UNTRANSFER_UNIT,
        payload: {
            url: '/site/unit/transfer/unassign/',
            data: {
                userUUID
            },
            unitUUID
        }
    };
};

export const setUnitState = (unitUUID = '', newState = '', push = true) => {
    return {
        type: CONSTANTS.SET_UNIT_STATE,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_SET_STATE,
            push,
            data: {
                unitUUID,
                newState
            }
        }
    };
};

export const setUnitList = (data) => {
    return {
        type: CONSTANTS.SET_UNITS_LIST,
        payload: data
    };
};

export const setLockList = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_LIST,
        payload: data
    };
};

export const setUnitListStats = (data) => {
    return {
        type: CONSTANTS.SET_UNITS_LIST_STATS,
        payload: data
    };
};

export const setUnitListLockStats = (data) => {
    return {
        type: CONSTANTS.SET_UNITS_LIST_LOCK_STATS,
        payload: data
    };
};

export const setUnitListLockStatusStats = (data) => {
    return {
        type: CONSTANTS.SET_UNITS_LIST_LOCK_STATUS_STATS,
        payload: data
    };
};

export const setUnit = (data) => {
    return {
        type: CONSTANTS.SET_UNIT,
        payload: data
    };
};

export const setLastSyncInfo = (data) => {
    return {
        type: CONSTANTS.SET_LAST_SYNC_INFO,
        payload: data
    };
};

export const setUnitLockDetails = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_LOCK_DETAILS,
        payload: data
    };
};

export const setUnitHeaders = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_HEADERS,
        payload: data
    };
};

export const setUnitHeadersWebhooks = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_HEADERS_WEBHOOKS,
        payload: data
    };
};

export const setLockHeaders = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_HEADERS,
        payload: data
    };
};

export const setAssignUnitHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ASSIGN_UNIT_HEADERS,
        payload: data
    };
};

export const setAssignUserHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ASSIGN_USER_HEADERS,
        payload: data
    };
};

export const setUnitFilters = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_FILTERS,
        payload: data
    };
};

export const setActiveUnit = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_ACTIVE,
        payload: data
    };
};

export const setActiveLock = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_ACTIVE,
        payload: data
    };
};

export const setUnitExternalId = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_EXTERNAL_ID,
        payload: data
    };
};

export const setUnitZoneSelected = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_ZONE_SELECTED,
        payload: data
    };
};

export const remoteUnlock = ({ mac = '', overridePin = '', overrideReason = '', bleHwVersion = '', unitUUID }) => {
    return {
        type: CONSTANTS.REMOTE_UNLOCK,
        payload: {
            url: ENDPOINTS.ENDPOINT_REMOTE_UNLOCK,
            bleHwVersion, // not in data obj because this is not being sent to the BE
            unitUUID,
            data: overridePin && overridePin !== "" && overrideReason && overrideReason !== ""
                ?
                {
                    mac,
                    overridePin,
                    overrideReason
                }
                :
                overridePin && overridePin !== "" && overrideReason === ''
                    ?
                    {
                        mac,
                        overridePin
                    }
                    :
                    overrideReason && overrideReason !== ""
                        ?
                        {
                            mac,
                            overrideReason
                        }
                        :
                        {
                            mac
                        }
        }
    };
};

export const unlockRequestPin = (userUUID = '') => {
    return {
        type: CONSTANTS.UNLOCK_REQUEST_PIN,
        payload: {
            url: '/unlock/requestpin/',
            data: {
                userUUID
            }
        }
    };
};

export const createRepeatingLockSchedule = ({
    name = '',
    description = '',
    lockID,
    lockUUID,
    startTime,
    endTime,
    useEndDate,
    endDate,
    interval
}) => {
    return {
        type: CONSTANTS.CREATE_REPEATING_LOCK_SCHEDULE,
        payload: {
            url: ENDPOINTS.ENDPOINT_CREATE_REPEATING_LOCK_SCHEDULE,
            data: {
                name,
                description,
                lockID,
                lockUUID,
                startTime,
                endTime,
                useEndDate,
                endDate,
                interval
            }
        }
    };
};

export const updateRepeatingLockSchedule = ({
    uuid = '',
    name = '',
    description = '',
    lockID,
    lockUUID,
    startTime,
    endTime,
    useEndDate,
    endDate,
    interval
}) => {
    return {
        type: CONSTANTS.UPDATE_REPEATING_LOCK_SCHEDULE,
        payload: {
            url: ENDPOINTS.ENDPOINT_UPDATE_REPEATING_LOCK_SCHEDULE,
            data: {
                uuid,
                name,
                description,
                lockID,
                lockUUID,
                startTime,
                endTime,
                useEndDate,
                endDate,
                interval
            }
        }
    };
};

export const deleteRepeatingLockSchedule = (uuid = '') => {
    return {
        type: CONSTANTS.DELETE_REPEATING_LOCK_SCHEDULE,
        payload: {
            url: ENDPOINTS.ENDPOINT_DELETE_REPEATING_LOCK_SCHEDULE,
            data: {
                uuid
            }
        }
    };
};

export const holdOpenGateTime = ({ lockHold, forceHoldOpen = false }) => {
    return {
        type: CONSTANTS.HOLD_OPEN_GATE_TIME,
        payload: {
            url: ENDPOINTS.ENDPOINT_HOLD_OPEN_GATE_TIME,
            data: { lockHold, forceHoldOpen }
        }
    };
};

export const holdOpenGateSchedule = ({ lockHold, forceHoldOpen = false }) => {
    return {
        type: CONSTANTS.HOLD_OPEN_GATE_SCHEDULE,
        payload: {
            url: ENDPOINTS.ENDPOINT_HOLD_OPEN_GATE_SCHEDULE,
            data: { lockHold, forceHoldOpen }
        }
    };
};

export const fetchLockHolds = () => {
    return {
        type: CONSTANTS.FETCH_LOCK_HOLDS,
        payload: {
            url: ENDPOINTS.ENDPOINT_FETCH_LOCK_HOLDS
        }
    };
};

export const setLockHolds = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_HOLDS,
        payload: data
    };
};

export const removeLockHold = (lockHoldID) => {
    return {
        type: CONSTANTS.REMOVE_LOCK_HOLD,
        payload: {
            url: ENDPOINTS.ENDPOINT_REMOVE_LOCK_HOLD,
            data: {
                lockHoldID
            }
        }
    };
};

export const readLockSchedule = (lockID, lockUUID) => {
    return {
        type: CONSTANTS.READ_LOCK_SCHEDULE,
        payload: {
            url: ENDPOINTS.ENDPOINT_READ_LOCK_SCHEDULE,
            data: {
                lockID,
                lockUUID
            }
        }
    };
};

export const readSiteLockSchedules = () => {
    return {
        type: CONSTANTS.READ_SITE_LOCK_SCHEDULES,
        payload: {
            url: ENDPOINTS.ENDPOINT_READ_SITE_LOCK_SCHEDULES,
            data: {}
        }
    };
};

export const setCurrentLockSchedules = (data) => {
    return {
        type: CONSTANTS.SET_CURRENT_LOCK_SCHEDULES,
        payload: data
    };
};
export const setCurrentSiteLockSchedules = (data) => {
    return {
        type: CONSTANTS.SET_CURRENT_SITE_LOCK_SCHEDULES,
        payload: data
    };
};

export const setOpenUnits = (data) => {
    return {
        type: CONSTANTS.SET_OPEN_UNITS,
        payload: data
    };
};

export const setUnlockStatus = (data) => {
    return {
        type: CONSTANTS.SET_UNLOCK_STATUS,
        payload: data
    };
};

export const fetchLowBatteryUnits = () => {
    return {
        type: CONSTANTS.FETCH_LOW_BATTERY_UNITS,
        payload: {
            url: ENDPOINTS.ENDPOINT_FETCH_LOW_BATTERY_UNITS,
            data: {}
        }
    };
};

export const setLowBatteryUnits = (data) => {
    return {
        type: CONSTANTS.SET_LOW_BATTERY_UNITS,
        payload: data
    };
};

export const fetchBatteryIssues = () => {
    return {
        type: CONSTANTS.FETCH_BATTERY_ISSUES,
        payload: {
            url: ENDPOINTS.ENDPOINT_FETCH_BATTERY_ISSUES,
            data: {}
        }
    };
};

export const setLowBatteryUnitsReplaced = (data) => {
    return {
        type: CONSTANTS.SET_LOW_BATTERY_UNITS_REPLACED,
        payload: data
    };
};

export const setNewBatteryEndpointNotExist = (data) => {
    return {
        type: CONSTANTS.SET_NEW_BATTERY_ENDPOINT_NOT_EXIST,
        payload: data
    }
}

export const markLowBatteryUnitsReplaced = (ids = [], reportedManually) => {
    return {
        type: CONSTANTS.MARK_LOW_BATTERY_UNITS_REPLACED,
        payload: {
            url: ENDPOINTS.ENDPOINT_MARK_LOW_BATTERY_UNITS_REPLACED,
            data: {
                locks: ids,
                reportedManually: reportedManually
            }
        }
    };
};

export const markLowBatteryUnitsNotReplaced = (ids = []) => {
    return {
        type: CONSTANTS.MARK_LOW_BATTERY_UNITS_NOT_REPLACED,
        payload: {
            url: ENDPOINTS.ENDPOINT_MARK_LOW_BATTERY_UNITS_NOT_REPLACED,
            data: {
                locks: ids,
                noReplace: true
            }
        }
    };
};

export const setLockFilterUnit = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_FILTER_UNIT,
        payload: data
    };
};

export const createLock = ({ unitName = '', zoneUUID = '-1', mac = '', name = '', type = '', accessType = '' }) => {
    return {
        type: CONSTANTS.CREATE_LOCK,
        payload: {
            url: ENDPOINTS.ENDPOINT_CREATE_LOCK,
            data: {
                locks: [
                    {
                        unitName,
                        zoneUUID,
                        mac,
                        name,
                        type,
                        accessType
                    }
                ]
            }
        }
    };
};

export const deleteLock = ({
    lockUUID = '',
    unitName = '',
    zoneUUID = '-1',
    mac = '',
    name = '',
    type = '',
    accessType = ''
}) => {
    return {
        type: CONSTANTS.DELETE_LOCK,
        payload: {
            url: ENDPOINTS.ENDPOINT_DELETE_LOCK,
            data: {
                lockUUID
            },
            lock:
                mac !== ''
                    ? {
                        unitName,
                        zoneUUID,
                        mac,
                        name,
                        type,
                        accessType
                    }
                    : null
        }
    };
};

export const deleteUnit = (unitUUID) => {
    return {
        type: CONSTANTS.DELETE_UNIT,
        payload: {
            url: '/site/unit/delete/',
            data: {
                unitUUID
            }
        }
    };
};

export const rebootSiteUnits = (uuids = []) => {
    return {
        type: CONSTANTS.REBOOT_SITE_UNITS,
        payload: {
            url: '/lock/reboot/',
            data: {
                uuids
            }
        }
    };
};

export const setUnitFilter = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_FILTER,
        payload: data
    };
};

export const setLockTypeFilter = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_TYPE_FILTER,
        payload: data
    };
};

export const setLockStatusFilter = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_STATUS_FILTER,
        payload: data
    };
};

export const fetchLockSettings = (uuid = '') => {
    return {
        type: CONSTANTS.FETCH_LOCK_SETTINGS,
        payload: {
            url: '/lock/settings/get/',
            data: {
                uuid
            }
        }
    };
};

export const setLockSettings = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_SETTINGS,
        payload: data
    };
};

export const fetchLockMotionSettings = (uuid = '') => {
    return { 
        type:CONSTANTS.FETCH_LOCK_MOTION_SETTINGS,
        payload: {
            url: '/lock/settings/motionget/',
            data: {
                uuid
            }
        }
    };
};

export const setLockMotionSettings = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_MOTION_SETTINGS,
        payload: data
    };
};
export const fetchSiteMotionSettings = (siteUUID = '') => {
    return { 
        type:CONSTANTS.FETCH_SITE_MOTION_SETTINGS,
        payload: {
            url: '/site/settings/motionget/',
            data: {
                siteUUID
            }
        }
    };
};
export const setSiteMotionSettings = (data) => {
    return {
        type: CONSTANTS.SET_SITE_MOTION_SETTINGS,
        payload: data
    };
};

export const fetchSyncUnits = (data) => {
    return {
        type: CONSTANTS.FETCH_SYNC_UNITS,
        payload: {
            url: '/site/sync/units/read/',
            data
        }
    };
};

export const fetchSyncUnitsDash = (data) => {
    return {
        type: CONSTANTS.FETCH_SYNC_UNITS_DASH,
        payload: {
            url: '/site/sync/units/read/',
            data
        }
    };
};

export const fetchPmsSync = () => {
    return {
        type: CONSTANTS.FETCH_PMS_SYNC,
        payload: {
            url: '/hermes/read/',
            data: {}
        }
    };
};

export const setSyncUnits = (data) => {
    return {
        type: CONSTANTS.SET_SYNC_UNITS,
        payload: data
    };
};

export const setPmsSyncUnits = (data) => {
    return {
        type: CONSTANTS.SET_PMS_SYNC_UNITS,
        payload: data
    };
};

export const setPmsSyncChanges = (data) => {
    return {
        type: CONSTANTS.SET_PMS_SYNC_CHANGES,
        payload: data
    };
};

export const fetchSharedUnits = (data) => {
    return {
        type: CONSTANTS.FETCH_SHARED_UNITS,
        payload: {
            url: '/shared/units/',
            data
        }
    };
};

export const setSharedUnits = (data) => {
    return {
        type: CONSTANTS.SET_SHARED_UNITS,
        payload: data
    };
};

export const setupUnit = (unitUUID) => {
    return {
        type: CONSTANTS.SETUP_UNIT,
        payload: {
            url: ENDPOINTS.ENDPOINT_SETUP_UNIT,
            data: {
                unitUUID
            }
        }
    };
};

export const resendKeys = (lockId) => {
    return {
        type: CONSTANTS.RESEND_KEYS,
        payload: {
            url: ENDPOINTS.ENDPOINT_RESEND_KEYS,
            data: {
                lockId,
                lockUUID: lockId.toString()
            }
        }
    };
};

export const fetchSiteShares = (data) => {
    return {
        type: CONSTANTS.FETCH_SITE_SHARES,
        payload: {
            url: '/site/shares/',
            data
        }
    };
};

export const setSiteShares = (data) => {
    return {
        type: CONSTANTS.SET_SITE_SHARES,
        payload: data
    };
};

export const fetchSiteFobs = (data) => {
    return {
        type: CONSTANTS.FETCH_SITE_FOBS,
        payload: {
            url: '/site/fobs/read/',
            data
        }
    };
};

export const setSiteFobs = (data) => {
    return {
        type: CONSTANTS.SET_SITE_FOBS,
        payload: data
    };
};

export const managerShareRequest = (userUUID, unitUUID, reason) => {
    return {
        type: CONSTANTS.MANAGER_SHARE_REQUEST,
        payload: {
            url: '/user/share/manager/request/',
            data: {
                userUUID,
                unitUUID,
                reason
            }
        }
    };
};

export const fetchAllUnitNotes = () => {
    return {
        type: CONSTANTS.FETCH_ALL_UNIT_NOTES,
        payload: {
            url: '/unit/notes/read/all/',
            data: {}
        }
    };
};

export const setAllUnitNotes = (data) => {
    return {
        type: CONSTANTS.SET_ALL_UNIT_NOTES,
        payload: data
    };
};

export const fetchUnitNotes = (unitUUID) => {
    return {
        type: CONSTANTS.FETCH_UNIT_NOTES,
        payload: {
            url: '/unit/notes/read/',
            data: {
                unitUUID
            }
        }
    };
};

export const setUnitNotes = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_NOTES,
        payload: data
    };
};

export const fetchUnitNote = (unitUUID, noteUUID) => {
    return {
        type: CONSTANTS.FETCH_UNIT_NOTE,
        payload: {
            url: '/unit/notes/read/',
            data: {
                unitUUID
            },
            noteUUID
        }
    };
};

export const setUnitNote = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_NOTE,
        payload: data
    };
};

export const createUnitNote = ({ unitUUID = '', heading = '', description = '', dueDate = '', userUUID = '' }) => {
    return {
        type: CONSTANTS.CREATE_UNIT_NOTE,
        payload: {
            url: '/unit/notes/create/',
            data: {
                unitUUID,
                heading,
                description,
                dueDate,
                userUUID
            }
        }
    };
};

export const editUnitNote = ({
    noteUUID = '',
    unitUUID = '',
    heading = '',
    description = '',
    dueDate = '',
    userUUID = ''
}) => {
    return {
        type: CONSTANTS.EDIT_UNIT_NOTE,
        payload: {
            url: '/unit/notes/edit/',
            data: {
                noteUUID,
                unitUUID,
                heading,
                description,
                dueDate,
                userUUID
            }
        }
    };
};

export const deleteUnitNote = (noteUUID) => {
    return {
        type: CONSTANTS.DELETE_UNIT_NOTE,
        payload: {
            url: '/unit/notes/delete/',
            data: {
                noteUUID
            }
        }
    };
};

export const fetchLockManufacturingData = (mac, hwType) => {
    return {
        type: CONSTANTS.FETCH_LOCK_MANUFACTURING_DATA,
        payload: {
            url: '/lock/manufacturing/read/',
            data: {
                mac,
                hwType
            }
        }
    };
};

export const setLockManufacturingData = (data) => {
    return {
        type: CONSTANTS.SET_LOCK_MANUFACTURING_DATA,
        payload: data
    };
};

export const uploadUnitTaskImage = (data) => {
    return {
        type: CONSTANTS.UPLOAD_UNIT_TASK_IMAGE,
        payload: {
            url: '/unit/notes/image/upload/',
            data
        }
    };
};

export const deleteUnitTaskImage = ({ unitNoteUUID = '', unitUUID = '' }) => {
    return {
        type: CONSTANTS.DELETE_UNIT_TASK_IMAGE,
        payload: {
            url: '/unit/notes/image/delete/',
            data: {
                unitNoteUUID: unitNoteUUID
            },
            unitUUID
        }
    };
};

export const setTemperatureUnits = (data) => {
    return {
        type: CONSTANTS.SET_TEMPERATURE_UNITS,
        payload: data
    };
};

export const fetchUnitTaskComments = (noteUUID) => {
    return {
        type: CONSTANTS.FETCH_UNIT_TASK_COMMENTS,
        payload: {
            url: '/unit/notes/comment/read/',
            data: {
                noteUUID
            }
        }
    };
};

export const setUnitTaskComments = (data) => {
    return {
        type: CONSTANTS.SET_UNIT_TASK_COMMENTS,
        payload: data
    };
};

export const createUnitTaskComment = ({ noteUUID = '', comment = '' }) => {
    return {
        type: CONSTANTS.CREATE_UNIT_TASK_COMMENT,
        payload: {
            url: '/unit/notes/comment/create/',
            data: {
                noteUUID,
                comment
            }
        }
    };
};

export const editUnitTaskComment = ({ id = 0, noteUUID = '', comment = '' }) => {
    return {
        type: CONSTANTS.EDIT_UNIT_TASK_COMMENT,
        payload: {
            url: '/unit/notes/comment/edit/',
            data: {
                id,
                noteUUID,
                comment
            }
        }
    };
};

export const deleteUnitTaskComment = ({ id = 0, noteUUID = '' }) => {
    return {
        type: CONSTANTS.DELETE_UNIT_TASK_COMMENT,
        payload: {
            url: '/unit/notes/comment/delete/',
            data: {
                id,
                noteUUID
            }
        }
    };
};

export const setSyncUnitsHeaders = (data) => {
    return {
        type: CONSTANTS.SET_SYNC_UNITS_HEADERS,
        payload: data
    };
};

export const setSharedUnitsHeaders = (data) => {
    return {
        type: CONSTANTS.SET_SHARED_UNITS_HEADERS,
        payload: data
    };
};

export const setUnitsTasksHeaders = (data) => {
    return {
        type: CONSTANTS.SET_UNITS_TASKS_HEADERS,
        payload: data
    };
};

export const setUnitsTasksCommentsHeaders = (data) => {
    return {
        type: CONSTANTS.SET_UNITS_TASKS_COMMENTS_HEADERS,
        payload: data
    };
};

export const fetchRepeaterList = (
    filter = ['available', 'inuse', 'pending', 'auction-smartentry', 'transfer-smartentry'],
) => {
    return {
        type: CONSTANTS.FETCH_REPEATER_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_UNIT_LIST,
            data: { filter },
        }
    };
};

export const setRepeaterList = (data) => {
    return {
        type: CONSTANTS.SET_REPEATER_LIST,
        payload: data
    };
};

export const setRepeaterHeader = (data) => {
    return {
        type: CONSTANTS.SET_REPEATER_HEADER,
        payload: data
    };
};