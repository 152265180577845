import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from '../store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_AUTH from 'actions/Auth';
import * as THEMES from 'themes';
import * as DATABASE from 'utilities/database';
import moment from 'moment';

import { countries } from 'countries-list';
import languageData from 'lngProvider/data';

const themeState = (state) => state.app.theme;
const colorState = (state) => state.app.color;
const errorState = (state) => state.app.errorDetails;
const authUserState = (state) => state.auth.authUser;
const siteState = (state) => state.auth.site;
const getErrorHeaders = (state) => state.app.errorHeader;
const getFeedbackHeaders = (state) => state.app.feedbackHeaders;
const getPerformanceDetails = (state) => state.app.performanceDetails;
const getPerformanceFilters = (state) => state.app.performanceFilters;
const getBatteryPerformanceDetails = (state) => state.app.batteryPerformanceDetails;
const getPerformanceStats = (state) => state.app.performanceStats;
const getDeviceFirmware = (state) => state.app.deviceFirmware;

function* requestStartup({ payload }) {
    let supportUser =
        payload.user.roles && payload.user.roles.find((role) => role.tier === 1)
            ? true
            : payload.user.permissions.includes('support_sync_pms')
            ? true
            : false;
    let tenant =
        payload.user.roles && payload.user.roles.find((role) => role.tier === 0)
            ? true
            : false;
    let vendor =
        payload.user.roles && payload.user.roles.find((role) => role.tier === 1000)
            ? true
            : false;
    try {
        yield put(ACTIONS_APP.setLoader(true));
        yield put(ACTIONS_APP.fetchTheme(payload.user.companyUUID));
        if (
            UTILS.checkPMS(payload.site) &&
            !supportUser &&
            !tenant &&
            !vendor &&
            payload.site.pms &&
            payload.site.pms !== 'breeze' &&
            payload.site.pms !== 'gds' &&
            payload.site.pms !== 'webhooks'
        ) {
            yield put(ACTIONS_AUTH.syncSite({ site: payload.site, force: false }));
        } else {
            const theme = yield select(themeState);
            payload.site.map = [
                {
                    color: theme.color.themeColor,
                    lat: payload.site.latitude && payload.site.latitude !== '' ? JSON.parse(payload.site.latitude) : 0,
                    lng:
                        payload.site.longitude && payload.site.longitude !== '' ? JSON.parse(payload.site.longitude) : 0
                }
            ];
            yield put(ACTIONS_AUTH.setSite(payload.site));
            yield put(ACTIONS_APP.setLoader(false));
            // yield put(ACTIONS_APP.fetchSiteVersion()); // temporarily commenting this out
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestTheme({ payload }) {
    try {
        let theme = yield call(API.POST, payload.url, payload.data);
        if (!theme) {
            theme = THEMES.theme;
            yield put(ACTIONS_APP.setTheme(theme));
        } else {
            const color = yield select(colorState);
            let logo = 'assets/images/Themes/Demo/logo.png';
            let frontendVersion = sessionStorage.getItem('nse_version') ? sessionStorage.getItem('nse_version') : 'default';
            if (theme.data.MainLogoImage) {
                logo = theme.data.MainLogoImage != ' ' ? `${theme.data.MainLogoImage}?t=${new Date().getTime()}&v=${frontendVersion}` : logo;
            } else if (theme.data.mainLogoImage) {
                logo = theme.data.mainLogoImage != ' ' ? `${theme.data.mainLogoImage}?t=${new Date().getTime()}&v=${frontendVersion}` : logo;
            }
            let facilityImage = 'assets/images/Themes/Demo/facilityImage.png';
            if (theme.data.SiteImage) {
                facilityImage =
                    theme.data.SiteImage != ' ' ? `${theme.data.SiteImage}?t=${new Date().getTime()}&v=${frontendVersion}` : facilityImage;
            } else if (theme.data.siteImage) {
                facilityImage =
                    theme.data.siteImage != ' ' ? `${theme.data.siteImage}?t=${new Date().getTime()}&v=${frontendVersion}` : facilityImage;
            }
            theme.new = {
                Id: theme.data.UUID || theme.data.uuid,
                companyId: theme.data.CompanyUUID || theme.data.companyUUID,
                title: theme.data.AppName || theme.data.appName,
                logo,
                menuLogo: logo,
                facilityImage,
                supportLink: theme.data.SupportUrl || theme.data.supportUrl,
                country: theme.data.FavoriteCountry || theme.data.favoriteCountry,
                color: {
                    name: color.name,
                    pageColor: color.pageColor,
                    menuColor: color.menuColor,
                    headerColor: color.headerColor,
                    compColor: color.compColor,
                    themeColor: theme.data.MainColor || theme.data.mainColor || '#0070cd',
                    fontColor: color.fontColor,
                    subFontColor: color.subFontColor,
                    buttonFontColor: color.buttonFontColor,
                    borderColor: color.borderColor,
                    boxShadow: color.boxShadow,
                    boxShadowCorner: color.boxShadowCorner,
                    boxShadowCornerHover: color.boxShadowCornerHover,
                    boxShadowIntense: color.boxShadowIntense,
                    go: color.go
                }
            };
            yield put(ACTIONS_APP.setTheme(theme.new));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestIpLocation() {
    try {
        const ipLocation = null;
        if (!ipLocation) {
            yield put(ACTIONS_APP.setIpLocation(null));
        } else {
            yield put(ACTIONS_APP.setIpLocation(ipLocation));
            let country = Object.values(countries).find((country) => country.name === ipLocation.country);
            if (!localStorage.getItem('nse_local')) {
                if (languageData.filter((lang) => lang.locale === country.languages[0]).length > 0) {
                    yield put(ACTIONS_APP.setLanguage(country.languages[0]));
                }
            }
            var nse_login_data = JSON.parse(localStorage.getItem('nse_login_data'));
            if (nse_login_data && !nse_login_data.country) {
                yield put(ACTIONS_APP.setCountry(country));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchErrorDetails({ payload }) {
    try {
        let error = yield call(API.POST_ERROR, payload.url, payload.data);
        const currentError = yield select(errorState);
        if (!error || !error.data) {
        } else if (payload.data && payload.data.code && payload.data.code === 131) {
            yield history.push(`/signin/pin`);
            let errorObject = JSON.parse(JSON.stringify(error.data));
            let jsonObject = JSON.parse(payload.jsonSent ? payload.jsonSent : '');
            jsonObject.password ? (jsonObject.password = 'hidden') : null;
            errorObject.code = errorObject.code === 0 ? (errorObject.code = payload.data.code) : errorObject.code;
            errorObject.subcode =
                errorObject.subcode === 0 ? (errorObject.subcode = payload.data.subcode) : errorObject.subcode;
            errorObject.type = errorObject.type === '' ? (errorObject.type = payload.data.type) : errorObject.type;
            errorObject.description =
                errorObject.description === ''
                    ? (errorObject.description = 'Something went wrong, please try again later')
                    : errorObject.description;
            errorObject.troubleshoot =
                errorObject.troubleshoot === ''
                    ? (errorObject.troubleshoot =
                          'Contact Smart Entry support for more details​ or submit an error report below')
                    : errorObject.troubleshoot;
            errorObject.endpoint = JSON.stringify(payload.endpoint ? payload.endpoint : '');
            errorObject.jsonSent = JSON.stringify(jsonObject ? jsonObject : '');
            errorObject.jsonReceived = JSON.stringify(payload.jsonReceived ? payload.jsonReceived : '');
            errorObject.errorID = payload.errorID ? payload.errorID : '';
            yield put(ACTIONS_APP.setErrorDetails(errorObject));
        } else if (payload.data && payload.data.code && payload.data.code === 8) {
            let loginAttempts =
                sessionStorage.getItem('login_attempts') && JSON.parse(sessionStorage.getItem('login_attempts'));
            if (!loginAttempts) {
                sessionStorage.setItem('login_attempts', JSON.stringify([moment().utc().format()]));
                let errorObject = JSON.parse(JSON.stringify(error.data));
                let jsonObject = JSON.parse(payload.jsonSent ? payload.jsonSent : '');
                jsonObject.password ? (jsonObject.password = 'hidden') : null;
                errorObject.code = errorObject.code === 0 ? (errorObject.code = payload.data.code) : errorObject.code;
                errorObject.subcode =
                    errorObject.subcode === 0 ? (errorObject.subcode = payload.data.subcode) : errorObject.subcode;
                errorObject.type = errorObject.type === '' ? (errorObject.type = payload.data.type) : errorObject.type;
                errorObject.description =
                    errorObject.description === ''
                        ? (errorObject.description = 'Something went wrong, please try again later')
                        : errorObject.description;
                errorObject.troubleshoot =
                    errorObject.troubleshoot === ''
                        ? (errorObject.troubleshoot =
                              'Contact Smart Entry support for more details​ or submit an error report below')
                        : errorObject.troubleshoot;
                errorObject.endpoint = JSON.stringify(payload.endpoint ? payload.endpoint : '');
                errorObject.jsonSent = JSON.stringify(jsonObject ? jsonObject : '');
                errorObject.jsonReceived = JSON.stringify(payload.jsonReceived ? payload.jsonReceived : '');
                errorObject.errorID = payload.errorID ? payload.errorID : '';
                yield put(ACTIONS_APP.setErrorDetails(errorObject));
            } else if (loginAttempts && loginAttempts.length < 3) {
                loginAttempts.push(moment().utc().format());
                sessionStorage.setItem('login_attempts', JSON.stringify(loginAttempts));
                yield put(ACTIONS_APP.setErrorDetails(null));
                yield put(ACTIONS_AUTH.hideAuthLoader());
                let errorObject = JSON.parse(JSON.stringify(error.data));
                let jsonObject = JSON.parse(payload.jsonSent ? payload.jsonSent : '');
                jsonObject.password ? (jsonObject.password = 'hidden') : null;
                errorObject.code = errorObject.code === 0 ? (errorObject.code = payload.data.code) : errorObject.code;
                errorObject.subcode =
                    errorObject.subcode === 0 ? (errorObject.subcode = payload.data.subcode) : errorObject.subcode;
                errorObject.type = errorObject.type === '' ? (errorObject.type = payload.data.type) : errorObject.type;
                errorObject.description =
                    errorObject.description === ''
                        ? (errorObject.description = 'Something went wrong, please try again later')
                        : errorObject.description;
                errorObject.troubleshoot =
                    errorObject.troubleshoot === ''
                        ? (errorObject.troubleshoot =
                              'Contact Smart Entry support for more details​ or submit an error report below')
                        : errorObject.troubleshoot;
                errorObject.endpoint = JSON.stringify(payload.endpoint ? payload.endpoint : '');
                errorObject.jsonSent = JSON.stringify(jsonObject ? jsonObject : '');
                errorObject.jsonReceived = JSON.stringify(payload.jsonReceived ? payload.jsonReceived : '');
                errorObject.errorID = payload.errorID ? payload.errorID : '';
                yield put(ACTIONS_APP.setErrorDetails(errorObject));
            } else if (loginAttempts && loginAttempts.length >= 3) {
                if (
                    loginAttempts[loginAttempts.length - 2] &&
                    moment().diff(loginAttempts[loginAttempts.length - 2], 'minutes', true) >= 10080
                ) {
                    loginAttempts.push(moment().utc().format());
                    sessionStorage.setItem('login_attempts', JSON.stringify(loginAttempts));
                    yield put(ACTIONS_APP.setErrorDetails(null));
                    yield put(ACTIONS_AUTH.hideAuthLoader());
                    let errorObject = JSON.parse(JSON.stringify(error.data));
                    let jsonObject = JSON.parse(payload.jsonSent ? payload.jsonSent : '');
                    jsonObject.password ? (jsonObject.password = 'hidden') : null;
                    errorObject.code =
                        errorObject.code === 0 ? (errorObject.code = payload.data.code) : errorObject.code;
                    errorObject.subcode =
                        errorObject.subcode === 0 ? (errorObject.subcode = payload.data.subcode) : errorObject.subcode;
                    errorObject.type =
                        errorObject.type === '' ? (errorObject.type = payload.data.type) : errorObject.type;
                    errorObject.description =
                        errorObject.description === ''
                            ? (errorObject.description = 'Something went wrong, please try again later')
                            : errorObject.description;
                    errorObject.troubleshoot =
                        errorObject.troubleshoot === ''
                            ? (errorObject.troubleshoot =
                                  'Contact Smart Entry support for more details​ or submit an error report below')
                            : errorObject.troubleshoot;
                    errorObject.endpoint = JSON.stringify(payload.endpoint ? payload.endpoint : '');
                    errorObject.jsonSent = JSON.stringify(jsonObject ? jsonObject : '');
                    errorObject.jsonReceived = JSON.stringify(payload.jsonReceived ? payload.jsonReceived : '');
                    errorObject.errorID = payload.errorID ? payload.errorID : '';
                    yield put(ACTIONS_APP.setErrorDetails(errorObject));
                } else if (
                    loginAttempts[loginAttempts.length - 2] &&
                    moment().diff(loginAttempts[loginAttempts.length - 2], 'minutes', true) <= 10080
                ) {
                    yield put(ACTIONS_AUTH.setLoginAttemptModal(true));
                    yield put(ACTIONS_APP.setErrorDetails(null));
                    yield put(ACTIONS_APP.setLoader(false));
                    yield put(ACTIONS_AUTH.hideAuthLoader());
                } else {
                    let errorObject = JSON.parse(JSON.stringify(error.data));
                    let jsonObject = JSON.parse(payload.jsonSent ? payload.jsonSent : '');
                    jsonObject.password ? (jsonObject.password = 'hidden') : null;
                    errorObject.code =
                        errorObject.code === 0 ? (errorObject.code = payload.data.code) : errorObject.code;
                    errorObject.subcode =
                        errorObject.subcode === 0 ? (errorObject.subcode = payload.data.subcode) : errorObject.subcode;
                    errorObject.type =
                        errorObject.type === '' ? (errorObject.type = payload.data.type) : errorObject.type;
                    errorObject.description =
                        errorObject.description === ''
                            ? (errorObject.description = 'Something went wrong, please try again later')
                            : errorObject.description;
                    errorObject.troubleshoot =
                        errorObject.troubleshoot === ''
                            ? (errorObject.troubleshoot =
                                  'Contact Smart Entry support for more details​ or submit an error report below')
                            : errorObject.troubleshoot;
                    errorObject.endpoint = JSON.stringify(payload.endpoint ? payload.endpoint : '');
                    errorObject.jsonSent = JSON.stringify(jsonObject ? jsonObject : '');
                    errorObject.jsonReceived = JSON.stringify(payload.jsonReceived ? payload.jsonReceived : '');
                    errorObject.errorID = payload.errorID ? payload.errorID : '';
                    yield put(ACTIONS_APP.setErrorDetails(errorObject));
                }
            }
        } else if (!currentError) {
            let errorObject = JSON.parse(JSON.stringify(error.data));
            let jsonObject = JSON.parse(payload.jsonSent ? payload.jsonSent : '');
            jsonObject.password ? (jsonObject.password = 'hidden') : null;
            errorObject.code = errorObject.code === 0 ? (errorObject.code = payload.data.code) : errorObject.code;
            errorObject.subcode =
                errorObject.subcode === 0 ? (errorObject.subcode = payload.data.subcode) : errorObject.subcode;
            errorObject.type = errorObject.type === '' ? (errorObject.type = payload.data.type) : errorObject.type;
            errorObject.description =
                errorObject.description === ''
                    ? (errorObject.description = 'Something went wrong, please try again later')
                    : errorObject.description;
            errorObject.troubleshoot =
                errorObject.troubleshoot === ''
                    ? (errorObject.troubleshoot =
                          'Contact Smart Entry support for more details​ or submit an error report below')
                    : errorObject.troubleshoot;
            errorObject.endpoint = JSON.stringify(payload.endpoint ? payload.endpoint : '');
            errorObject.jsonSent = JSON.stringify(jsonObject ? jsonObject : '');
            errorObject.jsonReceived = JSON.stringify(payload.jsonReceived ? payload.jsonReceived : '');
            errorObject.errorID = payload.errorID ? payload.errorID : '';
            yield put(ACTIONS_APP.setErrorDetails(errorObject));
        } else {
            console.warn(error);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchErrorDetailsDisplay({ payload }) {
    try {
        let error = yield call(API.POST, payload.url, payload.data);
        if (!error || !error.data) {
        } else {
            yield put(ACTIONS_APP.setErrorDetailsDisplay(error.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSubmitErrorDetails({ payload }) {
    try {
        let errorSubmit = yield call(API.POST_ERROR, payload.url, payload.data);
        if (!errorSubmit) {
            yield put(ACTIONS_APP.setErrorDetails(null));
            if (!payload.autoSubmit) {
                yield history.goBack();
                yield put(ACTIONS_APP.showMessage(`notifications.success.errorSubmission`, 'success'));
            }
        } else {
            yield put(ACTIONS_APP.setErrorDetails(null));
            if (!payload.autoSubmit) {
                yield history.goBack();
                yield put(ACTIONS_APP.showMessage(`notifications.success.errorSubmission`, 'success'));
            }
        }
    } catch (errorSubmit) {
        console.warn(errorSubmit);
    }
}

function* requestFetchAllErrors({ payload }) {
    try {
        let errors = yield call(API.POST, payload.url, payload.data);
        if (!errors || !errors.data) {
        } else {
            let errorHeaders = yield select(getErrorHeaders);
            let activeHeader = errorHeaders.find((item) => item.active);
            errors.data.errors = UTILS.sortList(!activeHeader.order, errors.data.errors, activeHeader.sortTitle);
            yield put(ACTIONS_APP.setAllErrors(errors.data.errors));
        }
    } catch (errors) {
        console.warn(errors);
    }
}

function* requestUpdateError({ payload }) {
    try {
        let errors = yield call(API.POST, payload.url, payload.data);
        if (!errors || !errors.data) {
        } else {
            yield history.push(
                `/app/help/troubleshoot/display/${payload.data.code}/${payload.data.subcode}/${payload.data.type}`
            );
        }
    } catch (errors) {
        console.warn(errors);
    }
}

function* requestDeleteError({ payload }) {
    try {
        let errors = yield call(API.POST, payload.url, payload.data);
        if (!errors) {
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            yield history.push(`/app/help/troubleshoot/all`);
            yield put(ACTIONS_APP.setLoader(false));
        }
    } catch (errors) {
        console.warn(errors);
    }
}

function* requestFetchWeather({ payload }) {
    try {
        yield put(ACTIONS_APP.setWeather(null));
        const weather = yield call(API.WEATHER, payload.data.lat, payload.data.lon);
        if (!weather) {
            yield put(ACTIONS_APP.setWeather(null));
        } else {
            yield put(ACTIONS_APP.setWeather(weather));
            sessionStorage.setItem('weather', JSON.stringify(weather));
        }
    } catch (error) {
        yield put(ACTIONS_APP.setWeather(null));
        console.warn(error);
    }
}

function* requestFetchWeatherWeek({ payload }) {
    try {
        yield put(ACTIONS_APP.setWeatherWeek(null));
        const weather = yield call(API.WEATHER_WEEK, payload.data.lat, payload.data.lon);
        if (!weather) {
            yield put(ACTIONS_APP.setWeatherWeek(null));
        } else {
            yield put(ACTIONS_APP.setWeatherWeek(weather));
            sessionStorage.setItem('weather_week', JSON.stringify(weather));
        }
    } catch (error) {
        yield put(ACTIONS_APP.setWeatherWeek(null));
        console.warn(error);
    }
}

function* requestFetchDashboardWidgets({ payload }) {
    try {
        // load default widgets if they haven't already selected them (only according to permissions/featureflags/etc.)
        const authUser = yield select(authUserState);
        const site = yield select(siteState);
        let filteredWidgets = DATABASE.dashboardWidgetsList.filter(
            (w) =>
                (!w.checkHas3K || (site && site.has3K && w.checkHas3K)) &&
                (!w.checkLoiteringAlert ||
                    (site && site.loiteringAlert && site.loiteringAlert == 'true' && w.checkLoiteringAlert)) &&
                (w.permissions.length === 0 ||
                    w.permissions.filter((p) => authUser.permissions.includes(p)).length > 0) &&
                (w.featureFlags.length === 0 ||
                    w.featureFlags.filter((f) => authUser.featureflagAssignments.includes(f)).length > 0)
        );
        let defaultWidgets = {
            columns: {
                'column-1': {
                    id: 'column-1',
                    widgetIds: []
                },
                'column-2': {
                    id: 'column-2',
                    widgetIds: []
                },
                'column-3': {
                    id: 'column-3',
                    widgetIds: []
                }
            },
            columnOrder: ['column-1', 'column-2', 'column-3']
        };
        let defaultFilteredWidgets = filteredWidgets.filter(w => w.default);
        let availableWidgets = filteredWidgets.filter(w => !w.default).map(a => a.id);
        let i;
        for (i = 0; i < defaultFilteredWidgets.length; i++) {
            if (i >= 0 && i < 3) {
                defaultWidgets.columns['column-1'].widgetIds.push(defaultFilteredWidgets[i].id);
            } else if (i > 2 && i < 6) {
                defaultWidgets.columns['column-2'].widgetIds.push(defaultFilteredWidgets[i].id);
            } else {
                defaultWidgets.columns['column-3'].widgetIds.push(defaultFilteredWidgets[i].id);
            }
        }
        const widgets = yield call(API.POST, payload.url, payload.data);
        if (!widgets) {
            return;
        } else {
            // backend will send empty object and array if they haven't been set yet which is the reason for the above code
            if (Object.keys(widgets.data.columns).length === 0) {
                yield put(ACTIONS_APP.setDashboardWidgets(defaultWidgets));
                yield put(ACTIONS_APP.setAvailableWidgets(availableWidgets));
            } else {
                const widgetList = [];
                // take out the empty string so it doesnt cramp our style
                Object.keys(widgets.data.columns).map(
                    (w) =>
                        widgets.data.columns[w] &&
                        widgets.data.columns[w].widgetIds &&
                        widgets.data.columns[w].widgetIds[0] === '' &&
                        widgets.data.columns[w].widgetIds.pop()
                );
                Object.keys(widgets.data.columns).map(
                    (w) =>
                        widgets.data.columns[w] &&
                        widgets.data.columns[w].widgetIds &&
                        widgetList.push(...widgets.data.columns[w].widgetIds)
                );
                const available = filteredWidgets.filter((w) => widgetList.indexOf(w.id) === -1).map((w) => w.id);
                yield put(ACTIONS_APP.setDashboardWidgets(widgets.data));
                yield put(ACTIONS_APP.setAvailableWidgets(available));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUpdateDashboardWidgets({ payload }) {
    try {
        // need to push an empty string for blank widgetIds array so the backend still sends it back to us ha ha
        Object.keys(payload.data.columns).map(
            (c) => payload.data.columns[c].widgetIds.length === 0 && payload.data.columns[c].widgetIds.push('')
        );
        const authUser = yield select(authUserState);
        const site = yield select(siteState);
        let filteredWidgets = DATABASE.dashboardWidgetsList.filter(
            (w) =>
                (!w.checkHas3K || (site && site.has3K && w.checkHas3K)) &&
                (!w.checkLoiteringAlert ||
                    (site && site.loiteringAlert && site.loiteringAlert == 'true' && w.checkLoiteringAlert)) &&
                (w.permissions.length === 0 ||
                    w.permissions.filter((p) => authUser.permissions.includes(p)).length > 0) &&
                (w.featureFlags.length === 0 ||
                    w.featureFlags.filter((f) => authUser.featureflagAssignments.includes(f)).length > 0)
        );
        const widgets = yield call(API.POST, payload.url, payload.data);
        if (!widgets) {
            return;
        } else {
            const widgetList = [];
            // take out the empty string so it doesnt cramp our style
            Object.keys(widgets.data.columns).map(
                (w) =>
                    widgets.data.columns[w] &&
                    widgets.data.columns[w].widgetIds &&
                    widgets.data.columns[w].widgetIds[0] === '' &&
                    widgets.data.columns[w].widgetIds.pop()
            );
            Object.keys(widgets.data.columns).map(
                (w) =>
                    widgets.data.columns[w] &&
                    widgets.data.columns[w].widgetIds &&
                    widgetList.push(...widgets.data.columns[w].widgetIds)
            );
            const available = filteredWidgets.filter((w) => widgetList.indexOf(w.id) === -1).map((w) => w.id);
            yield put(ACTIONS_APP.setDashboardWidgets(widgets.data));
            yield put(ACTIONS_APP.setAvailableWidgets(available));
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchOutageStatus({ payload }) {
    try {
        let status = yield call(API.LOGIN, payload.url, payload.data);
        if (!status || !status.data || !status.data.outageOn) {
            sessionStorage.setItem('current_outage', false);
        } else if (status.data.outageOn) {
            sessionStorage.setItem('current_outage', true);
        } else if (status.data.outagePending) {
            sessionStorage.setItem('pending_outage', true);
            sessionStorage.setItem('outage_time', status.data.outageAt);
        }
    } catch (status) {
        console.warn(status);
    }
}

function* requestUpdateOutageStatus({ payload }) {
    try {
        let status = yield call(API.POST, payload.url, payload.data);
        if (payload.data.outage && payload.data.forceNow) {
            yield put(ACTIONS_APP.showMessage('comp.button.outageReportAddedNow', 'success'));
            yield put(ACTIONS_APP.fetchOutageStatus());
            window.location.reload();
        } else if (payload.data.outage) {
            yield put(ACTIONS_APP.showMessage('comp.button.outageReportAdded', 'success'));
            yield put(ACTIONS_APP.fetchOutageStatus());
            window.location.reload();
        } else {
            yield put(ACTIONS_APP.showMessage('comp.button.outageReportRemoved', 'success'));
            yield put(ACTIONS_APP.fetchOutageStatus());
            window.location.reload();
        }
    } catch (status) {
        console.warn(status);
    }
}

function* requestFetchFacilityFeedback({ payload }) {
    try {
        yield put(ACTIONS_APP.setFacilityFeedback(null));
        let feedback = yield call(API.POST, payload.url, payload.data);
        if (!feedback || !feedback.data || !feedback.data.feedback) {
            yield put(ACTIONS_APP.setFacilityFeedback([]));
        } else {
            function parseTest(data) {
                try {
                    return JSON.parse(data);
                } catch (ex) {
                    return null;
                }
            }
            feedback.data.feedback.map(
                (feedback) =>
                    (feedback.deviceParsed = parseTest(feedback.deviceDetails)
                        ? JSON.parse(feedback.deviceDetails)
                        : null)
            );
            let feedbackHeaders = yield select(getFeedbackHeaders);
            let activeHeader = feedbackHeaders.find((item) => item.active);
            feedback.data.feedback = UTILS.sortList(!activeHeader.order, feedback.data.feedback, activeHeader.sortTitle);
            yield put(ACTIONS_APP.setFacilityFeedback(feedback.data.feedback));
        }
    } catch (feedback) {
        console.warn(feedback);
    }
}

function* requestFetchAirQuality({ payload }) {
    try {
        yield put(ACTIONS_APP.setAirQuality(null));
        const airQuality = yield call(API.AIR_QUALITY, payload.data.lat, payload.data.lon);
        if (!airQuality) {
            yield put(ACTIONS_APP.setAirQuality(null));
        } else {
            yield put(ACTIONS_APP.setAirQuality(airQuality));
            sessionStorage.setItem('air_quality', JSON.stringify(airQuality));
        }
    } catch (error) {
        yield put(ACTIONS_APP.setAirQuality(null));
        console.warn(error);
    }
}

function* requestFetchSiteVersion({ payload }) {
    try {
        yield put(ACTIONS_APP.setSiteVersion(null));
        let version = yield call(API.POST, payload.url, payload.data);
        if (!version || !version.data) {
            yield put(ACTIONS_APP.setSiteVersion(""));
        } else {
            yield put(ACTIONS_APP.setSiteVersion(version.data));
        }
    } catch (version) {
        console.warn(version);
    }
}

function* requestFetchUserSiteList({ payload }) {
    try {
        yield put(ACTIONS_APP.setUserSiteList(null));
        let list = yield call(API.POST, payload.url, payload.data);
        if (!list || !list.data) {
            yield put(ACTIONS_APP.setUserSiteList(null));
            yield put(ACTIONS_APP.showMessage(`errorCodes.description.2`, 'error'));
        } else {
            yield put(ACTIONS_APP.setUserSiteList(list.data));
        }
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchWebhooksClientDetails({ payload }) {
    try {
        yield put(ACTIONS_APP.setWebhooksClientDetails(null));
        let details = yield call(API.POST, payload.url, payload.data);
        if (!details || !details.data) {
            yield put(ACTIONS_APP.showMessage(`errorCodes.description.2`, 'error'));
        } else {
            yield put(ACTIONS_APP.setWebhooksClientDetails(details.data));
        }
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchWebhooksSiteDetails({ payload }) {
    try {
        yield put(ACTIONS_APP.setWebhooksSiteDetails(null));
        let details = yield call(API.POST, payload.url, payload.data);
        if (!details || !details.data) {
            yield put(ACTIONS_APP.showMessage(`errorCodes.description.2`, 'error'));
        } else {
            yield put(ACTIONS_APP.setWebhooksSiteDetails(details.data));
        }
    } catch (e) {
        console.warn(e);
    }
}

function* requestSubmitLoginPerformance({ payload }) {
    try {
        yield call(API.POST, payload.url, payload.data);
    } catch (e) {
        console.warn(e);
    }
}

function* requestSubmitLoginPerformanceFailed({ payload }) {
    try {
        yield call(API.POST_OLD, payload.url, payload.data);
    } catch (e) {
        console.warn(e);
    }
}

function* requestSubmitUnlockPerformance({ payload }) {
    try {
        yield call(API.POST, payload.url, payload.data);
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchPerformanceFilters({ payload }) {
    try {
        let filters = yield call(API.POST, payload.url, payload.data);
        let previousFilters = yield select(getPerformanceFilters);
        let newFilters = {};

        let regions= [
            {
                text: 'North East',
                value: 'North East'
            },
            {
                text: 'South East',
                value: 'South East'
            },
            {
                text: 'Mid West',
                value: 'Mid West'
            },
            {
                text: 'Central',
                value: 'Central'
            },
            {
                text: 'Mountain',
                value: 'Mountain'
            },
            {
                text: 'West',
                value: 'West'
            }
        ];
        const filteredRegionArray =
        payload.recentFilter && payload.recentFilter === 'region'
        ?
        previousFilters.regions
        :
        regions.filter((region) =>
            filters.data.region && filters.data.region.includes(region.value)
        ).map((r, i) => {return {...r, key: i}});

        let states= [
            { text: 'Alabama', value: 'AL' },
            { text: 'Alaska', value: 'AK' },
            { text: 'Arizona', value: 'AZ' },
            { text: 'Arkansas', value: 'AR' },
            { text: 'California', value: 'CA' },
            { text: 'Colorado', value: 'CO' },
            { text: 'Connecticut', value: 'CT' },
            { text: 'Delaware', value: 'DE' },
            { text: 'Florida', value: 'FL' },
            { text: 'Georgia', value: 'GA' },
            { text: 'Hawaii', value: 'HI' },
            { text: 'Idaho', value: 'ID' },
            { text: 'Illinois', value: 'IL' },
            { text: 'Indiana', value: 'IN' },
            { text: 'Iowa', value: 'IA' },
            { text: 'Kansas', value: 'KS' },
            { text: 'Kentucky', value: 'KY' },
            { text: 'Louisiana', value: 'LA' },
            { text: 'Maine', value: 'ME' },
            { text: 'Maryland', value: 'MD' },
            { text: 'Massachusetts', value: 'MA' },
            { text: 'Michigan', value: 'MI' },
            { text: 'Minnesota', value: 'MN' },
            { text: 'Mississippi', value: 'MS' },
            { text: 'Missouri', value: 'MO' },
            { text: 'Montana', value: 'MT' },
            { text: 'Nebraska', value: 'NE' },
            { text: 'Nevada', value: 'NV' },
            { text: 'New Hampshire', value: 'NH' },
            { text: 'New Jersey', value: 'NJ' },
            { text: 'New Mexico', value: 'NM' },
            { text: 'New York', value: 'NY' },
            { text: 'North Carolina', value: 'NC' },
            { text: 'North Dakota', value: 'ND' },
            { text: 'Ohio', value: 'OH' },
            { text: 'Oklahoma', value: 'OK' },
            { text: 'Oregon', value: 'OR' },
            { text: 'Pennsylvania', value: 'PA' },
            { text: 'Rhode Island', value: 'RI' },
            { text: 'South Carolina', value: 'SC' },
            { text: 'South Dakota', value: 'SD' },
            { text: 'Tennessee', value: 'TN' },
            { text: 'Texas', value: 'TX' },
            { text: 'Utah', value: 'UT' },
            { text: 'Vermont', value: 'VT' },
            { text: 'Virginia', value: 'VA' },
            { text: 'Washington', value: 'WA' },
            { text: 'West Virginia', value: 'WV' },
            { text: 'Wisconsin', value: 'WI' },
            { text: 'Wyoming', value: 'WY' }
        ];
        const filteredStateArray =
        payload.recentFilter && payload.recentFilter === 'state'
        ?
        previousFilters.states
        :
        states.filter((state) =>
            filters.data.state && filters.data.state.includes(state.value)
        ).map((s, i) => {return {...s, key: i}});
        let products = [
            {
                text: 'Nokē FOB',
                value: 'fobs'
            },
            {
                text: 'Nokē Volt',
                value: 'volt'
            },
            {
                text: 'Nokē Keypad',
                value: 'keypads'
            },
            {
                text: 'Nokē ONE',
                value: 'nokeone'
            },
            {
                text: 'Nokē Screen',
                value: 'screens'
            },
            {
                text: 'Nokē Ion',
                value: 'ion'
            }
        ];

        const filteredProductArray =
        payload.recentFilter && payload.recentFilter === 'product'
        ?
        previousFilters.products
        :
        products.filter((product) =>
            filters.data.product && filters.data.product.includes(product.value)
        ).map((p, i) => {return {...p, key: i}});
        
        const filteredCountryArray =
        payload.recentFilter && payload.recentFilter === 'country'
        ?
        previousFilters.countries
        :
        Object.entries(countries).filter(([key]) => 
            filters.data.country.includes(key)
        );
        let sortedCountryList =
        filteredCountryArray &&
        filteredCountryArray.sort((a, b) => {
            let textA = a[1].name.toUpperCase();
            let textB = b[1].name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        let siteTypes = [
            {
                text: 'All',
                value: 'all'
            },
            {
                text: 'Live Facilities',
                value: 'live'
            },
            {
                text: 'Install Facilities',
                value: 'install'
            }
        ]
        let sortedSiteTypeList =
        payload.recentFilter && payload.recentFilter === 'siteType'
        ?
        filters.data.siteType
        :
        siteTypes.filter((st) => filters.data.siteType.includes(st.value)).map((st, i) => {return {...st, key: i}});
        
        let sortedFacilityList =
        payload.recentFilter && payload.recentFilter === 'facility'
        ?
        filters.data.site
        :
        filters.data.site.sort((a, b) => a.name.localeCompare(b.name)).map((f, i) => {return {...f, key: i}});
        
        let sortedCompanyList =
        payload.recentFilter && payload.recentFilter === 'company'
        ?
        filters.data.company
        :
        filters.data.company.sort((a, b) => a.name.localeCompare(b.name)).map((c, i) => {return {...c, key: i}});
        
        newFilters.regions = filteredRegionArray;
        newFilters.states = filteredStateArray;
        newFilters.products = filteredProductArray;
        newFilters.countries = sortedCountryList;
        newFilters.facilities = sortedFacilityList;
        newFilters.companies = sortedCompanyList;
        newFilters.siteTypes = sortedSiteTypeList;
        yield put(ACTIONS_APP.setPerformanceFilters(newFilters));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchPerformanceStats({ payload }) {
    try {
        yield put(ACTIONS_APP.setPerformanceStats(null));
        let stats = yield call(API.POST, payload.url, payload.data);
        stats.data.devices = stats.data.fobs + stats.data.ion + stats.data.keypads + stats.data.nokeone + stats.data.screens + stats.data.volt;
        yield put(ACTIONS_APP.setPerformanceStats(stats.data));
        const performanceDetails = yield select(getPerformanceDetails);
        const batteryPerformanceDetails = yield select(getBatteryPerformanceDetails);
        const deviceFirmware = yield select(getDeviceFirmware);
        if ((payload.origin === 'performance' && performanceDetails && batteryPerformanceDetails)) yield put(ACTIONS_APP.setPerformanceLoader(false));
        if ((payload.origin === 'firmware' && deviceFirmware)) yield put(ACTIONS_APP.setFirmwareLoader(false));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchPerformanceDetails({ payload }) {
    try {
        yield put(ACTIONS_APP.setPerformanceDetails(null));
        let details = yield call(API.POST, payload.url, payload.data);
        details.data.details = details.data.details.reduce((acc, param) => {
            const { parameterName, ...rest } = param; // Extract parameterName and the rest of the object
            acc[parameterName] = rest; // Use parameterName as the key and the rest as the value
            return acc;
        }, {});
        yield put(ACTIONS_APP.setPerformanceDetails(details.data));
        const batteryPerformanceDetails = yield select(getBatteryPerformanceDetails);
        const performanceStats = yield select(getPerformanceStats);
        if (batteryPerformanceDetails && performanceStats) yield put(ACTIONS_APP.setPerformanceLoader(false));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchBatteryPerformanceDetails({ payload }) {
    try {
        yield put(ACTIONS_APP.setBatteryPerformanceDetails(null));
        let details = yield call(API.POST, payload.url, payload.data);
        details.data.batteryCondition = details.data.batteryCondition.reduce((acc, param) => {
            const { parameterName, ...rest } = param; // Extract parameterName and the rest of the object
            acc[parameterName] = rest; // Use parameterName as the key and the rest as the value
            return acc;
        }, {});
        details.data.batteryLife = details.data.batteryLife.reduce((acc, param) => {
            const { parameterName, ...rest } = param; // Extract parameterName and the rest of the object
            acc[parameterName] = rest; // Use parameterName as the key and the rest as the value
            return acc;
        }, {});
        yield put(ACTIONS_APP.setBatteryPerformanceDetails(details.data));
        const performanceDetails = yield select(getPerformanceDetails);
        const performanceStats = yield select(getPerformanceStats);
        if (performanceDetails && performanceStats) yield put(ACTIONS_APP.setPerformanceLoader(false));
    } catch (e) {
        console.warn(e);
    }
}

function* requestSubmitNotificationPerformance({ payload }) {
    try {
        yield call(payload.data.notification_type === 'password_reset' ? API.POST_OLD : API.POST, payload.url, payload.data);
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchSitesFilters({ payload }) {
    try {
        let filters = yield call(API.POST, payload.url, payload.data);
        let availableCountries = [...new Set(filters.data.site_filter.map(s => s.country))];
        let availableStates = [...new Set(filters.data.site_filter.map(s => s.state))];
        let availableRegions = [...new Set(filters.data.site_filter.map(s => s.region).filter(r => r !== ''))];
        let availableCompanies = filters.data.site_filter.reduce((acc, site) => {
            if (!acc.some(company => company.id == site.company_uuid)) {
                acc.push({ id: parseInt(site.company_uuid), name: site.company_name });
            }
            return acc;
        }, []);
        let states= [
            { text: 'Alabama', value: 'AL' },
            { text: 'Alaska', value: 'AK' },
            { text: 'Arizona', value: 'AZ' },
            { text: 'Arkansas', value: 'AR' },
            { text: 'California', value: 'CA' },
            { text: 'Colorado', value: 'CO' },
            { text: 'Connecticut', value: 'CT' },
            { text: 'Delaware', value: 'DE' },
            { text: 'Florida', value: 'FL' },
            { text: 'Georgia', value: 'GA' },
            { text: 'Hawaii', value: 'HI' },
            { text: 'Idaho', value: 'ID' },
            { text: 'Illinois', value: 'IL' },
            { text: 'Indiana', value: 'IN' },
            { text: 'Iowa', value: 'IA' },
            { text: 'Kansas', value: 'KS' },
            { text: 'Kentucky', value: 'KY' },
            { text: 'Louisiana', value: 'LA' },
            { text: 'Maine', value: 'ME' },
            { text: 'Maryland', value: 'MD' },
            { text: 'Massachusetts', value: 'MA' },
            { text: 'Michigan', value: 'MI' },
            { text: 'Minnesota', value: 'MN' },
            { text: 'Mississippi', value: 'MS' },
            { text: 'Missouri', value: 'MO' },
            { text: 'Montana', value: 'MT' },
            { text: 'Nebraska', value: 'NE' },
            { text: 'Nevada', value: 'NV' },
            { text: 'New Hampshire', value: 'NH' },
            { text: 'New Jersey', value: 'NJ' },
            { text: 'New Mexico', value: 'NM' },
            { text: 'New York', value: 'NY' },
            { text: 'North Carolina', value: 'NC' },
            { text: 'North Dakota', value: 'ND' },
            { text: 'Ohio', value: 'OH' },
            { text: 'Oklahoma', value: 'OK' },
            { text: 'Oregon', value: 'OR' },
            { text: 'Pennsylvania', value: 'PA' },
            { text: 'Rhode Island', value: 'RI' },
            { text: 'South Carolina', value: 'SC' },
            { text: 'South Dakota', value: 'SD' },
            { text: 'Tennessee', value: 'TN' },
            { text: 'Texas', value: 'TX' },
            { text: 'Utah', value: 'UT' },
            { text: 'Vermont', value: 'VT' },
            { text: 'Virginia', value: 'VA' },
            { text: 'Washington', value: 'WA' },
            { text: 'West Virginia', value: 'WV' },
            { text: 'Wisconsin', value: 'WI' },
            { text: 'Wyoming', value: 'WY' }
        ];
        const finalCountryArray = Object.entries(countries).filter(([key]) => 
            availableCountries.includes(key)
        );
        let sortedCountryList =
        finalCountryArray.sort((a, b) => {
            let textA = a[1].name.toUpperCase();
            let textB = b[1].name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        const finalStateArray = states.filter((state) =>
            availableStates.includes(state.value)
        ).map((s, i) => {return {...s, key: i}});
        const finalRegionArray = availableRegions.map((r, i) => {return {text: r, value: r, key: i}});
        let sortedFacilityList = filters.data.site_filter.sort((a, b) => a.name.localeCompare(b.name)).map((f, i) => {return {...f, key: i}});
        let sortedCompanyList = availableCompanies.sort((a, b) => a.name.localeCompare(b.name)).map((c, i) => {return {...c, key: i}});
        let finalFilters = {
            sites: sortedFacilityList,
            countries: sortedCountryList,
            states: finalStateArray,
            companies: sortedCompanyList,
            regions: finalRegionArray
        };
        yield put(ACTIONS_APP.setSitesFilters(finalFilters));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchFleetMgmtAccess({ payload }) {
    try {
        let access = yield call(API.POST, payload.url, payload.data);
        yield put(ACTIONS_APP.setFleetMgmtAccess(access.data));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchInstallModeSite({ payload }) {
    try {
        yield put(ACTIONS_APP.setInstallModeSite(null));
        let sites = yield call(API.POST, payload.url, payload.data);
        yield put(ACTIONS_APP.setInstallModeSite(sites.data));
        yield put(ACTIONS_APP.setReportLoader(false));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchDeviceFirmware({ payload }) {
    try {
        yield put(ACTIONS_APP.setDeviceFirmware(null));
        let firmware = yield call(API.POST, payload.url, payload.data);
        let totalDevices = 0;
        let totalInfrastructure = 0;
        let updatedDevices = 0;
        // sort alphabetically
        firmware.data.hardware_types = firmware.data.hardware_types.sort((a, b) => a.name.localeCompare(b.name));
        firmware.data.hardware_types = firmware.data.hardware_types.map(hardware_type => {
            // Destructure the existing properties of the hardware_type object
            const { firmwares, ...rest } = hardware_type;
        
            // Separate firmwares into latestFirmwares and outdatedFirmwares
            const latestFirmwares = firmwares.filter(firmware => firmware.is_up_to_date).sort((a, b) => {
                const versionA = a.firmware_version;
                const versionB = b.firmware_version;
              
                // Check for empty strings and push them to the end
                if (versionA === '') return 1;
                if (versionB === '') return -1;
              
                // Parse the version strings into numbers for proper numerical comparison
                const numA = parseFloat(versionA);
                const numB = parseFloat(versionB);
              
                // Descending order: largest to smallest
                return numB - numA;
            });
            const outdatedFirmwares = firmwares.filter(firmware => !firmware.is_up_to_date).sort((a, b) => {
                const versionA = a.firmware_version;
                const versionB = b.firmware_version;
              
                // Check for empty strings and push them to the end
                if (versionA === '') return 1;
                if (versionB === '') return -1;
              
                // Parse the version strings into numbers for proper numerical comparison
                const numA = parseFloat(versionA);
                const numB = parseFloat(versionB);
              
                // Descending order: largest to smallest
                return numB - numA;
            });

            // Calculate total device counts for each category
            const totalLatestDevices = latestFirmwares.reduce((sum, firmware) => sum + firmware.total_devices, 0);
            const totalOutdatedDevices = outdatedFirmwares.reduce((sum, firmware) => sum + firmware.total_devices, 0);
            if (hardware_type.name === 'Gateway(Noke)' || hardware_type.name === 'Gateway(Rigado)' || hardware_type.name === 'Repeater(Volt)' || hardware_type.name === 'Repeater(Ion)' || hardware_type.name === 'Repeater(Others)') {
                totalInfrastructure += totalLatestDevices + totalOutdatedDevices;
            }
            if (hardware_type.name !== 'Gateway(Noke)' && hardware_type.name !== 'Gateway(Rigado)' && hardware_type.name !== 'Repeater(Volt)' && hardware_type.name !== 'Repeater(Ion)' && hardware_type.name !== 'Repeater(Others)' && hardware_type.name !== 'Other') {
                totalDevices += totalLatestDevices + totalOutdatedDevices;
            }
            updatedDevices += totalLatestDevices;

            // Return a new object with updated firmware arrays
            return {
                ...rest,
                latestFirmwares,
                outdatedFirmwares,
                totalLatestDevices,
                totalOutdatedDevices,
            };
        });
        firmware.data.infrastructure_types = firmware.data.hardware_types.filter(t => t.name === 'Gateway(Noke)' || t.name === 'Gateway(Rigado)');

        let voltRepeater = firmware.data.hardware_types.find(t => t.name === 'Repeater(Volt)');
        let ionRepeater = firmware.data.hardware_types.find(t => t.name === 'Repeater(Ion)');
        let otherRepeater = firmware.data.hardware_types.find(t => t.name === 'Repeater(Others)');
        
        let repeaterObject = {
            name: 'Repeater',
            totalLatestDevices: 0,
            totalOutdatedDevices: 0,
            total_devices: 0
        };
        if (voltRepeater) {
            repeaterObject.latestRepeaterVoltFirmwares = voltRepeater.latestFirmwares;
            repeaterObject.outdatedRepeaterVoltFirmwares = voltRepeater.outdatedFirmwares;
            repeaterObject.totalLatestVoltDevices = voltRepeater.totalLatestDevices;
            repeaterObject.totalOutdatedVoltDevices = voltRepeater.totalOutdatedDevices;
            repeaterObject.totalLatestDevices += voltRepeater.totalLatestDevices;
            repeaterObject.totalOutdatedDevices += voltRepeater.totalOutdatedDevices;
            repeaterObject.total_devices += voltRepeater.total_devices;
        }
        if (ionRepeater) {
            repeaterObject.latestRepeaterIonFirmwares = ionRepeater.latestFirmwares;
            repeaterObject.outdatedRepeaterIonFirmwares = ionRepeater.outdatedFirmwares;
            repeaterObject.totalLatestIonDevices = ionRepeater.totalLatestDevices;
            repeaterObject.totalOutdatedIonDevices = ionRepeater.totalOutdatedDevices;
            repeaterObject.totalLatestDevices += ionRepeater.totalLatestDevices;
            repeaterObject.totalOutdatedDevices += ionRepeater.totalOutdatedDevices;
            repeaterObject.total_devices += ionRepeater.total_devices;
        }
        if(otherRepeater) {
            repeaterObject.latestRepeaterOtherFirmwares = otherRepeater.latestFirmwares;
            repeaterObject.outdatedRepeaterOtherFirmwares = otherRepeater.outdatedFirmwares;
            repeaterObject.totalLatestOtherDevices = otherRepeater.totalLatestDevices;
            repeaterObject.totalOutdatedOtherDevices = otherRepeater.totalOutdatedDevices;
            repeaterObject.totalLatestDevices += otherRepeater.totalLatestDevices;
            repeaterObject.totalOutdatedDevices += otherRepeater.totalOutdatedDevices;
            repeaterObject.total_devices += otherRepeater.total_devices;
        }
        repeaterObject && repeaterObject.total_devices !== 0 && firmware.data.infrastructure_types.push(repeaterObject);

        firmware.data.hardware_types = firmware.data.hardware_types.filter(t => t.name !== 'Gateway(Noke)' && t.name !== 'Gateway(Rigado)' && t.name !== 'Repeater(Volt)' && t.name !== 'Repeater(Ion)' && t.name !== 'Repeater(Others)' && t.name !== 'Other');
        firmware.data.totalDevices = totalDevices;
        firmware.data.totalInfrastructure = totalInfrastructure;
        firmware.data.updatedDevices = updatedDevices;

        yield put(ACTIONS_APP.setDeviceFirmware(firmware.data));
        const performanceStats = yield select(getPerformanceStats);
        if (performanceStats) yield put(ACTIONS_APP.setFirmwareLoader(false));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchDeviceFirmwareFiltered({ payload }) {
    try {
        let firmware = yield call(API.POST, payload.url, payload.data);
        let updatedFirmware = yield select(getDeviceFirmware);
        updatedFirmware = {...updatedFirmware, locks_data: firmware.data.locks_data};
        yield put(ACTIONS_APP.setDeviceFirmware(updatedFirmware));
        yield put(ACTIONS_APP.setFirmwareLoader(false));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchDeviceVoltage({ payload }) {
    try {
        let voltage = yield call(API.POST, payload.url, payload.data);
        voltage.data.batteryCondition = voltage.data.batteryCondition && voltage.data.batteryCondition.reduce((acc, param) => {
            const { parameterName, ...rest } = param; // Extract parameterName and the rest of the object
            acc[parameterName] = rest; // Use parameterName as the key and the rest as the value
            return acc;
        }, {});
        voltage.data.batteryLife = voltage.data.batteryLife && voltage.data.batteryLife.reduce((acc, param) => {
            const { parameterName, ...rest } = param; // Extract parameterName and the rest of the object
            acc[parameterName] = rest; // Use parameterName as the key and the rest as the value
            return acc;
        }, {});
        yield put(ACTIONS_APP.setDeviceVoltage(voltage.data));
        yield put(ACTIONS_APP.setLoader(false));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchSiteSettings({ payload }) {
    try {
        yield put(ACTIONS_APP.setSiteSettings(null));
        let response = yield call(API.POST, payload.url, payload.data);
        let settings = response.data[0];
        // Function to determine text and value based on the code
        function getOptionFromCode(code) {
            switch (code) {
                // case "1G":
                //     return { text: "Noke Ion", value: "1G" };
                case "1A":
                    return { text: "Noke One", value: "1A" };
                case "3E":
                    return { text: "Noke Volt (3E)", value: "3E" };
                case "4E":
                    return { text: "Noke Ion", value: "4E" };
                case "3K":
                    return { text: "Keypad", value: "3K" };
                default:
                    return;
            }
        }
        // Extract the first two characters from the string
        const code = settings.hwSettings.filter(s => s.key === 'firmwareTargetVersion')[0].val.split("_")[0];
        const optionData = getOptionFromCode(code);
        settings.options = [
            {
                text: 'Gateways',
                value: 'Gateways',
                index: 0
            },
            {
                text: optionData.text,
                value: optionData.value,
                index: 1
            }
        ];
        settings.values = {
            Gateways: {
                version: settings.hwSettings.filter(s => s.key === 'gatewayTargetVersion')[0].val,
                autoUpdate: settings.hwSettings.filter(s => s.key === 'enableGatewayAutoUpdate')[0].val
            },
            [optionData.value]: {
                version: settings.hwSettings.filter(s => s.key === 'firmwareTargetVersion')[0].val.split("_")[1].replace('-', '.'),
                autoUpdate: settings.hwSettings.filter(s => s.key === 'enableFirmwareAutoUpdate')[0].val
            }
        }
        yield put(ACTIONS_APP.setSiteSettings(settings));
    } catch (e) {
        console.warn(e);
    }
}

function* requestFetchDigitalAuditsParameters({ payload }) {
    try {
        const response = yield call(API.POST, payload.url, payload.data);
        const data = {
            siteCount: response.data.SiteCount,
            companyCount: response.data.CompanyCount,
            digitalAuditParameterScores: response.data.DigitalAuditParameterScores.map(item => ({
                parameter: item.parameter,
                criticality: item.criticality,
                total_count: item.total_count,
                failure_count: item.failure_count,
                all_sites: item.all_sites,
                central: item.central,
                mid_west: item.mid_west,
                mountain: item.mountain,
                north_east: item.north_east,
                south_east: item.south_east,
                west: item.west,
                international: item.international,
                weekly_trend: item.weekly_trend,
                monthly_trend: item.monthly_trend,
                qtr_trend: item.qtr_trend,
                lifetime_trend: item.lifetime_trend
            }))
        };
        yield put(ACTIONS_APP.setDigitalAuditsParameters(data));
        yield put(ACTIONS_APP.setParameterLoader(false));
    } catch (e) {
    }
}

function* requestFetchFirmwareAutoupdate({ payload }) {
    try {
        let update = yield call(API.POST, payload.url, payload.data);
        const formattedUpdate = update.data
        .filter(v => (v.firmwareTargetVersion !== '' && (v.hwVersion === '1G' || v.hwVersion === '1A' || v.hwVersion === '3E' || v.hwVersion === '4E' || v.hwVersion === '3K')))
        .map((item, i) => {
            return {
                ...item,
                text: item.deviceName,
                value: item.hwVersion,
                index: i,
                options: (item.hwVersion === '1G' || item.hwVersion === 'Rigado')
                ?
                item.firmwareTargetVersion.split(',').map((version, i) => {return {text: version, value: version, key: i}})
                :
                item.firmwareTargetVersion.split(',').map((version, i) => {return {text: version.split('_')[1].replace('-', '.'), value: version, key: i}})
            }});
        yield put(ACTIONS_APP.setFirmwareAutoupdate(formattedUpdate));
    } catch (e) {
        console.warn(e);
    }
}

function* requestSubmitFirmwareAutoupdate({ payload }) {
    try {
        yield put(ACTIONS_APP.setSiteSettings(null));
        yield call(API.POST, payload.url, payload.data);
        yield put(ACTIONS_APP.fetchSiteSettings({sites: [`${payload.data.siteId}`]}));
        yield put(ACTIONS_APP.setFirmwareLoader(false));
    } catch (e) {
        console.warn(e);
    }
}

export function* selectStartup() {
    yield takeEvery(CONSTANTS.FETCH_STARTUP, requestStartup);
}

export function* selectTheme() {
    yield takeEvery(CONSTANTS.FETCH_THEME, requestTheme);
}

export function* selectIpLocation() {
    yield takeEvery(CONSTANTS.FETCH_IP_LOCATION, requestIpLocation);
}

export function* selectFetchErrorDetails() {
    yield takeEvery(CONSTANTS.FETCH_ERROR_DETAILS, requestFetchErrorDetails);
}

export function* selectFetchErrorDetailsDisplay() {
    yield takeEvery(CONSTANTS.FETCH_ERROR_DETAILS_DISPLAY, requestFetchErrorDetailsDisplay);
}

export function* selectSubmitErrorDetails() {
    yield takeEvery(CONSTANTS.SUBMIT_ERROR_DETAILS, requestSubmitErrorDetails);
}

export function* selectFetchAllErrors() {
    yield takeEvery(CONSTANTS.FETCH_ALL_ERRORS, requestFetchAllErrors);
}

export function* selectUpdateError() {
    yield takeEvery(CONSTANTS.UPDATE_ERROR, requestUpdateError);
}

export function* selectDeleteError() {
    yield takeEvery(CONSTANTS.DELETE_ERROR, requestDeleteError);
}

export function* selectFetchWeather() {
    yield takeEvery(CONSTANTS.FETCH_WEATHER, requestFetchWeather);
}

export function* selectFetchWeatherWeek() {
    yield takeEvery(CONSTANTS.FETCH_WEATHER_WEEK, requestFetchWeatherWeek);
}

export function* selectFetchDashboardWidgets() {
    yield takeEvery(CONSTANTS.FETCH_DASHBOARD_WIDGETS, requestFetchDashboardWidgets);
}

export function* selectUpdateDashboardWidgets() {
    yield takeEvery(CONSTANTS.UPDATE_DASHBOARD_WIDGETS, requestUpdateDashboardWidgets);
}

export function* selectFetchOutageStatus() {
    yield takeEvery(CONSTANTS.FETCH_OUTAGE_STATUS, requestFetchOutageStatus);
}

export function* selectUpdateOutageStatus() {
    yield takeEvery(CONSTANTS.UPDATE_OUTAGE_STATUS, requestUpdateOutageStatus);
}

export function* selectFetchFacilityFeedback() {
    yield takeEvery(CONSTANTS.FETCH_FACILITY_FEEDBACK, requestFetchFacilityFeedback);
}

export function* selectFetchAirQuality() {
    yield takeEvery(CONSTANTS.FETCH_AIR_QUALITY, requestFetchAirQuality);
}

export function* selectFetchSiteVersion() {
    yield takeEvery(CONSTANTS.FETCH_SITE_VERSION, requestFetchSiteVersion);
}

export function* selectFetchUserSiteList() {
    yield takeEvery(CONSTANTS.FETCH_USER_SITE_LIST, requestFetchUserSiteList);
}

export function* selectFetchWebhooksClientDetails() {
    yield takeEvery(CONSTANTS.FETCH_WEBHOOKS_CLIENT_DETAILS, requestFetchWebhooksClientDetails);
}

export function* selectFetchWebhooksSiteDetails() {
    yield takeEvery(CONSTANTS.FETCH_WEBHOOKS_SITE_DETAILS, requestFetchWebhooksSiteDetails);
}

export function* selectSubmitLoginPerformance() {
    yield takeEvery(CONSTANTS.SUBMIT_LOGIN_PERFORMANCE, requestSubmitLoginPerformance);
}

export function* selectSubmitLoginPerformanceFailed() {
    yield takeEvery(CONSTANTS.SUBMIT_LOGIN_PERFORMANCE_FAILED, requestSubmitLoginPerformanceFailed);
}

export function* selectSubmitUnlockPerformance() {
    yield takeEvery(CONSTANTS.SUBMIT_UNLOCK_PERFORMANCE, requestSubmitUnlockPerformance);
}

export function* selectFetchPerformanceFilters() {
    yield takeEvery(CONSTANTS.FETCH_PERFORMANCE_FILTERS, requestFetchPerformanceFilters);
}

export function* selectFetchPerformanceStats() {
    yield takeEvery(CONSTANTS.FETCH_PERFORMANCE_STATS, requestFetchPerformanceStats);
}

export function* selectFetchPerformanceDetails() {
    yield takeEvery(CONSTANTS.FETCH_PERFORMANCE_DETAILS, requestFetchPerformanceDetails);
}

export function* selectFetchBatteryPerformanceDetails() {
    yield takeEvery(CONSTANTS.FETCH_BATTERY_PERFORMANCE_DETAILS, requestFetchBatteryPerformanceDetails);
}

export function* selectSubmitPerformanceFilters() {
    yield takeEvery(CONSTANTS.SUBMIT_PERFORMANCE_FILTERS, requestFetchPerformanceFilters);
}

export function* selectSubmitNotificationPerformance() {
    yield takeEvery(CONSTANTS.SUBMIT_NOTIFICATION_PERFORMANCE, requestSubmitNotificationPerformance);
}

export function* selectFetchSitesFilters() {
    yield takeEvery(CONSTANTS.FETCH_SITES_FILTERS, requestFetchSitesFilters);
}

export function* selectFetchFleetMgmtAccess() {
    yield takeEvery(CONSTANTS.FETCH_FLEET_MGMT_ACCESS, requestFetchFleetMgmtAccess);
}

export function* selectFetchInstallModeSite() {
    yield takeEvery(CONSTANTS.FETCH_INSTALL_MODE_SITE, requestFetchInstallModeSite);
}

export function* selectFetchDeviceFirmware() {
    yield takeEvery(CONSTANTS.FETCH_DEVICE_FIRMWARE, requestFetchDeviceFirmware);
}

export function* selectFetchDeviceFirmwareFiltered() {
    yield takeEvery(CONSTANTS.FETCH_DEVICE_FIRMWARE_FILTERED, requestFetchDeviceFirmwareFiltered);
}

export function* selectFetchDeviceVoltage() {
    yield takeEvery(CONSTANTS.FETCH_DEVICE_VOLTAGE, requestFetchDeviceVoltage);
}

export function* selectFetchDigitalAuditsParameters() {
    yield takeEvery(CONSTANTS.FETCH_DIGITAL_AUDITS_PARAMETERS, requestFetchDigitalAuditsParameters);
}
export function* selectFetchSiteSettings() {
    yield takeEvery(CONSTANTS.FETCH_SITE_SETTINGS, requestFetchSiteSettings);
}

export function* selectFetchFirmwareAutoupdate() {
    yield takeEvery(CONSTANTS.FETCH_FIRMWARE_AUTOUPDATE, requestFetchFirmwareAutoupdate);
}

export function* selectSubmitFirmwareAutoupdate() {
    yield takeEvery(CONSTANTS.SUBMIT_FIRMWARE_AUTOUPDATE, requestSubmitFirmwareAutoupdate);
}

export default function* rootSaga() {
    yield all([
        fork(selectStartup),
        fork(selectTheme),
        fork(selectIpLocation),
        fork(selectFetchErrorDetails),
        fork(selectFetchErrorDetailsDisplay),
        fork(selectSubmitErrorDetails),
        fork(selectFetchAllErrors),
        fork(selectUpdateError),
        fork(selectDeleteError),
        fork(selectFetchWeather),
        fork(selectFetchWeatherWeek),
        fork(selectFetchDashboardWidgets),
        fork(selectUpdateDashboardWidgets),
        fork(selectFetchOutageStatus),
        fork(selectUpdateOutageStatus),
        fork(selectFetchFacilityFeedback),
        fork(selectFetchAirQuality),
        fork(selectFetchSiteVersion),
        fork(selectFetchUserSiteList),
        fork(selectFetchWebhooksClientDetails),
        fork(selectFetchWebhooksSiteDetails),
        fork(selectSubmitLoginPerformance),
        fork(selectSubmitLoginPerformanceFailed),
        fork(selectSubmitUnlockPerformance),
        fork(selectFetchPerformanceFilters),
        fork(selectFetchPerformanceStats),
        fork(selectFetchPerformanceDetails),
        fork(selectFetchBatteryPerformanceDetails),
        fork(selectSubmitPerformanceFilters),
        fork(selectSubmitNotificationPerformance),
        fork(selectFetchSitesFilters),
        fork(selectFetchFleetMgmtAccess),
        fork(selectFetchDeviceFirmware),
        fork(selectFetchInstallModeSite),
        fork(selectFetchDeviceFirmwareFiltered),
        fork(selectFetchDeviceVoltage),
        fork(selectFetchSiteSettings),
        fork(selectFetchFirmwareAutoupdate),
        fork(selectSubmitFirmwareAutoupdate),
        fork(selectFetchDigitalAuditsParameters)
    ]);
}
