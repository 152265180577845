import React from 'react';
import moment from 'moment';

import CellText from 'components/Table/cell/components/text';
import CellDate from 'components/Table/cell/components/date';
import CellIcon from 'components/Table/cell/components/icon';
import CellBlank from 'components/Table/cell/components/blank';

import { UnitIcon } from 'utilities/icons';
import { withRouter } from 'react-router-dom';
import { Cell } from 'recharts';
import { connect } from 'react-redux';


class LowBatteryCell extends React.Component {
    handleClick = () => {
        this.props.history.push(`/app/units/display/${this.props.data.unitUUID}`)
    }
    render() {
        let now = moment().utc().format();
        const { data, theme, authUser} = this.props;
        const { lockName, batteryState, initiallyReported } = data;
        return (
            <>
                <CellIcon
                        Cellwidth={'45px'}
                        backgroundColor='white'
                        Icon={UnitIcon}
                        fill={theme.color.buttonFontColor}
                    />
                <CellText
                    update={this.handleClick}
                    Title={lockName}
                    textAlign={'left'}
                    TitleColor={true}
                />
                {(initiallyReported && initiallyReported !== '0001-01-01T00:00:00Z') ?
                <CellDate
                    date = {initiallyReported}
                    showTime = {false}
                    textAlign={'center'}
                /> : 
                <CellBlank />}
                <CellText
                    update={this.handleClick}
                    Title={batteryState}
                    TitleColor={true}
                />
                
            </>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser };
};

export default withRouter(connect(mapStateToProps)(LowBatteryCell));
