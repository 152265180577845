import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { SingleDatePicker } from 'react-dates';
import {
    UncontrolledTooltip,
    Button,
    Row,
    Col,
    Label,
    Input,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal/new';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { fadeIn } from 'react-animations';
import SkeletonContainer from 'components/Skeleton/skeletonWrapper';
import moment from 'moment-timezone';
import * as UTILS from 'utilities';

import { setErrorDetails, showMessage, submitUnlockPerformance, submitNotificationPerformance } from 'actions/App';
import {
    remoteUnlock,
    holdOpenGateTime,
    holdOpenGateSchedule,
    fetchLockHolds,
    removeLockHold,
    createRepeatingLockSchedule
} from 'actions/Units';
import { fetchEntryList } from 'actions/Entry';

import ToolTips from 'components/ToolTips';
import Spinner from 'components/Spinner';
import TextInput from 'components/Inputs/text';
import SelectInput from 'components/Inputs/select';
import CheckboxInput from 'components/Inputs/checkbox';
import TextAreaInput from 'components/Inputs/textarea';
import { EntryIcon } from 'utilities/icons';
import GateLeft from 'assets/images/Components/Buttons/gate_left.png';
import GateRight from 'assets/images/Components/Buttons/gate_right.png';
import Header from 'components/Header/dashboard';
class DashboardRemoteOpen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lockStatus: 'entry',
            lockIndex: 0,
            modalOpen: false,
            modalType: null,
            entry: null,
            startTime: '',
            endTime: '',
            startDate: moment(),
            endDate: moment(),
            repeatEndDate: moment().add(1, 'weeks'),
            hours: '',
            minutes: '',
            startFocused: false,
            endFocused: false,
            endDateFocused: false,
            isRepeatingSchedule: false,
            useEndDate: false,
            repeatTimeType: 'daily',
            repeatName: '',
            repeatDesc: '',
            prevRequest: null,
            showPendingModal: false,
            now: moment().subtract(1, 'day').format(),
            disabledEntries: {}
        };
    }
    componentDidMount() {
        const { fetchLockHolds, fetchEntryList, editWidgets, visible } = this.props;
        (editWidgets || visible) && fetchEntryList(undefined, true);
        (editWidgets || visible) && fetchLockHolds();
        // lets grab it every minute and see if we should show changes
        this.interval = setInterval(() => {
            (editWidgets || visible) && fetchLockHolds();
        }, 60000);
    }
    componentDidUpdate(prevProps) {
        if (!prevProps.entryList && this.props.entryList && this.props.simpleDash) {
            this.props.onDataLoaded();
        }
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    handleUnlock = (mac, i, bleHwVersion, hwType) => {
        const { remoteUnlock, showMessage, submitUnlockPerformance, authUser, site, submitNotificationPerformance } = this.props;
        let { lockStatus } = this.state;

        if (lockStatus == 'loader' || lockStatus == 'complete') {
            showMessage('notifications.failed.unlock', 'warning');
        } else {
            let inputHwType = 'other'
            if (hwType == 'unit') {
                inputHwType = 'unit'
            } else if (hwType == 'gate' ||hwType == 'exitgate' || hwType == 'door' || hwType == 'slidingdoor' || hwType == 'elevator') {
                inputHwType = 'gate'
            }
            submitUnlockPerformance({
                site_id: site?.id,
                user_id: authUser.id,
                unlock_type: 'UnlockRemote',
                mac: mac,
                overall_time: -1,
                start_time: moment().utc(),
                hwType: inputHwType,
                deviceType: bleHwVersion,
                sourceSystem: "portal"
            });
            submitNotificationPerformance({
                site_id: site.id,
                user_id: authUser.id,
                notification_type: 'unitNotifications',
                notification_name: 'override_used',
                start_time: moment().utc()
            });
            // Disable the button
            this.setState((prevState) => ({
                disabledEntries: { ...prevState.disabledEntries, [mac]: true },
            }));
          
            // Re-enable after 30 seconds
            setTimeout(() => {
            this.setState((prevState) => ({
                disabledEntries: { ...prevState.disabledEntries, [mac]: false },
            }));
            }, 30000);
            remoteUnlock({ mac, bleHwVersion });
        }
        this.setState({ lockStatus: 'loader' });
        this.setState({ lockIndex: i });
        setTimeout(() => {
            this.props.unlockStatus && this.props.unlockStatus == 'success'
                ? this.setState({ lockStatus: 'complete' })
                : this.props.unlockStatus == 'failed'
                ? this.setState({ lockStatus: 'failed' })
                : '';
        }, 6500);
        setTimeout(() => {
            this.setState({ lockStatus: 'entry' });
        }, 10000);
    };
    setEntryForHold = (entry) => {
        this.setState({ entry: entry }, this.setHoldTime);
    };
    setHoldTime = (time) => {
        const { holdOpenGateTime, fetchLockHolds, site, submitUnlockPerformance, submitNotificationPerformance, authUser } = this.props;
        let date;
        if (time) {
            date = moment().tz(site.timeZone).add(time, 'minutes').utc().format();
        } else {
            date = moment('2038-01-01').utc().format();
        }
        let req = {
            lockHold: { lockID: Number(this.state.entry.id), endTime: date },
            forceHoldOpen: false
        };
        let inputHwType = 'other'
            if (this.state.entry.hwType == 'unit') {
                inputHwType = 'unit'
            } else if (this.state.entry.hwType == 'gate' ||this.state.entry.hwType == 'exitgate' || this.state.entry.hwType == 'door' || this.state.entry.hwType == 'slidingdoor' || this.state.entry.hwType == 'elevator') {
                inputHwType = 'gate'
            }
        submitUnlockPerformance({
            site_id: site?.id,
            user_id: authUser.id,
            unlock_type: 'UnlockRemote',
            mac: this.state.entry.mac,
            overall_time: -1,
            start_time: moment().utc(),
            hwType: inputHwType,
            deviceType: this.state.entry.bleHwVersion,
            sourceSystem: "portal"
        });
        submitNotificationPerformance({
            site_id: site.id,
            user_id: authUser.id,
            notification_type: 'unitNotifications',
            notification_name: 'override_used',
            start_time: moment().utc()
        });
        holdOpenGateTime(req);
        if(!time){
            setTimeout(() => {
                fetchLockHolds();
            }, 7500);
        }
        this.setState({ prevRequest: req });
        this.setState({ entry: null });
    };
    setCustomHoldTime = () => {
        const { holdOpenGateTime, site, submitNotificationPerformance, authUser } = this.props;
        let minutes = Number(this.state.hours) * 60 + Number(this.state.minutes);
        let date = moment().tz(site.timeZone).add(minutes, 'minutes').utc().format();
        let req = {
            lockHold: { lockID: Number(this.state.entry.id), endTime: date },
            forceHoldOpen: false
        };
        submitNotificationPerformance({
            site_id: site.id,
            user_id: authUser.id,
            notification_type: 'unitNotifications',
            notification_name: 'override_used',
            start_time: moment().utc()
        });
        holdOpenGateTime(req);
        this.setState({ prevRequest: req });
        this.setState({ hours: '', minutes: '', entry: null, modalOpen: false, modalType: null });
    };
    setHoldSchedule = () => {
        const {
            startDate,
            startTime,
            endDate,
            endTime,
            isRepeatingSchedule,
            repeatTimeType,
            useEndDate,
            repeatEndDate,
            repeatName,
            repeatDesc,
            now
        } = this.state;
        const { createRepeatingLockSchedule, showMessage, site, authUser, submitNotificationPerformance } = this.props;
        if (!startDate || !endDate) {
            UTILS.getDateFormat(authUser, site) == 'DD-MM-YYYY'
            ?
            showMessage(`notifications.error.blank.invalidDateReversed`, 'warning')
            :
            showMessage(`notifications.error.blank.invalidDate`, 'warning')
            return;
        }
        if(startDate !== '' && startDate.format() < now){
            showMessage(`notifications.error.blank.expirationPast`, 'warning');
            let newStartDate = moment();
            this.setState({ startDate: newStartDate });
            return;
        }
        if(endDate !== '' && endDate.format() < now){
            showMessage(`notifications.error.blank.expirationPast`, 'warning');
            let newEndDate = moment();
            this.setState({ endDate: newEndDate });
            return;
        }
        if (
            isRepeatingSchedule &&
            repeatTimeType !== '' &&
            startDate !== '' &&
            startTime !== '' &&
            endDate !== '' &&
            endTime !== ''
        ) {
            let reqObj = {
                name: repeatName,
                description: repeatDesc,
                lockID: this.state.entry.id,
                startTime: moment.tz(`${startDate.format('YYYY-MM-DD')} ${startTime}:00`, site.timeZone)
                    .utc()
                    .format(),
                endTime: moment.tz(`${endDate.format('YYYY-MM-DD')} ${endTime}:00`, site.timeZone)
                    .utc()
                    .format(),
                useEndDate,
                endDate: useEndDate && repeatEndDate
                    ? moment(`${repeatEndDate.format('YYYY-MM-DD')}`)
                          .utc()
                          .format()
                    : null,
                interval: repeatTimeType
            };
            createRepeatingLockSchedule(reqObj);
        } else if (startDate !== '' && startTime !== '' && endDate !== '' && endTime !== '') {
            let startTime = moment(
                moment.tz(`${this.state.startDate.format('YYYY-MM-DD')} ${this.state.startTime}:00`, site.timeZone)
                    .utc()
                    .format()
            );
            let endTime = moment(
                moment.tz(`${this.state.endDate.format('YYYY-MM-DD')} ${this.state.endTime}:00`, site.timeZone)
                    .utc()
                    .format()
            );
            let req = {
                lockHold: { lockID: Number(this.state.entry.id), startTime, endTime },
                forceHoldOpen: false
            };
            submitNotificationPerformance({
                site_id: site.id,
                user_id: authUser.id,
                notification_type: 'unitNotifications',
                notification_name: 'override_used',
                start_time: moment().utc()
            });
            this.props.holdOpenGateSchedule(req);
            this.setState({ prevRequest: req });
            this.setState({ entry: null });
        }
        this.setState({ modalOpen: false, modalType: null });
    };
    requestForceOpen = () => {
        let { prevRequest } = this.state;
        let { errorDetails, setErrorDetails, holdOpenGateTime, holdOpenGateSchedule, fetchLockHolds, submitNotificationPerformance, site, authUser } = this.props;
        let newReq = prevRequest;
        if (newReq) {
            newReq.forceHoldOpen = true;
        }
        let errorURL = JSON.parse(errorDetails.endpoint);
        if (errorDetails.code !== 130 || !newReq) return;
        if (errorURL.includes('lock/hold/now')) {
            submitNotificationPerformance({
                site_id: site.id,
                user_id: authUser.id,
                notification_type: 'unitNotifications',
                notification_name: 'override_used',
                start_time: moment().utc()
            });
            holdOpenGateTime(newReq);
            setTimeout(() => {
                fetchLockHolds();
            }, 7500);
        }
        if (errorURL.includes('lock/hold/create')) {
            submitNotificationPerformance({
                site_id: site.id,
                user_id: authUser.id,
                notification_type: 'unitNotifications',
                notification_name: 'override_used',
                start_time: moment().utc()
            });
            holdOpenGateSchedule(newReq);
        }
        this.setState({ prevRequest: null });
        setErrorDetails(null);
    };
    clearHold = (holds) => {
        // not even sure if you can have multiple active holds on an entry unit,
        // but just in case we'll close out all of them, but hopefully leave any that haven't started yet
        holds.forEach((h) => this.props.removeLockHold(h.id));
    };
    pendingHold = () => {
        // const { showMessage } = this.props;
        // showMessage('notifications.failed.holdOpen', 'warning');
        this.setState({ showPendingModal: true });
    };
    refresh = () => {
        const { fetchLockHolds, fetchEntryList } = this.props;
        fetchEntryList(undefined, true);
        fetchLockHolds();
    };
    linkEntry = (data) => {
        this.props.history.push(`/app/entry/display/${data.id}`);
    };
    render() {
        const { intl, theme, height, entryList, lockHolds, authUser, errorDetails, simpleDash, site } =
            this.props;
        const { lockStatus, lockIndex, modalOpen, hours, minutes, startFocused, endFocused, endDateFocused, showPendingModal, disabledEntries } =
            this.state;
        this.styles = styles(theme, simpleDash);
        let newEntryList = entryList && entryList.filter((entry) => entry.hwType != 'repeater');
        // need to check if entry has a hold AND that the start time of the hold is before now
        newEntryList &&
            lockHolds &&
            newEntryList.map(
                (e) =>
                    (e.holds = lockHolds.filter(
                        (h) => h.lockUUID === e.uuid && moment(h.startTime).local().isSameOrBefore(moment())
                    ))
        );
        return (
            <div id={'remoteOpenTooltip'}>
                <Row>
                    <Col xs={12}>
                        {simpleDash && (
                            <div className={css(this.styles.boxTop)}>
                                <div className={css(this.styles.iconBox)}>
                                    <EntryIcon height={34} width={34} color={           
                                        theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                                        ? theme.color.fontColor
                                        : theme.color.themeColor} />
                                </div>
                                <div className={css(this.styles.headerIconWrapperSimple)}>
                                    <div
                                        className={css(this.styles.headerIcon)}
                                        id={'refreshIcon'}
                                        onClick={() => this.refresh()}>
                                        <i
                                            className="material-icons"
                                            style={{ color: theme.color.buttonFontColor }}
                                            id="autorenew">
                                            autorenew
                                        </i>
                                    </div>
                                    <ToolTips target={'refreshIcon'} message={'tooltip.refreshPage'} />
                                </div>
                            </div>
                        )}
                        {simpleDash ? (
                            <div className={css(this.styles.headerLayoutSimple)}>
                                <h1 className={css(this.styles.headerSimple)}>
                                    {intl.formatMessage({ id: 'permission.remote_open' })}
                                </h1>
                            </div>
                        ) : (
                            <Header
                                Icon={EntryIcon}
                                title={'permission.remote_open'}
                                manage={[
                                    {
                                        icon: 'autorenew',
                                        color: theme.color.buttonFontColor,
                                        text: 'tooltip.refreshPage',
                                        action: () => this.refresh(),
                                        id: 'quickOpen-autorenew'
                                    }
                                ]}
                            />
                        )}
                    </Col>
                </Row>
                <Row
                    style={{
                        maxWidth: '100%',
                        margin: '0 20px 20px 20px',
                        overflowX: 'hidden',
                        maxHeight: simpleDash ? ((height - 112)/2)-124 : 140,
                        padding: '5px 0 0 0'
                    }}>
                    {newEntryList ? (
                        newEntryList.map((entry, i) => (
                            <div
                                key={'ENTRYLIST' + entry.id}
                                className={
                                    newEntryList.length > 5
                                        ? css(this.styles.card, authUser && authUser.permissions.includes('manage_entries') ? this.styles.hover : this.styles.noHover)
                                        : css(this.styles.cardAlone, authUser && authUser.permissions.includes('manage_entries') ? this.styles.hover : this.styles.noHover)
                                }
                                onClick={() => authUser && authUser.permissions.includes('manage_entries') && this.linkEntry(entry)}
                                >
                                <div
                                    style={
                                        lockStatus == 'complete' && lockIndex == i
                                            ? { transition: 'all .07s ease-in-out', backgroundColor: '#0EC55E' }
                                            : lockStatus == 'failed' && lockIndex == i
                                            ? { transition: 'all .07s ease-in-out', backgroundColor: '#E02550' }
                                            : lockStatus == 'loader' && lockIndex == i
                                            ? {
                                                  transition: 'all .07s ease-in-out',
                                                  cursor: 'not-allowed',
                                                  backgroundColor:
                                                      theme &&
                                                      theme.color &&
                                                      theme.color.name &&
                                                      theme.color.name.includes('dark')
                                                          ? '#626262'
                                                          : theme &&
                                                            theme.color &&
                                                            theme.color.name &&
                                                            theme.color.name.includes('light2')
                                                          ? '#bbbcc8'
                                                          : '#DCDCDC'
                                              }
                                            : {
                                                  transition: 'all .07s ease-in-out',
                                                  backgroundColor: theme.color.themeColor
                                              }
                                    }
                                    className={css(this.styles.icon)}>
                                    {lockStatus == 'entry' && lockIndex == i ? (
                                        entry.gwDelinquent && entry.gwDelinquent == 0 && entry.holds && entry.holds.length > 0 ? (
                                            <div>
                                                <img
                                                    src={GateLeft}
                                                    alt="Gate Left Image"
                                                    className={css(this.styles.gateSingle)}
                                                />
                                                <img
                                                    src={GateRight}
                                                    alt="Gate Right Image"
                                                    className={css(this.styles.gateSingle)}
                                                />
                                            </div>
                                        ) : (
                                            <EntryIcon
                                                fill={theme.color.themeColor}
                                                color={theme.color.buttonFontColor}
                                            />
                                        )
                                    ) : lockStatus == 'loader' && lockIndex == i ? (
                                        <Spinner
                                            color={theme.color.fontColor}
                                            width={18}
                                            height={18}
                                        />
                                    ) : lockStatus == 'complete' && lockIndex == i ? (
                                        <i className="material-icons" style={{ color: 'white' }}>
                                            done
                                        </i>
                                    ) : lockStatus == 'failed' && lockIndex == i ? (
                                        <i className="material-icons" style={{ color: 'white' }}>
                                            error
                                        </i>
                                    ) : entry.holds && entry.holds.length > 0 && entry.holds.filter(hold => ((hold.commandStatus && hold.commandStatus == 'complete')) || !hold.commandStatus).length > 0 ? (
                                        <div>
                                            <img
                                                src={GateLeft}
                                                alt="Gate Left Image"
                                                className={css(this.styles.gateSingle)}
                                            />
                                            <img
                                                src={GateRight}
                                                alt="Gate Right Image"
                                                className={css(this.styles.gateSingle)}
                                            />
                                        </div>
                                    ) : (
                                        <EntryIcon fill={theme.color.themeColor} color={theme.color.buttonFontColor} />
                                    )}
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 50,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                    <p className={css(this.styles.title)}>{entry.name}</p>
                                </div>
                                {entry.gwDelinquent && entry.gwDelinquent == 1 ?
                                    <Button
                                        onClick={(e) => {
                                            
                                        }}
                                        disabled={true}
                                        className={css(this.styles.offlineButton)}>
                                        {intl.formatMessage({
                                            id: 'comp.button.open'
                                        })}
                                    </Button> :
                                entry.holds && entry.holds.length > 0 && entry.holds.filter(hold => ((hold.commandStatus && (hold.commandStatus == 'complete') || hold.commandStatus == 'pending_response')) || !hold.commandStatus).length > 0 ? (
                                    <>
                                        <Modal
                                            isOpen={showPendingModal}
                                            contentClassName={css(this.styles.modalWrapper)}>
                                            <ModalHeader style={{ borderBottom: '1px solid' + theme.color.borderColor }}>
                                                {intl.formatMessage({ id: `comp.schedules.forceHold.confirmAction` })}
                                            </ModalHeader>
                                            <ModalBody style={{ textAlign: 'center' }}>
                                                <div style={{ margin: '15px 0' }}>
                                                    <div style={{ fontSize: 17 }}>
                                                        {intl.formatMessage({
                                                            id: `notifications.failed.holdOpen`
                                                        })}
                                                    </div>
                                                    <div style={{ fontSize: 17 }}>
                                                        {intl.formatMessage({
                                                            id: `comp.button.remove`
                                                        })} {intl.formatMessage({
                                                            id: `comp.button.holdOpen`
                                                        })}?
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            marginTop: 20
                                                        }}>
                                                        <Button
                                                            onClick={() => this.setState({ showPendingModal: false })}
                                                            className={css(this.styles.cancelButton)}>
                                                            {intl.formatMessage({ id: `comp.button.cancel` })}
                                                        </Button>
                                                        <Button 
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.clearHold(entry.holds);
                                                                this.setState({ showPendingModal: false });
                                                            }} 
                                                            className={css(this.styles.submitButton)}>
                                                            {intl.formatMessage({
                                                                id: `comp.button.remove`
                                                            })}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                        <div id={`tip-${entry.id}`}>
                                            {entry.holds.filter(hold => ((hold.commandStatus && hold.commandStatus == 'complete')) || !hold.commandStatus).length > 0 &&
                                            (entry.bleHwVersion === '4K' ||
                                            (entry.bleHwVersion === '3K' &&
                                                entry.bleAppVersion &&
                                                Number(entry.bleAppVersion) >= 3.2) ||
                                            (entry.bleHwVersion === '3E' &&
                                                entry.bleAppVersion &&
                                                Number(entry.bleAppVersion) >= 4.18 &&
                                                entry.voltageWired &&
                                                entry.voltageWired < 15000) ||
                                            (entry.bleHwVersion === '2E' &&
                                                entry.bleAppVersion &&
                                                Number(entry.bleAppVersion) >= 3.37 &&
                                                entry.voltageWired &&
                                                entry.voltageWired < 15000)) ? (
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.clearHold(entry.holds);
                                                    }}
                                                    className={css(this.styles.clearHoldButton)}>
                                                    {intl.formatMessage({
                                                        id: 'comp.button.close'
                                                    })}
                                                </Button>
                                            ) :
                                            entry.holds.filter(hold => (hold.commandStatus && hold.commandStatus == 'pending_response')).length > 0 &&
                                            (entry.bleHwVersion === '4K' ||
                                            (entry.bleHwVersion === '3K' &&
                                                entry.bleAppVersion &&
                                                Number(entry.bleAppVersion) >= 3.2) ||
                                            (entry.bleHwVersion === '3E' &&
                                                entry.bleAppVersion &&
                                                Number(entry.bleAppVersion) >= 4.18 &&
                                                entry.voltageWired &&
                                                entry.voltageWired < 15000) ||
                                            (entry.bleHwVersion === '2E' &&
                                                entry.bleAppVersion &&
                                                Number(entry.bleAppVersion) >= 3.37 &&
                                                entry.voltageWired &&
                                                entry.voltageWired < 15000)) ? (
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.pendingHold();
                                                    }}
                                                    className={css(this.styles.pendingHoldButton)}>
                                                    {intl.formatMessage({
                                                        id: 'comp.button.opening'
                                                    })}
                                                </Button>
                                            ) :
                                            (
                                                <div style={{ height: 37 }}></div>
                                            )}
                                        </div>
                                        <UncontrolledTooltip
                                            target={`tip-${entry.id}`}
                                            className="my-tooltip"
                                            delay={{ show: 75, hide: 250 }}
                                            style={{ 
                                                color: theme.color.themeColor, 
                                                background: theme.color.compColor,
                                                border: '1px solid' + theme.color.themeColor,
                                                padding: '5px 10px',
                                                opacity: 1 + ' !important',
                                            }}
                                            modifiers={{
                                                preventOverflow: { boundariesElement: 'viewport' },
                                                hide: { enabled: false },
                                            }}>
                                            {intl.formatMessage({
                                                id: `comp.schedules.holdOpen.openUntil`
                                            })}{' '}
                                            {moment(entry.holds[0].endTime).isSameOrAfter(moment('2037-12-01'))
                                                ? intl.formatMessage({
                                                      id: `comp.schedules.holdOpen.manuallyClosed`
                                                  })
                                                : `${moment(entry.holds[0].endTime)
                                                      .local()
                                                      .format('MMMM Do YYYY, h:mm a')} ${intl.formatMessage({
                                                      id: 'comp.schedules.holdOpen.orManuallyClosed'
                                                  })}`}
                                        </UncontrolledTooltip>
                                    </>
                                ) : (
                                    <div
                                        onClick={(e) => e.stopPropagation()}
                                        style={{ overflow: 'visible !important' }}>
                                        <UncontrolledDropdown direction="down">
                                            {authUser.permissions.includes('manage_holds') &&
                                            (entry.bleHwVersion === '4K' ||
                                                (entry.bleHwVersion === '3K' &&
                                                    entry.bleAppVersion &&
                                                    Number(entry.bleAppVersion) >= 3.2) ||
                                                (entry.bleHwVersion === '3E' &&
                                                    entry.bleAppVersion &&
                                                    Number(entry.bleAppVersion) >= 4.18 &&
                                                    entry.voltageWired &&
                                                    entry.voltageWired < 15000) ||
                                                (entry.bleHwVersion === '2E' &&
                                                    entry.bleAppVersion &&
                                                    Number(entry.bleAppVersion) >= 3.37 &&
                                                    entry.voltageWired &&
                                                    entry.voltageWired < 15000)) ? (
                                                <DropdownToggle
                                                    id={`openToggle${i}`}
                                                    className={css(this.styles.openButton)}
                                                    data-boundary="viewport">
                                                    {intl.formatMessage({
                                                        id: 'comp.button.open'
                                                    })}
                                                    <i className="material-icons" style={{ color: 'white', margin: '1px 0 0 2px', fontSize: 14 }}>
                                                        expand_more
                                                    </i>
                                                </DropdownToggle>
                                            ) : (
                                                <Button
                                                    id={`openButton${i}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        !disabledEntries[entry.mac] && this.handleUnlock(entry.mac, i, entry.bleHwVersion, entry.hwType)
                                                    }}
                                                    className={disabledEntries[entry.mac] ? css(this.styles.disabledOpenButton) : css(this.styles.openButton)}>
                                                    {intl.formatMessage({
                                                        id: 'comp.button.open'
                                                    })}
                                                </Button>
                                            )}
                                            {authUser.permissions.includes('manage_holds') &&
                                            (entry.bleHwVersion === '4K' ||
                                                (entry.bleHwVersion === '3K' &&
                                                    entry.bleAppVersion &&
                                                    Number(entry.bleAppVersion) >= 3.2) ||
                                                (entry.bleHwVersion === '3E' &&
                                                    entry.bleAppVersion &&
                                                    Number(entry.bleAppVersion) >= 4.18 &&
                                                    entry.voltageWired &&
                                                    entry.voltageWired < 15000) ||
                                                (entry.bleHwVersion === '2E' &&
                                                    entry.bleAppVersion &&
                                                    Number(entry.bleAppVersion) >= 3.37 &&
                                                    entry.voltageWired &&
                                                    entry.voltageWired < 15000)) ?
                                                <ToolTips target={`openToggle${i}`} message={'pages.dashboard.openOptions'} /> :
                                                <ToolTips target={`openButton${i}`} message={'pages.dashboard.openImmediately'} />
                                            }
                                            <div>
                                                <DropdownMenu
                                                    modifiers={{
                                                        preventOverflow: {
                                                            enabled: false,
                                                            boundariesElement: 'viewport'
                                                        },
                                                        hide: { enabled: false },
                                                        strategy: 'fixed'
                                                    }}
                                                    positionFixed={true}
                                                    className={css(this.styles.dropdown)}>
                                                    <div
                                                        id={`quickOpen${i}`}
                                                        className={disabledEntries[entry.mac] ? css(this.styles.disabledDropdownItem) : css(this.styles.dropdownItem)}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            !disabledEntries[entry.mac] && this.handleUnlock(entry.mac, i, entry.bleHwVersion, entry.hwType)
                                                        }}>
                                                        {intl.formatMessage({
                                                            id: 'pages.dashboard.quickOpen'
                                                        })}
                                                    </div>
                                                    <div
                                                        id={`holdOpen${i}`}
                                                        className={css(this.styles.dropdownItem)}
                                                        onClick={() => this.setEntryForHold(entry)}>
                                                        {intl.formatMessage({
                                                            id: 'comp.button.holdOpen'
                                                        })}
                                                    </div>
                                                    <div
                                                        id={`setTime${i}`}
                                                        className={css(this.styles.dropdownItem)}
                                                        onClick={() =>
                                                            this.setState({
                                                                modalOpen: true,
                                                                modalType: 'time',
                                                                entry: entry
                                                            })
                                                        }>
                                                        {intl.formatMessage({
                                                            id: 'comp.button.setATime'
                                                        })}
                                                    </div>
                                                    <div
                                                        id={`setSchedule${i}`}
                                                        className={css(this.styles.dropdownItem)}
                                                        onClick={() =>
                                                            this.setState({
                                                                modalOpen: true,
                                                                modalType: 'schedule',
                                                                entry: entry
                                                            })
                                                        }>
                                                        {intl.formatMessage({
                                                            id: 'comp.button.setASchedule'
                                                        })}
                                                    </div>
                                                    <ToolTips target={`quickOpen${i}`} message={'pages.dashboard.openImmediately'} />
                                                    <ToolTips target={`holdOpen${i}`} message={'pages.dashboard.openIndefinite'} />
                                                    <ToolTips target={`setTime${i}`} message={'pages.dashboard.openSpecified'} />
                                                    <ToolTips target={`setSchedule${i}`} message={'pages.dashboard.openSchedule'} />
                                                </DropdownMenu>
                                            </div>
                                        </UncontrolledDropdown>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <SkeletonContainer>
                            <div className={css(this.styles.card)}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 34,
                                        backgroundColor: theme.color.themeColor,
                                        textAlign: 'center'
                                    }}>
                                    <EntryIcon fill={theme.color.themeColor} color={theme.color.buttonFontColor} />
                                </div>
                                <div style={{ width: '100%', height: 34, textAlign: 'center' }}>
                                    <Skeleton style={{ width: '70%', marginTop: 10 }} />
                                </div>
                            </div>
                            <div className={css(this.styles.card)}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 34,
                                        backgroundColor: theme.color.themeColor,
                                        textAlign: 'center'
                                    }}>
                                    <EntryIcon fill={theme.color.themeColor} color={theme.color.buttonFontColor} />
                                </div>
                                <div style={{ width: '100%', height: 34, textAlign: 'center' }}>
                                    <Skeleton style={{ width: '70%', marginTop: 10 }} />
                                </div>
                            </div>
                            <div className={css(this.styles.card)}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 34,
                                        backgroundColor: theme.color.themeColor,
                                        textAlign: 'center'
                                    }}>
                                    <EntryIcon fill={theme.color.themeColor} color={theme.color.buttonFontColor} />
                                </div>
                                <div style={{ width: '100%', height: 34, textAlign: 'center' }}>
                                    <Skeleton style={{ width: '70%', marginTop: 10 }} />
                                </div>
                            </div>
                            <div className={css(this.styles.card)}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 34,
                                        backgroundColor: theme.color.themeColor,
                                        textAlign: 'center'
                                    }}>
                                    <EntryIcon fill={theme.color.themeColor} color={theme.color.buttonFontColor} />
                                </div>
                                <div style={{ width: '100%', height: 34, textAlign: 'center' }}>
                                    <Skeleton style={{ width: '70%', marginTop: 10 }} />
                                </div>
                            </div>
                        </SkeletonContainer>
                    )}
                    {newEntryList && newEntryList.length == 0 && (
                        <div
                            style={{
                                fontSize: 12,
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: simpleDash ? height - 599 : 84,
                                animationName: fadeIn,
                                animationDuration: '1.2s'
                            }}>
                            <p style={{ marginTop: simpleDash ? 0 : 50 }}>
                                {intl.formatMessage({ id: 'comp.table.cell.empty' })}
                            </p>
                        </div>
                    )}
                </Row>
                {/* Currently, this special modal replaces the normal error modal for errors 130.1 and 130.2. This is the force hold open modal */}
                {errorDetails && (
                    <Modal
                        isOpen={
                            errorDetails &&
                            errorDetails.code === 130 &&
                            (errorDetails.subcode === 1 || errorDetails.subcode === 2)
                        }
                        contentClassName={css(this.styles.modalWrapper)}>
                        <ModalHeader style={{ borderBottom: '1px solid' + theme.color.borderColor }}>
                            {intl.formatMessage({ id: `comp.schedules.forceHold.confirmAction` })}
                        </ModalHeader>
                        <ModalBody style={{ textAlign: 'center' }}>
                            <div style={{ margin: '15px 0' }}>
                                {errorDetails.subcode === 1 ? (
                                    <div style={{ fontSize: 17 }}>
                                        {intl.formatMessage({
                                            id: `comp.schedules.forceHold.accessHours`
                                        })}
                                    </div>
                                ) : (
                                    <div style={{ fontSize: 17 }}>
                                        {intl.formatMessage({
                                            id: `comp.schedules.forceHold.overnight`
                                        })}
                                    </div>
                                )}
                                <div style={{ fontSize: 17 }}>
                                    {intl.formatMessage({
                                        id: `comp.schedules.forceHold.continue`
                                    })}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: 20
                                    }}>
                                    <Button
                                        onClick={() => this.props.setErrorDetails(null)}
                                        className={css(this.styles.cancelButton)}>
                                        {intl.formatMessage({ id: `comp.button.cancel` })}
                                    </Button>
                                    <Button onClick={this.requestForceOpen} className={css(this.styles.submitButton)}>
                                        {intl.formatMessage({
                                            id: `comp.schedules.forceHold.forceOpen`
                                        })}
                                    </Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
                <Modal isOpen={modalOpen} autoFocus={false} contentClassName={css(this.styles.modalWrapper)}>
                    <ModalHeader>
                        {this.state.modalType == 'time'
                            ? intl.formatMessage({ id: 'comp.button.setATime' })
                            : this.state.modalType == 'schedule'
                            ? intl.formatMessage({ id: 'comp.button.setASchedule' })
                            : 'Repeat Schedule'}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.modalType == 'time' && (
                            // set a time
                            <>
                                <Label className={css(this.styles.label)}>
                                    {intl.formatMessage({ id: `form.quickOptions` })}
                                </Label>
                                <div className={css(this.styles.timeButtons)}>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(1);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        1 minute
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(5);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        5 minutes
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(10);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        10 minutes
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(15);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        15 minutes
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(30);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        30 minutes
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(45);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        45 minutes
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(60);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        1 hour
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(120);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        2 hours
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(180);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        3 hours
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(240);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        4 hours
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(300);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        5 hours
                                    </span>
                                    <span
                                        className={css(this.styles.timeButton)}
                                        onClick={() => {
                                            this.setHoldTime(360);
                                            this.setState({ modalOpen: false, modalType: null });
                                        }}>
                                        6 hours
                                    </span>
                                </div>
                                <Label className={css(this.styles.label)}>
                                    {intl.formatMessage({ id: `form.custom` })}
                                </Label>
                                <div>
                                    <Label className={css(this.styles.label)}>
                                        {intl.formatMessage({ id: `pages.hours.Type` })}
                                    </Label>
                                    <Input
                                        className={css(this.styles.timeInput)}
                                        type="number"
                                        name="hours"
                                        value={hours}
                                        onChange={(e) => this.setState({ hours: e.target.value })}
                                    />
                                </div>
                                <div>
                                    <Label className={css(this.styles.label)}>
                                        {intl.formatMessage({ id: `form.minutes` })}
                                    </Label>
                                    <Input
                                        className={css(this.styles.timeInput)}
                                        type="number"
                                        name="minutes"
                                        value={minutes}
                                        onChange={(e) => this.setState({ minutes: e.target.value })}
                                    />
                                </div>
                                <Button onClick={() => this.setCustomHoldTime()} className={css(this.styles.setButton)}>
                                    {intl.formatMessage({ id: 'form.set' })}
                                </Button>
                            </>
                        )}
                        {this.state.modalType == 'schedule' && (
                            // set a schedule
                            <>
                                <div className={css(this.styles.flexRow)}>
                                    <Label className={css(this.styles.label)}>
                                        {intl.formatMessage({ id: `form.startDate` })}
                                    </Label>
                                    <Label className={css(this.styles.labelRight)}>
                                        {intl.formatMessage({ id: `form.startTime` })}
                                    </Label>
                                </div>
                                <div className={css(this.styles.flexRow)}>
                                    <div style={{ width: '50%' }}>
                                        <SingleDatePicker
                                            displayFormat={() => (UTILS.getDateFormat(authUser, site) == 'DD-MM-YYYY') ? "DD-MM-YYYY": "MM/DD/YYYY"}
                                            date={this.state.startDate}
                                            onDateChange={(date) => this.setState({ startDate: date })}
                                            focused={startFocused}
                                            onFocusChange={({ focused }) => this.setState({ startFocused: focused })}
                                            id="holdStartDate"
                                            openDirection="down"
                                            noBorder={true}
                                            isOutsideRange={() => false}
                                        />
                                    </div>
                                    <Input
                                        className={css(this.styles.inputRight)}
                                        type="time"
                                        onChange={(e) => this.setState({ startTime: e.target.value })}
                                        value={this.state.startTime}
                                    />
                                </div>
                                <div className={css(this.styles.flexRow)}>
                                    <Label className={css(this.styles.label)}>
                                        {intl.formatMessage({ id: `form.endDate` })}
                                    </Label>
                                    <Label className={css(this.styles.labelRight)}>
                                        {intl.formatMessage({ id: `form.endTime` })}
                                    </Label>
                                </div>
                                <div className={css(this.styles.flexRow)}>
                                    <div style={{ width: '50%' }}>
                                        <SingleDatePicker
                                            displayFormat={() => (UTILS.getDateFormat(authUser, site) == 'DD-MM-YYYY') ? "DD-MM-YYYY": "MM/DD/YYYY"}
                                            date={this.state.endDate}
                                            onDateChange={(date) => this.setState({ endDate: date })}
                                            focused={endFocused}
                                            onFocusChange={({ focused }) => this.setState({ endFocused: focused })}
                                            id="holdEndDate"
                                            openDirection="down"
                                            noBorder={true}
                                            isOutsideRange={() => false}
                                        />
                                    </div>
                                    <Input
                                        className={css(this.styles.inputRight)}
                                        type="time"
                                        onChange={(e) => this.setState({ endTime: e.target.value })}
                                        value={this.state.endTime}
                                    />
                                </div>
                                {/* Un-Hiding repeating schedules again */}
                                <div
                                    className={css(this.styles.repeatScheduleCheckbox)}
                                    onClick={() =>
                                        this.setState({
                                            isRepeatingSchedule: !this.state.isRepeatingSchedule
                                        })
                                    }>
                                    <CheckboxInput
                                        checked={this.state.isRepeatingSchedule}
                                        label={'comp.repeatSchedule.title'}
                                    />
                                </div>
                                {this.state.isRepeatingSchedule && (
                                    <>
                                        <div style={{ width: '50%' }}>
                                            <TextInput
                                                title={intl.formatMessage({ id: `form.name` })}
                                                placeholder={intl.formatMessage({
                                                    id: `form.name`
                                                })}
                                                fontSize={14}
                                                type="text"
                                                maxLength="99"
                                                value={this.state.repeatName}
                                                margin={'20px 0px 0px 0px'}
                                                onChange={(e) => this.setState({ repeatName: e.target.value })}
                                            />
                                            <div style={{ padding: '7px 0' }}>
                                                <TextAreaInput
                                                    title={intl.formatMessage({ id: `form.description` })}
                                                    placeholder={intl.formatMessage({ id: 'form.description' })}
                                                    maxLength={499}
                                                    value={this.state.repeatDesc}
                                                    onChange={(e) => this.setState({ repeatDesc: e.target.value })}
                                                    fontSize={14}
                                                />
                                            </div>
                                            <div>
                                                <SelectInput
                                                    title={intl.formatMessage({ id: `comp.repeatSchedule.interval` })}
                                                    options={[
                                                        {
                                                            text: intl.formatMessage({
                                                                id: `comp.repeatSchedule.daily`
                                                            }),
                                                            key: 0,
                                                            value: 'daily'
                                                        },
                                                        {
                                                            text: intl.formatMessage({
                                                                id: `comp.repeatSchedule.weekly`
                                                            }),
                                                            key: 1,
                                                            value: 'weekly'
                                                        },
                                                        {
                                                            text: intl.formatMessage({
                                                                id: `comp.repeatSchedule.monthly`
                                                            }),
                                                            key: 2,
                                                            value: 'monthly'
                                                        }
                                                    ]}
                                                    value={this.state.repeatTimeType}
                                                    onChange={(e) =>
                                                        this.setState({
                                                            repeatTimeType: e.target.value
                                                        })
                                                    }
                                                    fontSize={14}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={css(this.styles.repeatScheduleCheckbox)}
                                            onClick={() =>
                                                this.setState({
                                                    useEndDate: !this.state.useEndDate
                                                })
                                            }>
                                            <CheckboxInput
                                                checked={this.state.useEndDate}
                                                label={'comp.repeatSchedule.useExpirationDate'}
                                            />
                                        </div>
                                        {this.state.useEndDate && (
                                            <div style={{ width: '50%' }}>
                                                <SingleDatePicker
                                                    displayFormat={() => (UTILS.getDateFormat(authUser, site) == 'DD-MM-YYYY') ? "DD-MM-YYYY": "MM/DD/YYYY"}
                                                    date={this.state.repeatEndDate}
                                                    onDateChange={(date) => this.setState({ repeatEndDate: date })}
                                                    focused={endDateFocused}
                                                    onFocusChange={({ focused }) =>
                                                        this.setState({ endDateFocused: focused })
                                                    }
                                                    id="holdRepeatEndDate"
                                                    openDirection="down"
                                                    noBorder={true}
                                                    isOutsideRange={() => false}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className={css(this.styles.cancelButton)}
                            onClick={() => this.setState({ modalOpen: false, modalType: null, entry: null })}>
                            {intl.formatMessage({ id: 'comp.button.cancel' })}
                        </Button>
                        {this.state.modalType == 'schedule' && (
                            <Button
                                className={css(this.styles.submitButton)}
                                onClick={() => {
                                    this.setHoldSchedule();
                                }}>
                                {intl.formatMessage({ id: 'comp.button.submit' })}
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ app, entry, units, auth }) => {
    const { authUser, site } = auth;
    const { theme, errorDetails, height } = app;
    const { entryList } = entry;
    const { unlockStatus, lockHolds } = units;
    return { theme, entryList, unlockStatus, lockHolds, authUser, errorDetails, height, site };
};

export default withRouter(
    injectIntl(
        connect(mapStateToProps, {
            remoteUnlock,
            holdOpenGateTime,
            holdOpenGateSchedule,
            fetchLockHolds,
            removeLockHold,
            createRepeatingLockSchedule,
            setErrorDetails,
            showMessage,
            fetchEntryList,
            submitUnlockPerformance,
            submitNotificationPerformance
        })(DashboardRemoteOpen)
    )
);

const styles = (theme) =>
    StyleSheet.create({
        gateSingle: {
            height: 27,
            width: 35,
            maxHeight: 25,
            maxWidth: 33
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row'
        },
        timeButtons: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
            gridColumnGap: 20,
            gridRowGap: 20,
            padding: 20
        },
        timeButton: {
            cursor: 'pointer',
            textAlign: 'center',
            padding: '5px 10px',
            background: theme.color.themeColor,
            color: theme.color.buttonFontColor,
            borderRadius: 5
        },
        timeInput: {
            width: '25%'
        },
        setButton: {
            cursor: 'pointer',
            textAlign: 'center',
            padding: 5,
            background: theme.color.themeColor,
            color: theme.color.buttonFontColor,
            borderColor: theme.color.themeColor,
            borderRadius: 5,
            marginTop: 10
        },
        label: {
            fontWeight: 400,
            fontSize: 12,
            color: theme.color.fontColor,
            width: '50%',
            padding: '5px 0 2px 0'
        },
        labelRight: {
            fontWeight: 400,
            fontSize: 12,
            color: theme.color.fontColor,
            width: '50%',
            marginLeft: '1rem',
            padding: '5px 0 2px 0'
        },
        inputRight: {
            width: '50%',
            marginLeft: '1rem'
        },
        modalWrapper: {
            backgroundColor: theme.color.compColor,
            color: theme.color.fontColor
        },
        cancelButton: {
            backgroundColor: theme.color.pageColor,
            borderColor: theme.color.borderColor,
            color:
                theme && theme.color && theme.color.name && theme.color.name.includes('dark')
                    ? theme.color.buttonFontColor
                    : theme.color.fontColor
        },
        submitButton: {
            backgroundColor: theme.color.themeColor,
            borderColor: theme.color.borderColor,
            color: theme.buttonFontColor
        },
        dropdown: {
            display: 'grid',
            gridTemplateColumns: '100px 100px',
            gap: 10,
            padding: 10,
            overflow: 'visible',
            backgroundColor: theme.color.compColor
        },
        dropdownItem: {
            textAlign: 'center',
            padding: '7px',
            borderRadius: 5,
            color: theme.color.buttonFontColor,
            backgroundColor: theme.color.themeColor
        },
        disabledDropdownItem: {
            textAlign: 'center',
            padding: '7px',
            borderRadius: 5,
            backgroundColor: theme.color.borderColor,
            borderColor: theme.color.borderColor,
            color: theme.color.fontColor,
            opacity: 0.6,
            cursor: 'not-allowed',
            ':hover': {
                opacity: 0.6,
            }
        },
        holdButton: {
            margin: '0 10px 10px 10px',
            padding: '3px 7px',
            minHeight: 26,
            minWidth: 62,
            color: theme.color.buttonFontColor,
            backgroundColor: '#E02550',
            borderColor: theme.color.borderColor,
            ':hover': {
                opacity: '0.9'
            },
            '@media(max-width:1600px)': {
                fontSize: 11
            }
        },
        openButton: {
            margin: '0 10px 10px 10px',
            padding: '3px 7px',
            minHeight: 26,
            minWidth: 62,
            color: theme.color.buttonFontColor,
            backgroundColor: theme.color.themeColor,
            borderColor: theme.color.borderColor,
            ':hover': {
                opacity: '0.9'
            },
            '@media(max-width:1600px)': {
                fontSize: 11
            }
        },
        disabledOpenButton: {
            margin: '0 10px 10px 10px',
            padding: '3px 7px',
            minHeight: 26,
            minWidth: 62,
            backgroundColor: theme.color.borderColor,
            borderColor: theme.color.borderColor,
            color: theme.color.fontColor,
            opacity: 0.6,
            cursor: 'not-allowed',
            ':hover': {
                opacity: 0.6,
            },
            '@media(max-width:1600px)': {
                fontSize: 11
            }
        },
        clearHoldButton: {
            margin: '0 10px 10px 10px',
            padding: '3px 7px',
            minHeight: 26,
            minWidth: 62,
            color: theme.color.buttonFontColor,
            backgroundColor: '#0EC55E',
            borderColor: theme.color.borderColor,
            ':hover': {
                opacity: '0.9'
            },
            '@media(max-width:1600px)': {
                fontSize: 11
            }
        },
        pendingHoldButton: {
            margin: '0 10px 10px 10px',
            padding: '3px 7px',
            minHeight: 26,
            minWidth: 62,
            color: theme.color.buttonFontColor,
            backgroundColor: '#F2CF52',
            borderColor: theme.color.borderColor,
            ':hover': {
                opacity: '0.9'
            },
            '@media(max-width:1600px)': {
                fontSize: 11
            }
        },
        offlineButton: {
            margin: '0 10px 10px 10px',
            padding: '3px 7px',
            minHeight: 26,
            minWidth: 62,
            color: theme.color.fontColor,
            backgroundColor: theme.color.borderColor,
            borderColor: theme.color.borderColor,
            '@media(max-width:1600px)': {
                fontSize: 11
            }
        },
        header: {
            color: theme.color.themeColor,
            marginBottom: 15
        },
        headerSimple: {
            color: theme.color.themeColor,
            marginBottom: 15,
            fontSize: 16,
            textAlign: 'center',
            width: '100%'
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 10,
            fontSize: 20
        },
        icon: {
            width: '100%',
            minHeight: 30,
            maxHeight: 30,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        card: {
            width: '18%',
            minHeight: 105,
            maxHeight: 105,
            minWidth: 88,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderRadius: 5,
            margin: '6px 2% 0 2%',
            transition: 'all .2s ease-in-out',
            overflow: 'hidden',
            boxShadow: theme.color.boxShadowCorner,
            wordBreak: 'break-word',
            ':hover': {
                cursor: 'pointer',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-width:400px)': {
                width: '23%'
            }
        },
        cardAlone: {
            minWidth: 88,
            margin: '0 1%',
            minHeight: 105,
            maxHeight: 105,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderRadius: 5,
            marginBottom: 12,
            transition: 'all .2s ease-in-out',
            overflow: 'hidden',
            boxShadow: theme.color.boxShadowCorner,
            ':hover': {
                cursor: 'pointer',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-width:400px)': {
                width: '23%'
            }
        },
        title: {
            marginBottom: 0,
            marginTop: 5,
            textAlign: 'center',
            width: '80%',
            fontSize: 11,
            overflow: 'hidden',
            '@media(max-width:1350px)': {
                fontSize: 9
            },
            '@media(max-width:400px)': {
                width: 50,
                fontSize: 8
            }
        },
        hover: {
            ':hover': {
                cursor: 'pointer'
            }
        },
        noHover: {
            ':hover': {
                cursor: 'auto'
            }
        },
        headerLayout: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
        },
        headerLayoutSimple: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'center',
            paddingTop: 30
        },
        headerIcon: {
            opacity: 1,
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: '0.8'
            }
        },
        headerIconWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            width: 55
        },
        repeatScheduleCheckbox: {
            cursor: 'pointer',
            userSelect: 'none',
            margin: '8px 0'
        },
        repeatEveryDescription: {
            padding: 5,
            borderRadius: 5,
            width: '100%',
            height: 100,
            border: `1px solid ${theme.color.borderColor}`
        },
        editTitle: {
            paddingTop: 5,
            paddingBottom: 2
        },
        boxTop: {
            display: 'flex',
            justifyContent: 'center',
            background: theme.color.themeColor,
            paddingTop: 10,
            position: 'relative'
        },
        iconBox: {
            background: theme.color.compColor,
            border: '3px solid' + theme.color.themeColor,
            borderRadius: '50%',
            height: 55,
            width: 55,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: -25
        },
        headerIconWrapperSimple: {
            position: 'absolute',
            top: 8,
            right: 10
        }
    });
